import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "./Notification";

function getNumOfInputs(detail, gId) {
  // console.log("DETAIL", detail)
  let max_digit = 96
  let num_count = 0
  if (detail.length) {
    detail.forEach(e => {
      if (e["id"] == gId) {
        max_digit = e["product"]["properties"]["max_digit"]
        num_count = (e["product"]["properties"]["max_digit"] == 96) ? 6 : 5
        // console.log('NUM', max_digit)
        // localStorage.removeItem('evl_gameId');
      }      
    });
  }

  return {"num_count": num_count, "max_digit": max_digit}
}

function CloseGameMultiple({ id, detail }) {
  const [OTP1, setOTP1] = useState("");
  const [OTP2, setOTP2] = useState("");
  const [OTP3, setOTP3] = useState("");
  const [OTP4, setOTP4] = useState("");
  const [OTP5, setOTP5] = useState("");
  const [OTP6, setOTP6] = useState("");
  const token = localStorage.getItem("accesstoken");

  const loading = false;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  // get game ID set on main page
  let gID2 = localStorage.getItem('evl_gameId');
  const min_det = getNumOfInputs(detail, gID2)
  console.log("MIN DET", min_det)

  const addValue = (box, value) => {
    console.log("evaluate", box, value)
    if (box == 1 && value <= 96 && value > 0) {
      setOTP1(value);
    } else if (box == 2 && value <= 96 && value > 0) {
      setOTP2(value);
    } else if (box == 3 && value <= 96 && value > 0) {
      setOTP3(value);
    } else if (box == 4 && value <= 96 && value > 0) {
      setOTP4(value);
    } else if (box == 5 && value <= 96 && value > 0) {
      setOTP5(value);
    } else if (box == 6 && value <= 96 && value > 0) {
      setOTP6(value);
    }
  };

  // const addValueNew = (box, value) => {
  //   console.log("evaluate 2", box, value)
  //   if (box == 1 && value <= 96 && value > 0) {
  //     setOTP1(value);
  //   } else if (box == 2 && value <= 96 && value > 0) {
  //     setOTP2(value);
  //   } else if (box == 3 && value <= 96 && value > 0) {
  //     setOTP3(value);
  //   } else if (box == 4 && value <= 96 && value > 0) {
  //     setOTP4(value);
  //   } else if (box == 5 && value <= 96 && value > 0) {
  //     setOTP5(value);
  //   } else if (box == 6 && value <= 96 && value > 0) {
  //     setOTP6(value);
  //   }
  // };

  function validateFields() {
    if (
      OTP1 === "" ||
      OTP2 === "" ||
      OTP3 === "" ||
      OTP4 === "" ||
      OTP5 === "" 
    ) {
      setAllValid(false)
    } else {
      if (min_det["num_count"] == 6 && OTP6 === "") {        
        setAllValid(false)
        console.log("6th item passed")
      } else {
        setAllValid(true)
      }
      // setAllValid(true)
    }
  }

  const [allValid, setAllValid] = useState(false);
  const closeModal = async () => {
    validateFields()
    console.log("ALL VALID", allValid)
    
    if(!allValid) {
      ErrorNotification("Fill all the spaces");
      return;
    }

    let otpSet = [
      parseInt(OTP1),
      parseInt(OTP2),
      parseInt(OTP3),
      parseInt(OTP4),
      parseInt(OTP5),
    ];
    if (min_det["num_count"] == 6) {        
      otpSet.push(parseInt(OTP6))
    }

      let payload = {
        game_id: id,
        results: otpSet,
      };

      try {
        const res = await axiosInstance.post(
          "api/v1/admin/game/close",
          payload,
        );

        if (res?.status === 200) {
          SuccessNotification(
            res?.data?.message ?? "You have successfully closed the game ",
          );
          window.location.reload();
        }
      } catch (error) {
        ErrorNotification(
          error?.messge ?? "An error occured while closing game",
        );
      }
  };

  const raiseAlert = () => {
    Swal.fire({
      title: "Are you sure you want to close this game",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,accept",
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };

  const DynaInput = ({var_otp, val_idx}) => {
    return (
      <input
        value={var_otp}
        onChange={(e) => addValue(val_idx, e.target.value)}
        type="text"
        style={{
          width: 50,
          margin: 10,
          paddingLeft: 15,

          height: 50,
          marginBottom: 20,
          borderRadius: 5,
        }}
      />
    )
  }

  let otp_list = {
    OTP1: "",
    OTP2: "",
    OTP3: "",
    OTP4: "",
    OTP5: "",
    OTP6: "",
  };

  /**
   * Create input list
   */
  let otp_inputs = [];
  for (let i = 1; i <= min_det["num_count"]; i++) {
    otp_inputs.push(<DynaInput var_otp={otp_list["OTP" + i]} val_idx={i} />);
  }

  return (
    <div
      style={{
        height: "500px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>{"Please enter winning numbers here"}</h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {otp_inputs} */}
        <DynaInput var_otp={OTP1} val_idx={1} />
        <DynaInput var_otp={OTP2} val_idx={2} />
        <DynaInput var_otp={OTP3} val_idx={3} />
        <DynaInput var_otp={OTP4} val_idx={4} />
        <DynaInput var_otp={OTP5} val_idx={5} />

        {(min_det["num_count"] == 6) && (
          <DynaInput var_otp={OTP6} val_idx={6} />
        )}
      </div>

      <div style={{ width: "10%" }}>
        <button
          onClick={() => raiseAlert()}
          style={{
            width: "100%",
            marginBottom: 10,
          }}
          className="btn btn-primary"
        >
          {loading ? "Loading..." : "Close Game"}
        </button>
      </div>
    </div>
  );
}

export default CloseGameMultiple;
