import React, { useState } from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { CircularProgress } from '@material-ui/core';
import {
	USERS_SUMMARY,
	GET_ALL_DEPARTMENTS,
	GET_ADMIN_ROLES,
	GET_ALL_REGIONS,
} from '../../Graphql/Queries';
import { useQuery } from '@apollo/client';
import UserCard from './UserCard';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { AdminDetails, CustomerDetails, AgentDetails } from './FormDetails';
import { api } from '../../utils/Api';
import {
	SuccessNotification,
	ErrorNotification,
} from '../../components/Notification';


const UserManagement = () => {
	const [userSummary, setUserSummary] = useState(null);
	const [departments, setDepartments] = useState(null);
	const [adminRoles, setAdminRoles] = useState(null);
	const [regions, setRegions] = useState(null);
	const [show, setShow] = useState(false);

	const formik = useFormik({
		initialValues: {
			first_name: '',
			middle_name: null,
			surname: '',
			phone_number: 0,
			email: '',
			national_id: null,
			device_id: null,
			user_type: '',
			dob: null,
			department_id: null,
			momo_network: null,
			role_id: null,
			address: null,
			digital_address: null,
			town_city: null,
			region_id: null,
			consent_given: false,
			platform: 'web',
		},

		onSubmit: (values) => {
			// console.log(values.user_type);
			if (values.user_type === 'admin') {
				const body = {
					phone_number: Number(values.phone_number),
					first_name: values.first_name,
					middle_name: values.middle_name,
					surname: values.surname,
					email: values.email,
					department_id: values.department_id,
					role_id: values.role_id,
				};
				api
					.post('/auth/admins/v1/signup', body)
					.then(() => {
						SuccessNotification('Successfully created User');
					})
					.catch(() => {
						ErrorNotification(
							'There was issue with your request, Kindly try again with the correct information',
						);
					});
			} else {
				api
					.post('/auth/admin/users/v1/signup', values)
					.then(() => {
						SuccessNotification('Successfully created User');
					})
					.catch(() => {
						ErrorNotification(
							'There was issue with your request, Kindly try again with the correct information',
						);
					});
			}
		},
	});

	const handleClose = () => {
		setShow(false);
	};

	const createUser = () => {
		setShow(true);
	};

	const { loading: userSummaryLoading } = useQuery(USERS_SUMMARY, {
		onCompleted: (data) => {
			let myObjs = [];
			myObjs.push({
				label: 'Admins',
				value: data.admins?.aggregate.count,
				borderColor: '#FF6F00',
			});

			myObjs.push({
				label: 'Agents',
				value: data.agents?.aggregate.count,
				borderColor: '#76FF03',
			});

			myObjs.push({
				label: 'Customers',
				value: data.regular_customers?.aggregate.count,
				borderColor: '#00b0ff',
			});

			myObjs.push({
				label: 'Writers',
				value: data.writers?.aggregate.count,
				borderColor: '#00b0ff',
			});

			myObjs.push({
				label: 'WriterFms',
				value: data.writer_fms?.aggregate.count,
				borderColor: '#00b0ff',
			});

			setUserSummary(myObjs);
		},
	});

	useQuery(GET_ALL_DEPARTMENTS, {
		onCompleted: (data) => {
			setDepartments(data.departments);
		},

		onError: () => {
			ErrorNotification('Failed to load departments');
		},
	});

	useQuery(GET_ADMIN_ROLES, {
		onCompleted: (data) => {
			setAdminRoles(data.admin_roles);
		},

		onError: () => {
			ErrorNotification('Failed to load admin roles');
		},
	});

	useQuery(GET_ALL_REGIONS, {
		onCompleted: (data) => {
			setRegions(data.regions);
		},
	});

	const modal = () => {
		return (
			<Modal
				show={show}
				onHide={handleClose}
				animation={true}
				size={'lg'}
				style={{ marginTop: 30 }}
			>
				<Modal.Header>
					<Modal.Title id="example-custom-modal-styling-title">
            Create User
					</Modal.Title>
					<button
						onClick={() => handleClose()}
						type="button"
						className="close"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={formik.handleSubmit} style={{ margin: 15 }}>
						<div className="row">
							<div className="form-group col-md-5">
								<Form.Group>
									<Form.Label>What user do you want to create?</Form.Label>
									<Form.Control
										as={'select'}
										type={'select'}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name={'user_type'}
									>
										<option value={null}>Select an option</option>
										<option value={'admin'}>Admin</option>
										<option value={'agent'}>Agent</option>
										<option value={'customer'}>Customer</option>
									</Form.Control>
								</Form.Group>
							</div>
							{formik.values['user_type'] === 'admin' ? (
								<>
									<div className="form-group col-md-5">
										<Form.Group>
											<Form.Label>
                        What is the department of the user?
											</Form.Label>
											<Form.Control
												as={'select'}
												type={'select'}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name={'department_id'}
											>
												<option value={null}>Select an option</option>
												{departments
													? departments.map((department, key) => {
														return (
															<option key={key} value={department.id}>
																{department.name}
															</option>
														);
													})
													: null}
											</Form.Control>
										</Form.Group>
									</div>
									{AdminDetails.content.map((field, key) => {
										return (
											<div key={key} className="form-group col-md-5">
												{field.type === 'select' ? (
													<Form.Group>
														<Form.Label>{field.label}</Form.Label>
														<Form.Control
															as={'select'}
															type={'select'}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name={field.name}
														>
															<option value={null}>Select an option</option>
															{adminRoles
																? adminRoles.map((role, key) => {
																	return (
																		<option key={key} value={role.id}>
																			{role.name}
																		</option>
																	);
																})
																: null}
														</Form.Control>
													</Form.Group>
												) : (
													<Form.Group>
														<Form.Label>{field.label}</Form.Label>
														<Form.Control
															type={field.type}
															name={field.name}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															placeholder={field.placeholder}
														></Form.Control>
													</Form.Group>
												)}
											</div>
										);
									})}
								</>
							) : formik.values['user_type'] === 'agent' ? (
								AgentDetails.content.map((field, key) => {
									return (
										<div key={key} className="form-group col-md-5">
											{field.type === 'select' &&
                      field.name === 'momo_network' ? (
													<Form.Group>
														<Form.Label>{field.label}</Form.Label>
														<Form.Control
															as={'select'}
															type={'select'}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name={field.name}
														>
															<option value={null}>Select an option</option>
															{field.option.map((network, key) => {
																return (
																	<option key={key} value={network.value}>
																		{network.name}
																	</option>
																);
															})}
														</Form.Control>
													</Form.Group>
												) : field.type === 'select' &&
                        field.name === 'region_id' ? (
														<Form.Group>
															<Form.Label>{field.label}</Form.Label>
															<Form.Control
																as={'select'}
																type={'select'}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																name={field.name}
															>
																<option value={null}>Select an option</option>
																{regions
																	? regions.map((region, key) => {
																		return (
																			<option key={key} value={region.id}>
																				{region.name}
																			</option>
																		);
																	})
																	: null}
															</Form.Control>
														</Form.Group>
													) : field.type === 'check' ? (
														<Form.Group>
															<Form.Check
																type="checkbox"
																label={field.label}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																name={field.name}
															/>
														</Form.Group>
													) : (
														<Form.Group>
															<Form.Label>{field.label}</Form.Label>
															<Form.Control
																type={field.type}
																name={field.name}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																placeholder={field.placeholder}
															></Form.Control>
														</Form.Group>
													)}
										</div>
									);
								})
							) : formik.values['user_type'] === 'customer' ? (
								CustomerDetails.content.map((field, key) => {
									return (
										<div key={key} className="form-group col-md-5">
											{field.type === 'select' &&
                      field.name === 'momo_network' ? (
													<Form.Group>
														<Form.Label>{field.label}</Form.Label>
														<Form.Control
															as={'select'}
															type={'select'}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name={field.name}
														>
															<option value={null}>Select an option</option>
															{field.option.map((network, key) => {
																return (
																	<option key={key} value={network.value}>
																		{network.name}
																	</option>
																);
															})}
														</Form.Control>
													</Form.Group>
												) : field.type === 'select' &&
                        field.name === 'region_id' ? (
														<Form.Group>
															<Form.Label>{field.label}</Form.Label>
															<Form.Control
																as={'select'}
																type={'select'}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																name={field.name}
															>
																<option value={null}>Select an option</option>
																{regions
																	? regions.map((region, key) => {
																		return (
																			<option key={key} value={region.id}>
																				{region.name}
																			</option>
																		);
																	})
																	: null}
															</Form.Control>
														</Form.Group>
													) : field.type === 'check' ? (
														<Form.Check
															type="checkbox"
															label={field.label}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															name={field.name}
														/>
													) : (
														<Form.Group>
															<Form.Label>{field.label}</Form.Label>
															<Form.Control
																type={field.type}
																name={field.name}
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																placeholder={field.placeholder}
															></Form.Control>
														</Form.Group>
													)}
										</div>
									);
								})
							) : (
								<div style={{ textAlign: 'center', margin: 15 }}>
									<h2>Select a user type to create</h2>
								</div>
							)}
						</div>
						<Button type="submit">Submit</Button>
					</Form>
				</Modal.Body>
			</Modal>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{modal()}
			{/* <div style={{ display: "flex", flexDirection: "row" }}> */}
			<div className="container">
				<div className="row">
					{userSummaryLoading ? (
						<CircularProgress
							style={{ position: 'relative', marginTop: '25%', left: '55%' }}
						/>
					) : (
						userSummary?.map((userType, key) => {
							return (
								<UserCard
									key={key}
									name={userType.label}
									borderColor={userType.borderColor}
									value={userType.value}
								/>
							);
						})
					)}
				</div>
			</div>

			<div
				style={{
					position: 'relative',
					marginTop: '7%',
					display: 'flex',
					justifyContent: 'flex-end',
					padding: 15,
				}}
			>
				<Button
					variant='outline-primary'
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						border: 'solid'
					}}
					onClick={(e) => {
						e.preventDefault();
						createUser();
					}}
				>
					<GrAddCircle size={30} />
					<span style={{ color: 'black' }}>Add User</span>
				</Button>

				{/* <Button
          variant='outline-light'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          onClick={e => {
            e.preventDefault()
            createUser()
          }}
        >
          <GrDownload size={30} />
          <span style={{ color: 'black' }}>Download Users</span>
        </Button> */}
			</div>
		</div>
	);
};

export default UserManagement;
