import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Tile({ productImage }) {
  const [imgSrc, setImgSrc] = useState('');
  const token = localStorage.getItem('accesstoken');

  useEffect(() => {
    const downloadFile = (filename) => {
      var config = {
        method: 'get',
        url: ` https://backend.staging.megafortunelottery.com/storage/file/?itemname=${filename}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: '',
      };

      axios(config)
        .then((response) => {
          setImgSrc(response.data.download_url);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    downloadFile(productImage);
  }, []);

  return (
    <div>
      <img
        alt=""
        src={imgSrc}
        style={{
          height: 120,
          width: 120,
          padding: 10,
        }}
      />
    </div>
  );
}

export default Tile;
