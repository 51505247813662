import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import './index.css';
import ContentComponent from './ContentComponent';
import WriterComponent from './WriterComponent';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    maxHeight: '100vh',
  },
  content: {},
  mainBlock: {
    backgroundColor: '#F7F8FC',
    padding: 30,
  },
});

class Index extends React.Component {
  state = { selectedItem: 'Overview' };

  usertype = localStorage.getItem('usertype');
  

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    console.log("I logged in");
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => this.forceUpdate();

  render() {
    // const { selectedItem } = this.state;
    return (
      <Row className={css(styles.container)}>
        <Column flexGrow={1} className={css(styles.mainBlock)}>
          <div className={css(styles.content)}>
            {this.usertype === 'writerfm' ? (
              <WriterComponent />
            ) : (
              <ContentComponent />
            )}
          </div>
        </Column>
      </Row>
    );
  }
}

export default Index;
