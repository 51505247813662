import React from "react";
import "./App.css";
import Spinner from "./components/Spinner";
import { Content } from "./content";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { client } from "./Appoloclient";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Toaster />
        <React.Suspense fallback={<Spinner />}>
          <Content />
        </React.Suspense>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
