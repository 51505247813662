import { gql } from "@apollo/client";

export const CREATE_ADMIN = gql`
  mutation createAdmin(
    $name: String
    $email: String
    $phone: bigint
    $password: String
    $role: String
    $department_id: uuid!
  ) {
    insert_admin_user_one(
      object: {
        name: $name
        email: $email
        phone_number: $phone
        password: $password
        role: $role
        department_id: $department_id
      }
      on_conflict: {
        constraint: admin_user_email_key
        update_columns: updated_at
      }
    ) {
      id

      name

      email

      phone_number

      role

      password

      is_active
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmin(
    $id: uuid!
    $name: String
    $email: String
    $phone: bigint
    $department_id: uuid
    $role: String
  ) {
    admin: update_admin_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        department_id: $department_id
        name: $name
        email: $email
        phone_number: $phone
        role: $role
      }
    ) {
      id

      role

      department {
        id

        name
      }

      name

      email

      phone_number

      is_active
    }
  }
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation blockAdmin($id: uuid!, $is_active: Boolean) {
    admin: update_admin_user_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id

      role

      department {
        id

        name
      }

      name

      email

      phone_number

      password

      is_active
    }
  }
`;

export const SMS_LOG = gql`
  mutation createSMSLog(
    $recipient_id: uuid
    $schedule_date: timestamptz
    $sender_id: uuid
    $sent_date: timestamptz
    $status: String
    $title: String
    $message: String
  ) {
    insert_sms_log_one(
      object: {
        recipient_id: $recipient_id
        schedule_date: $schedule_date
        sender_id: $sender_id
        sent_date: $sent_date
        status: $status
        title: $title
        message: $message
      }
    ) {
      id
    }
  }
`;

export const SET_BULK_SMS_LOG = gql`
  mutation createBulkSMSLog($messages: [sms_log_insert_input!]!) {
    insert_sms_log(objects: $messages) {
      returning {
        id
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation createProduct(
    $name: String
    $image: String
    $description: String
    $properties: jsonb
    $product_type_id: uuid
    $schedule_type: String
    $run_start: timetz
    $run_end: timetz
    $schedule_start: date
    $schedule_end: date
    $claim_expiry_hours: Int
    $schedule_day: jsonb
    $owner_id: uuid
    $min_stake_amount: float8
    $max_stake_amount: float8
    $odds: float8
    $slogan: String
    $prefix: String
  ) {
    insert_product_one(
      object: {
        name: $name
        image: $image
        description: $description
        properties: $properties
        product_type_id: $product_type_id
        schedule_type: $schedule_type
        run_start: $run_start
        run_end: $run_end
        schedule_start: $schedule_start
        schedule_end: $schedule_end
        claim_expiry_hours: $claim_expiry_hours
        schedule_day: $schedule_day
        owner_id: $owner_id
        min_stake_amount: $min_stake_amount
        max_stake_amount: $max_stake_amount
        odds: $odds
        slogan: $slogan
        prefix: $prefix
      }
    ) {
      id
    }
  }
`;

export const SET_GAME_OF_DAY = gql`
  mutation SetGameofDay($gameId: uuid!) {
    update_game_by_pk(
      pk_columns: { id: $gameId }
      _set: { game_of_day: false }
    ) {
      event_code
      game_of_day
      closed_by
    }
  }
`;

export const CREATE_GAME = gql`
  mutation CreateGame(
    $claim_expiry_hours: Int
    $created_by: uuid!
    $game_of_day: Boolean
    $is_active: Boolean
    $product_id: uuid!
    $properties: jsonb
    $run_end: timetz
    $run_start: timetz
    $schedule_end: date
    $schedule_start: date
  ) {
    insert_game_one(
      object: {
        claim_expiry_hours: $claim_expiry_hours
        created_by: $created_by
        game_of_day: $game_of_day
        is_active: $is_active
        product_id: $product_id
        properties: $properties
        run_end: $run_end
        run_start: $run_start
        schedule_end: $schedule_end
        schedule_start: $schedule_start
      }
    ) {
      claim_expiry_hours
      closed_at
      closed_by
      created_at
      created_by
      is_active
      run_end
      run_start
      schedule_end
      schedule_start
      properties
      product_id
      id
      event_code
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $productId: uuid!
    $image: String
    $description: String
    $properties: jsonb
    $schedule_type: String
    $run_start: timetz
    $run_end: timetz
    $schedule_start: date
    $schedule_end: date
    $claim_expiry_hours: Int
    $schedule_day: jsonb
    $min_stake_amount: float8
    $max_stake_amount: float8
    $slogan: String
    $odds: float8
    $prefix: String
  ) {
    update_product_by_pk(
      pk_columns: { id: $productId }
      _set: {
        claim_expiry_hours: $claim_expiry_hours
        description: $description
        image: $image
        max_stake_amount: $max_stake_amount
        min_stake_amount: $min_stake_amount
        properties: $properties
        run_end: $run_end
        run_start: $run_start
        schedule_end: $schedule_end
        schedule_day: $schedule_day
        schedule_start: $schedule_start
        schedule_type: $schedule_type
        odds: $odds
        slogan: $slogan
        prefix: $prefix
      }
    ) {
      id
    }
  }
`;

export const APPROVE_AGENT = gql`
  mutation updateAgentStatus($_userId: uuid = "") {
    update_customer(
      where: { id: { _eq: $_userId } }
      _set: { status: "approved" }
    ) {
      affected_rows
    }
  }
`;

export const APPROVE_WRITER = gql`
  mutation updateWriterStatus($_userId: uuid = "") {
    update_writer(
      where: { id: { _eq: $_userId } }
      _set: { status: "active" }
    ) {
      affected_rows
    }
  }
`;

export const ADD_COMMISSION_RATE = gql`
  mutation addCommissionRate(
    $max_stake_amount: float8
    $min_stake_amount: float8
    $rate: float8
    $compute_as: String
  ) {
    insert_agent_commission_pricing_one(
      object: {
        max_stake_amount: $max_stake_amount
        min_stake_amount: $min_stake_amount
        rate: $rate
        compute_as: $compute_as
      }
    ) {
      id
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule(
    $product_id: uuid!
    $start_at: date
    $is_active: Boolean
    $end_at: date
    $created_by: uuid!
  ) {
    insert_game_schedule_one(
      object: {
        product_id: $product_id
        start_at: $start_at
        is_active: $is_active
        end_at: $end_at
        created_by: $created_by
      }
    ) {
      start_at
      product_id
      created_by
      created_at
      end_at
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation create_notification(
    $channels: jsonb
    $target: jsonb
    $plain_text: String
    $html: String
    $title: String
    $recipients: [notification_recipient_insert_input!]!
  ) {
    insert_notification_one(
      object: {
        channels: $channels
        message: $plain_text
        html: $html
        title: $title
        target: $target
        recipients: { data: $recipients }
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_DETAILS = gql`
  mutation updateCustomerDetails(
    $userID: uuid
    $address: String
    $digital_address: String
    $ghana_card: String
    $phone_number: bigint
    $region_id: uuid
    $town_city: String
  ) {
    update_customer(
      where: { id: { _eq: $userID } }
      _set: {
        address: $address
        digital_address: $digital_address
        ghana_card: $ghana_card
        phone_number: $phone_number
        region_id: $region_id
        town_city: $town_city
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_AGENT_COMMISSION_PRICING = gql`
  mutation MyMutation($_id: uuid = "") {
    delete_agent_commission_pricing(where: { id: { _eq: $_id } }) {
      affected_rows
    }
  }
`;

export const BLOCK_USER = gql`
  mutation blockUser($_id: uuid = "", $blocked_reason: String = "") {
    update_customer(
      where: { id: { _eq: $_id } }
      _set: { blocked: true, blocked_reason: $blocked_reason }
    ) {
      affected_rows
    }
  }
`;

export const UNBLOCK_USER = gql`
  mutation unblockUser($_id: uuid = "", $blocked_reason: String = "") {
    update_customer(
      where: { id: { _eq: $_id } }
      _set: { blocked: false, blocked_reason: $blocked_reason }
    ) {
      affected_rows
    }
  }
`;

export const CLOSE_GAME = gql`
  mutation closeGame($gameId: uuid!, $adminId: uuid!, $result: jsonb) {
    update_game_by_pk(
      pk_columns: { id: $gameId }
      _set: { closed_by: $adminId, results: $result }
    ) {
      id
    }
  }
`;

export const TERMINATE_GAME = gql`
  mutation TerminateGame(
    $gameId: uuid!
    $reason: String
    $terminated_at: timestamptz
  ) {
    update_game_by_pk(
      pk_columns: { id: $gameId }
      _set: {
        termintated: true
        termination_reason: $reason
        termintated_at: $terminated_at
      }
    ) {
      id
    }
  }
`;

export const ADDWRITERTOWRITERFM = gql`
  mutation addwritertoWriterFm($data: [writerfm_writers_insert_input!]!) {
    insert_writerfm_writers(objects: $data) {
      affected_rows
    }
  }
`;

export const EDIT_ADMIN_ACCESS = gql`
  mutation changeAdminAccess($adminID: uuid, $status: String) {
    update_admin(where: { id: { _eq: $adminID } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const BLOCK_WRITER = gql`
  mutation blockWriter($writerID: uuid, $blocked_reason: String) {
    update_writer(
      where: { id: { _eq: $writerID } }
      _set: { blocked: true, blocked_reason: $blocked_reason, is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const UNBLOCK_WRITER = gql`
  mutation unblockWriter($writerID: uuid) {
    update_writer(
      where: { id: { _eq: $writerID } }
      _set: { blocked: false, is_active: true }
    ) {
      affected_rows
    }
  }
`;
