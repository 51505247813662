import React from 'react';

function SomethingWentWrong() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}
    >
      <h3>Ooops!,something went wrong</h3>
    </div>
  );
}

export default SomethingWentWrong;
