import React, { useState, useEffect } from 'react';
import verified from '../../assets/verified.svg';
import unverified from '../../assets/google-unverified.svg';
import Table from 'react-bootstrap/Table';
import { Modal, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import {
  APPROVE_AGENT,
  UPDATE_CUSTOMER_DETAILS,
  BLOCK_USER,
  UNBLOCK_USER,
} from '../../Graphql/Mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_REGIONS, GET_ALL_AGENTS } from '../../Graphql/Queries';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { useFormik } from 'formik';
import { UpdateCustomerForm } from '../Users/FormDetails';
import imgPlaceholder from '../../assets/ImgPlaceHolder.jpg';
import { api } from '../../utils/Api';
import { SEND_NOTIFICATION } from '../../Graphql/Mutations';

const AgentDetails = (props) => {
  const location = useLocation();

  const agent = location.state;
  // const history = useHistory();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [blockUserShow, setBlockUserShow] = useState(false);
  const [regions, setRegions] = useState(null);
  const [agentRegion, setAgentRegion] = useState('');
  const [agentSelfie, setAgentSelfie] = useState(null);
  const [agentGhanaCard, setAgentGhanaCard] = useState(null);
  const [blockedReason, setBlockedReason] = useState('');

  // console.log(agent)
  const {
    first_name,
    last_name,
    email,
    phone_number,
    status,
    created_at,
    address,
    digital_address,
    ghana_card,
    selfie,
    region_id,
    town_city,
    id,
    blocked,
    blocked_reason,
  } = agent;

  const { data: regionData } = useQuery(GET_ALL_REGIONS);

  useEffect(() => {
    let regionName = [];

    if (regionData) {
      // console.log(regionData)
      setRegions(regionData.regions);

      regionName = regionData.regions.filter((region) => {
        return region.id === region_id;
      });

      // console.log(regionName[0].name)
      setAgentRegion(regionName[0]?.name);
    }
  }, [regionData]);

  const [CreateSmsRecord] = useMutation(SEND_NOTIFICATION, {
    onCompleted: () => {
      SuccessNotification('Successfully approved agent');
    },
    onError: () => ErrorNotification('Agent approval failed, try again'),
  });

  const sms_action = () => {
    // let target = ["agents"]
    let customers = [{ customer_id: id }];

    CreateSmsRecord({
      variables: {
        channels: ['sms', 'api'],
        plain_text:
          'Your account has been successfully verified.Login to the app and start staking',
        title: 'Account verification successful',
        recipients: customers,
      },
    });
  };

  const loadImages = async (img, setMethod) => {
    api
      .get(`/storage/file/?itemname=${img}`)
      .then((response) => {
        // SuccessNotification('Successfully created User')
        setMethod(response.data.download_url);
      })
      .catch((err) => {
        // setError(err);
        console.log(err);
        ErrorNotification('Resource could not be loaded');
      });
  };

  useEffect(() => {
    loadImages(selfie, setAgentSelfie);
    loadImages(ghana_card, setAgentGhanaCard);
  }, []);

  // console.log(agent)

  const formik = useFormik({
    initialValues: {
      address: null,
      digital_address: null,
      ghana_card: null,
      phone_number: null,
      region_id: null,
      town_city: null,
    },

    onSubmit: () => {
      // console.log(values)
      updateAgent();
    },
  });

  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  };

  const call = () => {
    sms_action();
    navigate(-1);
  };

  const updateAgentCall = () => {
    SuccessNotification('Successfully updated Agent');
    navigate(-1);
  };

  const [approveAgent] = useMutation(APPROVE_AGENT, {
    variables: {
      _userId: id,
    },
    onCompleted: () => call(),
    onError: () =>
      ErrorNotification(
        'There was an issue with your request, kindly enter right information and try again',
      ),
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'getAgents'],
  });

  const [updateAgent] = useMutation(UPDATE_CUSTOMER_DETAILS, {
    variables: {
      userID: id,
      address: formik.values.address,
      digital_address: formik.values.digital_address,
      ghana_card: formik.values.ghana_card,
      phone_number: Number(formik.values.phone_number),
      region_id: formik.values.region_id,
      town_city: formik.values.town_city,
    },
    onCompleted: () => updateAgentCall(),
    onError: () =>
      ErrorNotification(
        'There was an issue with your request, kindly review the information entered and try again',
      ),
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'getAgents'],
  });

  const [blockAgent] = useMutation(BLOCK_USER, {
    onCompleted: () => {
      SuccessNotification('Agent has been blocked');
      navigate(-1);
    },
    onError: () => {
      ErrorNotification(
        'There was an issue with your request, kindly review the information entered and try again',
      );
    },
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'getAgents'],
  });

  const [unblockAgent] = useMutation(UNBLOCK_USER, {
    onCompleted: () => {
      SuccessNotification('Agent has been unblocked');
      navigate(-1);
    },

    onError: () => {
      ErrorNotification('An error occured when unblocking agent. Try again');
    },
    refetchQueries: [{ query: GET_ALL_AGENTS }, 'getAgents'],
  });

  const handleClose = () => {
    setShow(false);
  };

  const updateUser = () => {
    setShow(true);
  };

  const blockUserDialog = () => {
    setBlockUserShow(true);
  };

  const handleCloseForBlockUserDialog = () => {
    setBlockUserShow(false);
  };

  const blockUserModal = () => {
    return (
      <Modal
        show={blockUserShow}
        onHide={handleCloseForBlockUserDialog}
        animation={true}
        size={'sm'}
        style={{ marginTop: 30 }}
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Block Agent
          </Modal.Title>
          <button
            onClick={() => handleCloseForBlockUserDialog()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="font-loader">Blocked Reason</Form.Label>
              <Form.Control
                onChange={(e) => setBlockedReason(e.target.value)}
                type="text"
                class="form-control"
                placeholder=""
              ></Form.Control>
            </Form.Group>{' '}
            <br />
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                blockAgent({
                  variables: {
                    _id: id,
                    blocked_reason: blockedReason,
                  },
                });
              }}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const updateUserModal = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size={'lg'}
        style={{ marginTop: 30 }}
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Update Agent
          </Modal.Title>
          <button
            onClick={() => handleClose()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="form-group col-md-5">
                <Form.Group>
                  <Form.Label>What region is the agent from?</Form.Label>
                  <Form.Control
                    as={'select'}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={'region_id'}
                  >
                    <option value={null}>Select an option</option>
                    {regions
                      ? regions.map((region, key) => {
                        return (
                          <option key={key} value={region.id}>
                            {region.name}
                          </option>
                        );
                      })
                      : null}
                  </Form.Control>
                </Form.Group>
              </div>
              {UpdateCustomerForm.content.map((field, key) => {
                return (
                  <div key={key} className="form-group col-md-5">
                    <Form.Group>
                      <Form.Label>{field.label}</Form.Label>
                      <Form.Control
                        type={field.type}
                        name={field.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                );
              })}
            </div>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {updateUserModal()}
      {blockUserModal()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '85%',
          justifyContent: 'space-between',
          padding: 15,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h1 style={{ margin: 10 }}>AGENT DETAILS</h1>
          <img
            alt=""
            style={{ height: 20, width: 20 }}
            src={status === 'pending' ? unverified : verified}
          />
        </div>
        <div>
          <ButtonGroup variant="contained" aria-label="Basic button group" style={{ marginTop: 15 }}>
            <Button
              style={{ marginTop: 15 }}
              color="secondary"
              size="small" 
              onClick={() => { navigate(-1); }}
            >
              Back
            </Button>
            <Button variant="text" size="small"  style={{ marginTop: 15 }}>&nbsp;</Button>
            <Dropdown style={{ position: 'relative', marginTop: 15 }}>
              <Dropdown.Toggle
                style={{ backgroundColor: '#0E3E86' }}
                id="dropdown-basic"
              >
                Perform Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => approveAgent()}>
                  Approve Agent
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    blockUserDialog();
                  }}
                >
                  Block Agent
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    unblockAgent({
                      variables: {
                        _id: id,
                        blocked_reason: '',
                      },
                    });
                  }}
                >
                  Unblock Agent
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    updateUser();
                  }}
                >
                  Update Agent
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>
        </div>
      </div>
      <div>
        <div style={{ margin: 15 }}>
          Created at:{' '}
          {new Date(created_at).toLocaleDateString('en-GB', options)}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 10,
        }}
      >
        <Table
          bordered
          striped
          style={{
            width: '40%',
            margin: 15,
          }}
        >
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                {first_name} {last_name}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td>Phone&nbsp;Number</td>
              <td>{phone_number}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td
                style={{
                  background: status === 'approved' ? "#28a745" : "#dc3545",
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: 'white',
                }}
              >
                {status}
              </td>
            </tr>
            <tr>
              <td>Digital Address</td>
              <td>{digital_address}</td>
            </tr>
            <tr>
              <td>Blocked Status</td>
              <td
                style={{
                  background: blocked ? '#c62828' : null,
                  textAlign: 'center',
                  textTransform: 'capitalize',
                }}
              >
                {blocked ? (
                  <span
                    style={{
                      color: blocked ? 'white' : null,
                    }}
                  >
                    Blocked
                  </span>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>Blocked Reason</td>
              <td>{blocked_reason}</td>
            </tr>
            <tr>
              <td>Region</td>
              <td>{agentRegion}</td>
            </tr>
            <tr>
              <td>Town / City</td>
              <td>{town_city}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{address}</td>
            </tr>
          </tbody>
        </Table>

        <div
          style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}
        >
          <div style={{ marginBottom: '5%', textAlign: 'center' }}>
            <h4>Profile Image</h4>
            <img
              style={{ height: 200 }}
              src={agentSelfie ? agentSelfie : imgPlaceholder}
              alt="/"
            />
          </div>
          <div style={{ marginBottom: '5%', textAlign: 'center' }}>
            <h4>Ghana Card</h4>
            <img
              style={{ height: 400 }}
              src={agentGhanaCard ? agentGhanaCard : imgPlaceholder}
              alt="/"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDetails;
