import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as ReactDOM from "react-dom/client";
import './index.css';
import * as serviceWorker from './serviceWorker';
import AppWrapper from './AppWrapper';
// import Auth0ProviderWithHistory from "../src/auth0/auth0-provider-with-history"

import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
      ),
    }),
    new Sentry.Replay(),
  ],
  // sets the runnning environment i.e. production/staging
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,

  // TODO: Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // TODO: Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.REACT_APP_API_BASE_URL],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppWrapper />
);

serviceWorker.unregister();
