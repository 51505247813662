import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import verified from "../../assets/verified.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { EDIT_ADMIN_ACCESS } from "../../Graphql/Mutations";
import { GET_ALL_ADMINS } from "../../Graphql/Queries";
import { useMutation } from "@apollo/client";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../components/Notification";

const AdminsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const admin = location.state;
  const { created_at, id, first_name, last_name, phone_number, email, status } =
    admin;
  const [show, setShow] = useState(false);

  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    weekday: "long",
  };

  const handleClose = () => {
    setShow(false);
  };

  const [editAdminAccess] = useMutation(EDIT_ADMIN_ACCESS, {
    onCompleted: () => {
      SuccessNotification("Admin has been revoked successfully");
    },

    onError: (error) => {
      ErrorNotification("Admin revoke failed check");
      console(error);
    },
    refetchQueries: [{ query: GET_ALL_ADMINS }, 'getAllAdmins'],
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size={"md"}
        style={{ marginTop: 30 }}
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Edit Admin Access
          </Modal.Title>
          <button
            onClick={() => handleClose()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              justifyContent: "center",
              padding: 25,
            }}
          >
            {status === "active" ? (
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                Are you sure you want to revoke the access of this user?
              </h5>
            ) : (
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                Are you sure you want to grant admin access back to this user?
              </h5>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  margin: 8,
                }}
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  if (status === "active") {
                    editAdminAccess({
                      variables: {
                        adminID: id,
                        status: "Access Revoked",
                      },
                    });
                  } else {
                    editAdminAccess({
                      variables: {
                        adminID: id,
                        status: "active",
                      },
                    });
                  }
                  setShow(false);
                  navigate(-1);
                }}
              >
                Yes
              </Button>
              <Button
                style={{
                  margin: 8,
                }}
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "85%",
          justifyContent: "space-between",
          padding: 15,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1 style={{ margin: 10 }}>ADMIN DETAILS</h1>
          <img alt="" style={{ height: 20, width: 20 }} src={verified} />
        </div>
        {/* <div>
          <Dropdown style={{ position: 'relative', marginTop: 15 }}>
            <Dropdown.Toggle
              style={{ backgroundColor: '#0E3E86' }}
              id='dropdown-basic'
            >
              Perform Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                Approve Agent
              </Dropdown.Item>

              <Dropdown.Item>Block Agent</Dropdown.Item>
              <Dropdown.Item>Unblock Agent</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <div style={{ margin: 15 }}>
          Created at:{" "}
          {new Date(created_at).toLocaleDateString("en-GB", options)}
        </div>
        <div>
          {status === "active" ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
              variant="danger"
            >
              Revoke Access
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
              variant="success"
            >
              Grant Access
            </Button>
          )}
        </div>
      </div>
      <div style={{ padding: 15 }}>
        <Table
          bordered
          striped
          style={{
            width: "40%",
          }}
        >
          <tbody>
            <tr>
              <td>Admin Name</td>
              <td>
                {first_name} {last_name}
              </td>
            </tr>
            <tr>
              <td>Admin Email</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td>Admin Phone Number</td>
              <td>{phone_number}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td
                style={{
                  background:
                    status === "pending"
                      ? "#ffee58"
                      : status === "active"
                        ? "#76ff03"
                        : "#D32F2F",
                  textAlign: "center",
                  textTransform: "capitalize",
                  color: status === "Access Revoked" ? "white" : "black",
                }}
              >
                {status}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdminsDetails;
