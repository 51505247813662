import React, { Component, useContext } from 'react';
const Context = React.createContext();

class Provider extends Component {
  reducer = (state, action) => {
    switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        user: action.payload,
        error: action.error,
        isLoggedIn: action.payload ? true : false,
      };

    default:
      return state;
    }
  };
  state = {
    user: null,
    error: '',
    profile_pic: '',
    vehicle_info: [],
    insurance_products: [],

    dispatch: (action) => {
      this.setState((state) => this.reducer(state, action));
    },
  };

  async componentDidMount() {
    try {
      // this.fetchVehicleInfo();
      // this.fetchAllPolicies();
    } catch (error) {
      console.log(error);
    }
  }

  // fetchVehicleInfo = () => {
  //   this.props.client
  //     .query({
  //       query: gql`
  //         query BodyTypesMakeModelCurrencies {
  //           body_types: body_type(
  //             order_by: { name: asc }
  //             where: { is_active: { _eq: true } }
  //           ) {
  //             id

  //             name
  //           }

  //           vehicle_makes: vehicle_make(
  //             order_by: { name: asc }
  //             where: { is_active: { _eq: true } }
  //           ) {
  //             id

  //             name

  //             vehicle_models(
  //               order_by: { name: asc }
  //               where: { is_active: { _eq: true } }
  //             ) {
  //               id

  //               name
  //             }
  //           }

  //           usage_types(
  //             order_by: { name: asc }
  //             where: { is_active: { _eq: true } }
  //           ) {
  //             id

  //             name
  //           }

  //           currencies: currency(
  //             order_by: { name: asc }
  //             where: { is_active: { _eq: true } }
  //           ) {
  //             id

  //             name

  //             symbol
  //           }
  //         }
  //       `,
  //     })
  //     .then((json) => {
  //       console.log("json_data",json.data)
  //       this.setState({ vehicle_info: json.data });
  //     });
  // };

  // fetchAllPolicies = () => {
  //   this.setState({ policyloading: true });
  //   this.props.client
  //     .query({
  //       query: gql`
  //         query GetAllInsuranceProducts {
  //           product_type(
  //             where: { is_active: { _eq: true } }
  //             order_by: { name: asc }
  //           ) {
  //             id

  //             name

  //             description

  //             insurance_products(
  //               where: { is_active: { _eq: true } }
  //               order_by: { name: asc }
  //             ) {
  //               id

  //               name

  //               description

  //               requirements

  //               created_at
  //             }

  //             product_benefits {
  //               id

  //               name
  //             }
  //           }
  //         }
  //       `,
  //     })
  //     .then((json) => {
  //       this.setState({ policyloading: false });
  //       this.setState({ allpolicies: json.data.product_type });

  //       let products = [];

  //       for (let i = 0; i < json.data.product_type.length; i++) {
  //         if (json.data.product_type[i].name === "Motor Insurance") {
  //           products.push(json.data.product_type[i].insurance_products);
  //         }
  //       }
  //       this.setState({ insurance_products: products });
  //     });
  // };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
const useAppContext = () => useContext(Context);

export { Provider, useAppContext };
