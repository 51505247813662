import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminModal from '../../components/Modal';
import addIcon from '../../assets/plus.svg';
import './index.css';
import axios from 'axios';
import { SmsForm } from './Allforms';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import MessageDetail from './MessageDetail';
import { useMutation, useQuery } from '@apollo/client/react';
import { SMS_LOG } from '../../Graphql/Mutations';
import { GET_SMS_LOG } from '../../Graphql/Queries';
import { formatDateFull } from '../../utils';

const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: true,
      sort: true,
      display: false,
    },
  },
  {
    name: 'title',
    label: 'Sms Title',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'message',
    label: 'Message',
    options: {
      filter: true,
      sort: true,
      display: false,
    },
  },
  {
    name: 'sent_by',
    label: 'SENT BY',
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: "sent_to",
  //   label: "SENT TO",
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
  {
    name: 'sent_date',
    label: 'DATE SENT',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'schedule_date',
    label: 'Schedule Date',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    align: 'right',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (val) => {
        return (
          <div>
            {val === 'SENT' ? (
              <div className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center">
                <p className="text-green">{val.toLowerCase()}</p>
              </div>
            ) : val === 'PENDING' ? (
              <div className="px-4  mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center">
                <p className="text-orange">{val.toLowerCase()}</p>
              </div>
            ) : (
              <div className="px-3 py-1 mt-2 divheight bg-blueLight rounded-12 flex items-center justify-center">
                <p className="text-blue">{val.toLowerCase()}</p>
              </div>
            )}
          </div>
        );
      },
    },
  },
];

const TableList = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const [hideReciepient, setReciepient] = useState(false);
  const handleclose = () => setShowModal(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [smsplan, setSmsPlan] = useState('1');
  const [rawData, setRawData] = useState();
  const [smsData, setsmsData] = useState();
  const [detail, setDetail] = useState('');
  const status = 'SENT';

  const [state, setState] = React.useState({
    title: '',
    body: '',
    phone: '',
  });

  // const today = new Date();

  const { data: sms_data, refetch } = useQuery(GET_SMS_LOG, {
    onCompleted: (data) => {
      let tabledata = [];
      setRawData(data.sms_log);
      for (var i = 0; i < data.sms_log.length; i++) {
        let c = {
          title: data.sms_log[i].title,
          id: data.sms_log[i].id,
          status: data.sms_log[i].status,
          sent_date: formatDateFull(data.sms_log[i].sent_date),
          sent_by: data.sms_log[i].sender.name,
          message: data.sms_log[i].message,
          sent_to: sms_data.data[i].recipient.customers.length + ' customer(s)',
          schedule_date: formatDateFull(data.sms_log[i].schedule_date),
        };

        tabledata.push(c);
      }
      setsmsData(tabledata);
    },

    onError: () => {
      ErrorNotification('Failed to fetch sms data');
    },
  });

  const [CreateSmsRecord] = useMutation(SMS_LOG, {
    onCompleted: () => {
      sendSms();
    },
    onError: () => ErrorNotification('Failed to create sms record'),
  });

  const { title, body, phone } = state;
  const values = {
    title,
    body,
    phone,
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const send_sms = () => {
    CreateSmsRecord({
      variables: {
        recipient_id: selectedOption[0].id,

        schedule_date: '2022-08-22',

        sender_id: localStorage.getItem('token'),

        sent_date: '2022-08-22',

        status: status,

        title: state.title,

        message: state.body,
      },
    });
  };

  const sendSms = () => {
    // var data = "";
    setLoading(true);

    axios
      .post(
        `https://backend.staging.mypolicy.market/external/sms/?message=${body}&phone=${selectedOption[0].value}`,
      )
      .then(() => {
        setLoading(false);
        SuccessNotification('Sms sent successfully');
        refetch();
        setShowModal(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const finobject = (data, id) => {
    let y = data.find((x) => x.id === id);
    return y;
  };

  const handleRowClick = (rowData) => {
    let chosenItem = finobject(rawData, rowData[0]);
    setDetail(chosenItem);
    setShowMDetailModal(true);
  };
  const setReciepientShow = (value) => {
    if (value === '2') {
      setReciepient(true);
    } else {
      setReciepient(false);
    }
  };

  const users = [
    {
      id: '6511314f-3ad8-4a70-9507-6bf817a1d1d6',
      value: '0508305891',
      label: 'Paul Dwamena',
    },
    {
      id: '6511314f-3ad8-4a70-9507-6bf817a1d1d6',
      value: '0559225573',
      label: 'Rita Dwamena',
    },
    {
      id: '6511314f-3ad8-4a70-9507-6bf817a1d1d6',
      value: '0244175837',
      label: 'Ama Asantewaa',
    },
  ];

  const options = {
    filterType: 'checkbox',
    onRowClick: handleRowClick,
    sortOrder: {
      name: 'Date_Purchased',
      direction: 'desc',
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showModal}
        close={!showModal}
        title={'Create new sms'}
        body={
          <SmsForm
            users={users}
            setReciepientShow={setReciepientShow}
            hideReciepient={hideReciepient}
            values={values}
            handleChange={handleChange}
            setSelectedOption={setSelectedOption}
            loading={loading}
            sendSms={send_sms}
            setSmsPlan={setSmsPlan}
            smsplan={smsplan}
          />
        }
        handleClose={handleclose}
      />

      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Message details'}
        body={<MessageDetail detail={detail} />}
        handleClose={handleclose2}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <h3 style={{ color: '#0E3E86', fontWeight: 600 }}>Sms Records </h3>
        <button className="btn btn-info" onClick={() => setShowModal(true)}>
          <img alt="" src={addIcon} style={{ position: 'relative', top: -1 }} />{' '}
          Create Sms
        </button>
      </div>

      <MUIDataTable
        data={smsData}
        columns={columns}
        options={options}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default TableList;
