import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { ErrorNotification } from './Notification';

function TerminateGameComponent({ id, terminateGame }) {
  const [reason, setReason] = useState('');

  const convertToTimeStamp = () => {
    let newDate = new Date();
    // console.log(newDate.toISOString())
    return newDate.toISOString();
  };

  const raiseAlert = () => {
    Swal.fire({
      title: 'Are you sure you want to terminate this game',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,accept',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          terminateGame({
            variables: {
              gameId: id,
              reason: reason,
              terminated_at: convertToTimeStamp(),
            },
          });
        } catch (error) {
          console.log(error);
          ErrorNotification('An error occured while closing game');
        }
      }
    });
  };

  return (
    <div style={{ padding: 15 }}>
      <div style={{ marginTop: 10 }}>
        <div
          className="form-group"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <label htmlFor="exampleFormControlInput1">Termination reason</label>
          <textarea
            onChange={(e) => setReason(e.target.value)}
            id="w3review"
            name="w3review"
            rows="4"
            cols="95"
          />
        </div>
      </div>

      <div style={{ width: '40%' }}>
        <button onClick={() => raiseAlert()} className="btn btn-danger">
          Terminate game
        </button>
      </div>
    </div>
  );
}

export default TerminateGameComponent;
