import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminModal from '../../components/Modal';
import addIcon from '../../assets/plus.svg';
import './index.css';
import { SendEmail } from './Allforms';

const columns = [
  {
    name: 'Intermediary_Id',
    label: 'Email Subject',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'Intermediary_Type',
    label: 'SENT BY',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'Name',
    label: 'SENT TO',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'Date_Joined',
    label: 'DATE SENT',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'Status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (val) => {
        return (
          <div>
            {val === 'DELIVERED' ? (
              <div className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center">
                <p className="text-green">{val.toLowerCase()}</p>
              </div>
            ) : val === 'PENDING' ? (
              <div className="px-3 py-1 mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center">
                <p className="text-orange">{val.toLowerCase()}</p>
              </div>
            ) : (
              <div className="px-3 py-1 mt-2 divheight bg-blueLight rounded-12 flex items-center justify-center">
                <p className="text-blue">{val.toLowerCase()}</p>
              </div>
            )}
          </div>
        );
      },
    },
  },
];

const EmailTableList = () => {
  const [showModal, setShowModal] = useState(false);
  const [hideReciepient, setReciepient] = useState(false);
  const handleclose = () => setShowModal(false);

  let loading = false;
  const [state, setState] = React.useState({
    title: '',
    body: '',
    phone: '',
  });

  const { title, body, phone } = state;
  const values = {
    title,
    body,
    phone,
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const handleRowClick = () => {
    setShowModal(true);
  };

  const setReciepientShow = (value) => {
    if (value === '2') {
      setReciepient(true);
    } else {
      setReciepient(false);
    }
  };

  const data = [
    {
      Intermediary_Id: 'Payment Reminder',
      Intermediary_Type: 'Charles Benson Brokerage',
      Name: 'Charles Benson',
      Status: 'DELIVERED',
      Date_Joined: '24th January 2022',
    },
    {
      Intermediary_Id: 'Verification Reminder',
      Intermediary_Type: 'Charles Benson',
      Name: 'J & J Brokerage',
      Status: 'PENDING',
      Date_Joined: '24th January 2022',
    },
  ];

  const options = {
    filterType: 'checkbox',
    onRowClick: handleRowClick,
    sortOrder: {
      name: 'Date_Purchased',
      direction: 'desc',
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showModal}
        close={!showModal}
        title={'Create new email'}
        body={
          <SendEmail
            setReciepientShow={setReciepientShow}
            hideReciepient={hideReciepient}
            values={values}
            handleChange={handleChange}
            loading={loading}
            handleClose={handleclose}
            // sendSms={sendSms}
          />
        }
        handleClose={handleclose}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <h3 style={{ color: '#0E3E86', fontWeight: 'bold' }}>Email Records </h3>
        <button className="btn btn-info" onClick={() => setShowModal(true)}>
          <img alt="" src={addIcon} style={{ position: 'relative', top: -1 }} />{' '}
          Create Email
        </button>
      </div>

      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default EmailTableList;
