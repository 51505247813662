import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { SEND_NOTIFICATION } from '../../Graphql/Mutations';
import { GET_MAILING_LIST } from '../../Graphql/Queries';
import Select from 'react-select';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';

const NewEmail = () => {
  let myLoading = false;
  const [mailingList, setMailingList] = useState(null);
  const [usertype, setuserType] = useState('1');
  const [hideReciepient, setReciepient] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [state, setState] = useState({
    email: '',
    reciepient_name: '',
    subject: '',
    body: '',
  });

  const setReciepientShow = (value) => {
    if (value === '2') {
      setReciepient(true);
    } else {
      setReciepient(false);
    }
  };

  useQuery(GET_MAILING_LIST, {
    onCompleted: (data) => {
      let userArray = [];
      for (let i = 0; i < data.mailingList.length; i++) {
        let d = {
          id: data.mailingList[i].id,
          label: data.mailingList[i].full_name,
          value: data.mailingList[i].email,
        };

        userArray.push(d);
      }

      setMailingList(userArray);
    },

    onError: () => {
      // console.log(error)
      ErrorNotification('Unable to load mailing list');
    },
  });

  const [sendMail] = useMutation(SEND_NOTIFICATION, {
    onCompleted: () => SuccessNotification('Email sent successfully'),
    onError: () => ErrorNotification('Email failed to send'),
  });

  /**
   * Sending the email notifications through Hasura mutation
   */
  const emailAction = () => {
    const { subject, body } = state;

    if (usertype === '1') {
      sendMail({
        variables: {
          channels: ['email'],
          target: ['agents', 'customers'],
          plain_text: body,
          html: body,
          title: subject,
          recipients: [],
        },
      });
    } else if (usertype === '3') {
      sendMail({
        variables: {
          channels: ['email'],
          target: ['agents'],
          plain_text: body,
          html: body,
          title: subject,
          recipients: [],
        },
      });
    } else if (usertype === '4') {
      sendMail({
        variables: {
          channels: ['email'],
          target: ['customers'],
          plain_text: body,
          html: body,
          title: subject,
          recipients: [],
        },
      });
    } else if (usertype === '2') {
      let ids = [];

      for (let i = 0; i < selectedOption.length; i++) {
        ids.push({
          customer_id: selectedOption[i].id,
        });
      }

      sendMail({
        variables: {
          channels: ['email'],
          target: null,
          plain_text: body,
          html: body,
          title: subject,
          recipients: ids,
        },
      });
    }
  };

  // const submitEmail = () => {
  //   const { email, name, subject, body } = state;
  //   setMyLoading(true);
  //   var config = {
  //     method: "post",
  //     url: `https://backend.staging.megafortunelottery.com/notifications/email?recipient_email=${email}&recipient_name=${name}&subject=${subject}&html_content=${body}`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage["accesstoken"]}`,
  //     },
  //     data: "",
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setState({
  //         email: "",
  //         reciepient_name: "",
  //         subject: "",
  //         body: "",
  //       });
  //       setMyLoading(false);
  //       SuccessNotification("Email has been sent successfully");
  //     })
  //     .catch(function (error) {
  //       setMyLoading(false);
  //       ErrorNotification("There was an error in sending email");
  //     });
  // };

  function handleChange(evt) {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  return (
    <Container style={{ padding: 50 }} maxWidth={false}>
      <div
        style={{
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          padding: 40,
        }}
      >
        <p style={{ fontWeight: 'bolder' }}>Compose New Email</p>
        <hr />
        <form style={{ width: '100%' }}>
          <div className="form-group">
            <label>
              Select recipient type
              <select
                onChange={(e) => {
                  {
                    setReciepientShow(e.target.value);
                    setuserType(e.target.value);
                  }
                }}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="0">Select Users</option>
                <option value="1">All users</option>
                <option value="3">All Agents</option>
                <option value="4">All Customers</option>
                <option value="2">Selected users</option>
              </select>
            </label>
          </div>

          {/* <div className="form-group">
            <label for="exampleInputEmail1">Reciepient Name</label>

            <input
              onChange={handleChange}
              name="reciepient_name"
              type="text"
              className="form-control"
              value={state.reciepient_name}
            />
          </div> */}

          {hideReciepient ? (
            <div className="form-group ">
              <label htmlFor="contact" className="font-loader">
                Choose Contact to send sms
              </label>
              <span style={{ color: 'red' }}> *</span>

              <Select
                id="contact"
                onChange={setSelectedOption}
                name="mail"
                isMulti
                options={mailingList}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          ) : null}

          <div className="form-group">
            <label>
              Email Subject
              <input
                onChange={handleChange}
                name="subject"
                type="text"
                className="form-control"
                value={state.subject}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Email Body
              <textarea
                onChange={handleChange}
                name="body"
                cols="40"
                rows="5"
                className="form-control"
                value={state.body}
              />
            </label>
          </div>

          <button
            onClick={(e) => {
              e.preventDefault();
              // submitEmail()
              emailAction();
            }}
            className="btn btn-primary form-control"
          >
            {myLoading ? 'myLoading...' : 'Send Email'}
          </button>
        </form>
      </div>
    </Container>
  );
};

NewEmail.propTypes = {
  className: PropTypes.string,
};

export default NewEmail;
