import React from 'react';

const ChangePassword = () => {
  return (
    <div className="card">
      <form>
        <div className="form-row" style={{ marginLeft: '1%', width: '100%' }}>
          <div className="form-group col-md-5" style={{ margin: 10 }}>
            <label htmlFor="oldPassword">
              Old Password
              <span style={{ color: 'red' }}>*</span>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                placeholder="Old Password"
              />
            </label>
          </div>
          <div className="form-group col-md-5" style={{ margin: 10 }}>
            <label htmlFor="NewPassword">
              New Password
              <span style={{ color: 'red' }}>*</span>
              <input
                type="password"
                className="form-control"
                id="NewPassword"
                placeholder="New Password"
              />
            </label>
          </div>
          <div className="form-group col-md-5" style={{ margin: 10 }}>
            <label htmlFor="ConfirmPassword">
              Confirm Password
              <span style={{ color: 'red' }}>*</span>
              <input
                type="password"
                className="form-control"
                id="ConfirmPassword"
                placeholder="Confirm Password"
              />
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '90%',
              padding: 20,
            }}
          >
            <button
              className="btn btn-info"
              onClick={(e) => {
                e.preventDefault();
                // Add your form submission logic here
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
