import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Modal, Button, Form } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import {
	SuccessNotification,
	ErrorNotification,
} from '../../components/Notification';
// import { useHistory } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { GET_ALL_REGIONS, GET_ALL_CUSTOMERS } from '../../Graphql/Queries';
import {
	UPDATE_CUSTOMER_DETAILS,
	BLOCK_USER,
	UNBLOCK_USER,
} from '../../Graphql/Mutations';
import { UpdateCustomerForm } from './FormDetails';
import imgPlaceholder from '../../assets/ImgPlaceHolder.jpg';
import { api } from '../../utils/Api';

const CustomerDetails = () => {
	const location = useLocation();
	const customer = location.state;

	// const history = useHistory();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [blockUserShow, setBlockUserShow] = useState(false);
	const [regions, setRegions] = useState(null);
	const [customerRegion, setCustomerRegion] = useState('');
	const [customerSelfie, setCustomerSelfie] = useState(null);
	// const [customerSelfieError, setCustomerSelfieError] = useState(null);
	const [customerGhanaCard, setCustomerGhanaCard] = useState(null);
	// const [customerGhanaCardError, setCustomerGhanaCardError] = useState(null);

	const [blockedReason, setBlockedReason] = useState('');

	const {
		address,
		digital_address,
		email,
		first_name,
		ghana_card,
		id,
		last_name,
		phone_number,
		selfie,
		status,
		region_id,
		town_city,
		blocked,
		blocked_reason,
	} = customer;

	console.log(customer);

	useQuery(GET_ALL_REGIONS, {
		onCompleted: (data) => {
			let regionName = [];

			// console.log(regionData)
			setRegions(data.regions);

			regionName = data.regions.filter((region) => {
				return region.id === region_id;
			});

			// console.log(regionName[0].name)
			setCustomerRegion(regionName[0]?.name);
		},
	});

	const loadImages = async (img, setMethod) => {
		api
			.get(`/storage/file/?itemname=${img}`)
			.then((response) => {
				// SuccessNotification('Successfully created User')
				setMethod(response.data.download_url);
			})
			.catch(() => {
				ErrorNotification('Resource could not be loaded');
			});
	};

	useEffect(() => {
		loadImages(selfie, setCustomerSelfie);
		loadImages(ghana_card, setCustomerGhanaCard);
	}, []);

	const formik = useFormik({
		initialValues: {
			address: '',
			digital_address: '',
			ghana_card: '',
			phone_number: '',
			region_id: '',
			town_city: '',
		},

		onSubmit: () => {
			updateCustomer();
		},
	});

	const updateCustomerCall = () => {
		SuccessNotification('Successfully updated Customer');
		navigate(-1);
	};

	// console.log(customer)

	const [updateCustomer] = useMutation(UPDATE_CUSTOMER_DETAILS, {
		variables: {
			userID: id,
			address: formik.values.address,
			digital_address: formik.values.digital_address,
			ghana_card: formik.values.ghana_card,
			phone_number: Number(formik.values.phone_number),
			region_id: formik.values.region_id,
			town_city: formik.values.town_city,
		},
		onCompleted: () => updateCustomerCall(),
		onError: (error) => ErrorNotification(error),
		refetchQueries: [{ query: GET_ALL_CUSTOMERS }, 'getAllCustomers'],
	});

	const [blockCustomer] = useMutation(BLOCK_USER, {
		onCompleted: () => {
			SuccessNotification('Agent has been blocked');
			navigate(-1);
		},
		onError: () => {
			ErrorNotification('Error occurred when blocking agent');
		},
		refetchQueries: [{ query: GET_ALL_CUSTOMERS }, 'getAllCustomers'],
	});

	const [unblockCustomer] = useMutation(UNBLOCK_USER, {
		onCompleted: () => {
			SuccessNotification('Agent has been unblocked');
			navigate(-1);
		},

		onError: () => {
			ErrorNotification('Error occurred while unblicking agent');
		},
		refetchQueries: [{ query: GET_ALL_CUSTOMERS }, 'getAllCustomers'],
	});

	const handleClose = () => {
		setShow(false);
	};

	const updateUser = () => {
		setShow(true);
	};

	const blockUserDialog = () => {
		setBlockUserShow(true);
	};

	const handleCloseForBlockUserDialog = () => {
		setBlockUserShow(false);
	};

	const blockUserModal = () => {
		return (
			<Modal
				show={blockUserShow}
				onHide={handleCloseForBlockUserDialog}
				animation={true}
				size={'sm'}
				style={{ marginTop: 30 }}
			>
				<Modal.Header>
					<Modal.Title id="example-custom-modal-styling-title">
            Block Agent
					</Modal.Title>
					<button
						onClick={() => handleCloseForBlockUserDialog()}
						type="button"
						className="close"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label className="font-loader">Blocked Reason</Form.Label>
							<Form.Control
								onChange={(e) => setBlockedReason(e.target.value)}
								type="text"
								class="form-control"
								placeholder=""
							></Form.Control>
						</Form.Group>{' '}
						<br />
						<Button
							type="submit"
							onClick={(e) => {
								e.preventDefault();
								blockCustomer({
									variables: {
										_id: id,
										blocked_reason: blockedReason,
									},
								});
							}}
						>
              Submit
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		);
	};

	const updateUserModal = () => {
		return (
			<Modal
				show={show}
				onHide={handleClose}
				animation={true}
				size={'lg'}
				style={{ marginTop: 30 }}
			>
				<Modal.Header>
					<Modal.Title id="example-custom-modal-styling-title">
            Update Customer
					</Modal.Title>
					<button
						onClick={() => handleClose()}
						type="button"
						className="close"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="form-group col-md-5">
								<Form.Group>
									<Form.Label>What is the department of the user?</Form.Label>
									<Form.Control
										as={'select'}
										type={'select'}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name={'region_id'}
									>
										<option value={null}>Select an option</option>
										{regions
											? regions.map((region, key) => {
												return (
													<option key={key} value={region.id}>
														{region.name}
													</option>
												);
											})
											: null}
									</Form.Control>
								</Form.Group>
							</div>
							{UpdateCustomerForm.content.map((field, key) => {
								return (
									<div key={key} className="form-group col-md-5">
										<Form.Group>
											<Form.Label>{field.label}</Form.Label>
											<Form.Control
												type={field.type}
												name={field.name}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											></Form.Control>
										</Form.Group>
									</div>
								);
							})}
						</div>
						<Button type="submit">Submit</Button>
					</Form>
				</Modal.Body>
			</Modal>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{updateUserModal()}
			{blockUserModal()}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '80%',
					justifyContent: 'space-between',
					padding: 15,
					marginBottom: 10,
				}}
			>
				<h1 style={{ margin: 10 }}>CUSTOMER DETAILS</h1>
				<div>
					<Dropdown style={{ position: 'relative', marginTop: 15 }}>
						<Dropdown.Toggle
							style={{ backgroundColor: '#0E3E86' }}
							id="dropdown-basic"
						>
              Perform Actions
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									blockUserDialog();
								}}
							>
                Block Customer
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									unblockCustomer({
										variables: {
											_id: id,
											blocked_reason: '',
										},
									});
								}}
							>
                Unblock Customer
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									updateUser();
								}}
							>
                Update Customer
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', padding: 15 }}>
				<Table
					bordered
					striped
					style={{
						width: '40%',
					}}
				>
					<tbody>
						<tr>
							<td>Customer Name</td>
							<td>
								{first_name} {last_name}
							</td>
						</tr>
						<tr>
							<td>Customer Email</td>
							<td>{email}</td>
						</tr>
						<tr>
							<td>Customer Phone Number</td>
							<td>{phone_number}</td>
						</tr>
						<tr>
							<td>Status</td>
							<td
								style={{
									background: status === 'pending' ? '#ffee58' : '#76ff03',
									textAlign: 'center',
									textTransform: 'capitalize',
								}}
							>
								{status}
							</td>
						</tr>
						<tr>
							<td>Digital Address</td>
							<td>{digital_address}</td>
						</tr>
						<tr>
							<td>Blocked Status</td>
							<td
								style={{
									background: blocked ? '#c62828' : null,
									textAlign: 'center',
									textTransform: 'capitalize',
								}}
							>
								{blocked ? (
									<span
										style={{
											color: blocked ? 'white' : null,
										}}
									>
                    Blocked
									</span>
								) : null}
							</td>
						</tr>
						<tr>
							<td>Blocked Reason</td>
							<td>{blocked_reason}</td>
						</tr>
						<tr>
							<td>Region</td>
							<td>{customerRegion}</td>
						</tr>
						<tr>
							<td>Town / City</td>
							<td>{town_city}</td>
						</tr>
						<tr>
							<td>Address</td>
							<td>{address}</td>
						</tr>
					</tbody>
				</Table>

				<div
					style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}
				>
					<div style={{ marginBottom: '5%', textAlign: 'center' }}>
						<h4>Profile Image</h4>
						<img
							style={{ height: 200 }}
							src={customerSelfie ? customerSelfie : imgPlaceholder}
							alt="/"
						/>
					</div>
					<div style={{ marginBottom: '5%', textAlign: 'center' }}>
						<h4>Ghana Card</h4>
						<img
							style={{ height: 400 }}
							src={customerGhanaCard ? customerGhanaCard : imgPlaceholder}
							alt="/"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
