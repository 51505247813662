import React from 'react';

const ChequeDataComponent = ({
  banks,
  state,
  handleChange,
  selectedItem,
  raiseAlert,
}) => {
  return (
    <div style={{ padding: 15 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <h4>Agent Name: {selectedItem?.agent.full_name}</h4>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Total amount: GHS {selectedItem?.total_commission_balance}</p>
        </div>
      </div>

      <div style={{ marginTop: 10 }}>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">
            Reference
            <input
              type="text"
              onChange={handleChange}
              value={state?.reference}
              name="reference"
              className="form-control font-loader"
            />{' '}
          </label>
        </div>
        <div className="form-group" style={{ marginTop: 10 }}>
          <label htmlFor="exampleFormControlInput1">
            Account Number
            <input
              type="text"
              value={state?.account_number}
              onChange={handleChange}
              name="account_number"
              className="form-control font-loader"
            />{' '}
          </label>
        </div>
        <div className="form-group" style={{ marginTop: 10 }}>
          <label htmlFor="exampleFormControlInput1">
            Bank
            <select
              onChange={handleChange}
              defaultValue={state?.bank_id}
              className="form-select"
              name="bank_id"
              aria-label="Default select example"
            >
              <option value="">Select a bank </option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.name}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div style={{ display: 'flex', float: 'right' }}>
        <button className="btn btn-danger mr-2">Cancel</button>
        <button className="btn btn-success" onClick={() => raiseAlert()}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ChequeDataComponent;

// export const ChequeDataComponent2 = ({ banks, state, handleChange, selectedItem, raiseAlert }) => {

//     return (
//         <div style={{ padding: 15 }}>

//             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
//                 <h4>{selectedItem?.fullname}</h4>
//                 <div style={{ display: "flex", flexDirection: "column" }} >
//                     <p>GHS {selectedItem?.amount}.00</p>

//                 </div>

//             </div>

//             <div style={{ marginTop: 10 }}>
//                 <div className="form-group">
//                     <label for="exampleFormControlInput1">Reference</label>
//                     <input
//                         type="text"
//                         onChange={handleChange}
//                         value={state?.reference}
//                         name="reference"
//                         className="form-control font-loader"
//                     />{" "}
//                 </div>
//                 <div className="form-group" style={{ marginTop: 10 }}>
//                     <label for="exampleFormControlInput1">Account Number</label>
//                     <input
//                         type="text"
//                         value={state?.account_number}
//                         onChange={handleChange}
//                         name="account_number"
//                         className="form-control font-loader"
//                     />{" "}
//                 </div>
//                 <div className="form-group" style={{ marginTop: 10 }}>
//                     <label for="exampleFormControlInput1">Bank </label>
//                     <select
//                         onChange={handleChange}
//                         defaultValue={state?.bank_id}
//                         className="form-select"
//                         name="bank_id"
//                         aria-label="Default select example"
//                     >
//                         <option value="">Select a bank </option>
//                         {banks.map((bank) => (
//                             <option value={bank.id}>{bank.name}</option>
//                         ))}

//                     </select>
//                 </div>

//             </div>

//             <div style={{ display: "flex", float: "right" }}>
//                 <button className="btn btn-danger mr-2">
//                     Cancel

//                 </button>
//                 <button className="btn btn-success" onClick={() => raiseAlert()}>
//                     Save

//                 </button>
//             </div>

//         </div>

//     );
// };
