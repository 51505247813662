import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Spinner from '../../components/Spinner';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import axios from 'axios';

const ExpandableTable = ({ rowData }) => {
  const [loadingStates, setLoadingStates] = useState(
    Array(rowData.length).fill(false),
  );

  let loading = false;
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const ResolveStake = async (reference, rowIndex) => {
    setLoadingStates((prevState) => {
      const newState = [...prevState];
      newState[rowIndex] = true;
      return newState;
    });

    try {
      const token = localStorage.getItem('accesstoken');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const res = await axiosInstance.get(
        `stake/checkout/${reference}/resolve`,
        {
          headers,
        },
      );

      if (res.data) {
        SuccessNotification('Stake payment resolved successfully');
      }
    } catch (error) {
      console.log(error.response);
      ErrorNotification(error.response.data.message);
    }

    setLoadingStates((prevState) => {
      const newState = [...prevState];
      newState[rowIndex] = false;
      return newState;
    });
  };

  // console.log(rowData)

  // const downloadTypes = [
  //   { id: 1, item: "xls" },
  //   { id: 2, item: "csv" },
  //   { id: 3, item: "xlsx" },
  //   { id: 3, item: "docx" },
  // ];

  let processRowData = [];

  rowData.forEach((user) => {
    let newUser = Object.assign({}, user, {
      phone_number: user.customer?.phone_number,
      full_name: user.customer?.full_name,
    });
    processRowData.push(newUser);
  });

  // console.log(processRowData)

  // /** Utility method to show staked numbers of a user */
  // const showLines = lines => {
  //   if (lines) {
  //     let res = '['

  //     for (let i = 0; i < lines?.length; i++) {
  //       res = res + lines[i] + ", "
  //     }

  //     res = res + ' ] '

  //     return res
  //   } else {
  //     return ''
  //   }
  // }

  // const nullValueHandler = val => {
  //   if (val) {
  //     return val
  //   } else {
  //     return 'No value'
  //   }
  // }

  // const {
  //   itemserror,
  //   loading: itemsLoading,
  //   data,
  // } = useQuery(GET_ALL_BANKS)

  // useEffect(() => {
  //   if (data) {
  //     setBanks(data.bank)
  //   }

  // }, [data])

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    selectableRows: 'none',
    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    // onDownload: (buildHead, buildBody, columns, data) => {
    //   buildHead = () => {
    //     let res = []

    //     for(let i=0; i<columns.length; i++){
    //       res.push(columns[i].label)
    //     }

    //     res.push('\n')

    //     return res
    //   }
    //   buildBody = () => {
    //     let res = []
    //     let row = []
    //     for (let i = 0; i < data.length; i++) {
    //       if (data[i].data[5]) {
    //         let row = [
    //           nullValueHandler( data[i].data[0]?.full_name),
    //           nullValueHandler(data[i].data[0]?.phone_number),
    //           nullValueHandler(data[i].data[0]?.email),
    //           data[i].data[3],
    //           showLines(data[i].data[4]),
    //           'Paid',
    //           data[i].data[6],
    //           data[i].data[7],
    //           data[i].data[8],
    //           data[i].data[9]

    //         ]
    //         res.push(row)
    //       } else {
    //         let row = [
    //           nullValueHandler(data[i].data[0]?.full_name),
    //           nullValueHandler(data[i].data[0]?.phone_number),
    //           nullValueHandler(data[i].data[0]?.email),
    //           data[i].data[3],
    //           showLines(data[i].data[4]),
    //           'Not Paid',
    //           data[i].data[6],
    //           data[i].data[7],
    //           data[i].data[8],
    //           data[i].data[9],
    //           '\n'
    //         ]
    //         res.push(row)
    //       }
    //     }
    //     console.log(columns)
    //     return res
    //   }
    //   return '\uFEFF' + buildHead() + buildBody()
    // },
    // onRowClick: handleRowClick,
    // customToolbarSelect: () => {},
    sortOrder: {
      name: 'last_name',
      direction: 'asc',
    },
  };

  const columns = [
    {
      name: 'full_name',
      label: 'Customer Name',
      options: {
        filter: true,
        sort: true,
        // customBodyRender: val => {
        //   return (
        //     <p style={{ position: 'relative', left: 5, top: 10 }}>
        //       {val?.full_name}
        //     </p>
        //   )
        // }
      },
    },
    {
      name: 'phone_number',
      label: 'Customer Phone Number',
      options: {
        filter: true,
        sort: true,
        // customBodyRender: val => {
        //   return (
        //     <p style={{ position: 'relative', left: 5, top: 10 }}>
        //       {val?.phone_number}
        //     </p>
        //   )
        // }
      },
    },
    // {
    //   name: 'customer',
    //   label: 'Customer Email',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: val => {
    //       return (
    //         <p style={{ position: 'relative', left: 5, top: 10 }}>
    //           {val?.email}
    //         </p>
    //       )
    //     }
    //   }
    // },

    {
      name: 'stake_type',
      label: 'Stake Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'stake_status',
      label: 'Stake Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'picks',
      label: 'Staked Numbers',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>
              {val?.toString()}
            </p>
          );
        },
      },
    },
    {
      name: 'is_paid',
      label: 'Paid Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val == false) {
            return (
              <p style={{ position: 'relative', left: 5, top: 10 }}>Not Paid</p>
            );
          } else {
            return (
              <p style={{ position: 'relative', left: 5, top: 10 }}>Paid</p>
            );
          }
        },
      },
    },
    {
      name: 'staked_amount',
      label: 'Staked Amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          let result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHS',
          }).format(val);
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>{result}</p>
          );
        },
      },
    },
    // {
    //   name: 'agent_commission_rate',
    //   label: 'Agent Commission Rate',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: val => {
    //       return (
    //         <p style={{ position: 'relative', left: 50, top: 10 }}>
    //           {val}%
    //         </p>
    //       )
    //     }
    //   }
    // },
    {
      name: 'payment_reference',
      label: 'Payment Reference',
      options: {
        filter: true,

        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 50, top: 10 }}>{val}</p>
          );
        },
      },
    },

    {
      name: 'agent_commission_amount',
      label: 'Agent Commission Amount',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          let result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHS',
          }).format(val);
          return <p style={{ position: 'relative', left: 50 }}>{result}</p>;
        },
      },
    },

    {
      name: 'winning_amount',
      label: 'Winning Amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          let result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHS',
          }).format(val);
          return <p style={{ position: 'relative', left: 50 }}>{result}</p>;
        },
      },
    },
    {
      name: 'is_paid',
      label: 'Resolve stake',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          if (!val) {
            return (
              <button
                className="btn btn-success"
                onClick={() => ResolveStake(tableMeta.rowData[7], rowIndex)}
                disabled={loadingStates[rowIndex]}
              >
                {loadingStates[rowIndex] ? 'Loading...' : 'Resolve Payment'}
              </button>
            );
          } else {
            return <p style={{ position: 'relative', left: 5, top: 10 }}></p>;
          }
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20, position: 'relative' }}>
      {/* <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={"Pay with cheque"}
        body={

          <ChequeDataComponent banks={banks} state={state} handleChange={handleChange} selectedItem={selectedItem} raiseAlert={raiseAlert} />


        }
        handleClose={handleclose2}
      /> */}

      {loading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={processRowData ? processRowData : []}
          title="Stakes"
          columns={columns}
          options={options}
          // onRowClick={handleRowClick}
        />
      )}
      {/* <div style={{ display: "flex", flexDirection: "row",float:"right" }}>

        <div style={{ position: "relative", top: 10, marginRight: 20 }}>
          <button disabled={open || paymentType === "cheque"} className="btn btn-success" onClick={() => raiseAlert()}>
            Pay with Momo

          </button>
        </div>

        <div style={{ position: "relative", top: 10 }}>
          <button disabled={paymentType==="momo"} className="btn btn-info" onClick={() => { setShowMDetailModal(true);   setInitiatePayment(true) }}>
            Pay with Cheque

          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ExpandableTable;
