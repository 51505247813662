import React from 'react';
import { formatDateFull } from '../../../utils';

//get message detail with api detail
function MessageDetail(props) {
	const { title, processed_at, message, recipients } = props.detail;
	const getnames = (arraydata) => {
		for (var i = 0; i < arraydata.length; i++) {
			return arraydata[i].customer.full_name;
		}
	};
	return (
		<div>
			<div style={{ display: 'flex', flexDirection: 'row', lineHeight: 1 }}>
				<p style={{ color: 'black', fontSize: 25, fontWeight: 'bold' }}>
					{title}
				</p>
			</div>

			<div style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
				<p style={{ color: 'black', position: 'relative', top: -10 }}>
					{message}
				</p>
			</div>

			<div style={{ marginTop: 2, display: 'flex', flexDirection: 'row' }}>
				<p style={{ fontWeight: 'bold', paddingRight: 10 }}>To:</p>
				<p style={{ color: 'black' }}>{getnames(recipients)}</p>
			</div>
			<hr />
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				{/* <p style={{ fontSize: 12 }}>Sent by: {sender.name}</p> */}
				<p style={{ fontSize: 12 }}>
          Date Sent: {formatDateFull(processed_at)}
				</p>
			</div>
		</div>
	);
}

export default MessageDetail;
