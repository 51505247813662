import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import { Form } from 'react-bootstrap';
import Spinner from '../../../components/Spinner';
import './index.css';
import {
  GET_MOST_STAKED_NUMBERS,
  FILTER_MOST_STAKED_NUMBERS,
} from '../../../Graphql/Queries';
import { ErrorNotification } from '../../../components/Notification';

const Index = ({ game }) => {
  const [tableData, setTableData] = useState([]);
  // const [query, setQuery] = useState(null);

  // console.log(query)

  const { loading } = useQuery(GET_MOST_STAKED_NUMBERS, {
    variables: {
      game_id: game,
    },
    onCompleted: (data) => {
      setTableData(data.view_most_freq_stakes);
    },
  });

  const [
    getGameReportWithinRange,
    // { data: gameDataWithinRange },
  ] = useLazyQuery(FILTER_MOST_STAKED_NUMBERS, {
    onCompleted: (data) => {
      setTableData(data.view_most_freq_stakes);
    },
    onError: () => {
      ErrorNotification('Failed to load most frequent stakes query');
    },
  });

  // useEffect(() => {
  //   if (data) {
  //     // console.log(data)
  //     setTableData(data.view_most_freq_stakes);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (gameDataWithinRange) {
  //     setTableData(gameDataWithinRange.view_most_freq_stakes);

  //     console.log(gameDataWithinRange.view_most_freq_stakes);
  //   }
  // }, [gameDataWithinRange, query]);

  const reportTypes = [
    { id: 1, report_item: 'Mega-1' },
    { id: 2, report_item: 'Mega-2' },
    { id: 3, report_item: 'Mega-3' },
    { id: 4, report_item: 'Mega-4' },
    { id: 5, report_item: 'Mega-5' },
    { id: 6, report_item: 'Mega-6' },
    { id: 7, report_item: 'Perm-2' },
    { id: 8, report_item: 'Perm-3' },
    { id: 9, report_item: 'Perm-4' },
    { id: 10, report_item: 'Perm-5' },
    { id: 11, report_item: 'Perm-6' },
    { id: 12, report_item: 'Banker' },
  ];

  // const downloadTypes = [
  //   { id: 1, item: "xls" },
  //   { id: 2, item: "csv" },
  //   { id: 3, item: "xlsx" },
  //   { id: 3, item: "docx" },
  // ];

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    // onRowClick: handleRowClick,
    // onRowSelectionChange: (e, i, v) => setSelectedRows(v),
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const columns = [
    {
      name: 'staked_line',
      label: 'Staked Lines',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 25 }}>{val.toString()}</p>
          );
        },
      },
    },

    {
      name: 'stake_type',
      label: 'Stake Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 25 }}>{val}</p>;
        },
      },
    },

    {
      name: 'freq',
      label: 'Frequency',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 35 }}>{val}</p>;
        },
      },
    },

    {
      name: 'total_stake_amount',
      label: 'Total amount staked (GHS)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 85 }}>{val}</p>;
        },
      },
    },

    {
      name: 'possible_payout',
      label: 'Total possible payout ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          let result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHS',
          }).format(val);
          return <p style={{ position: 'relative', left: 85 }}>{result}</p>;
        },
      },
    },
  ];

  // const handleRowClick = (rowData) => {};

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
          <p
            style={{
              width: '10%',
              marginTop: 15,
              fontWeight: 600,
              fontSize: 15,
            }}
          >
            Filter by:
          </p>
          <div style={{ width: '30%', marginBottom: 10 }}>
            <Form.Control
              required
              as="select"
              type="select"
              onChange={(e) => {
                // setQuery(e.target.value);
                getGameReportWithinRange({
                  variables: {
                    game_id: game,
                    stake_type: e.target.value,
                  },
                });
              }}
              // onInput={(e) => setProduct(e.target.value)}
              style={{
                backgroundColor: 'white',
                color: '#37517e',
                border: 'solid',
                width: '100%',
                height: '45px',
              }}
            >
              <option value="">Select a filter type</option>

              {reportTypes?.map((m, key) => (
                <option key={key} value={m.report_item}>
                  {m.report_item}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={tableData}
          title={'Most frequent stake'}
          columns={columns}
          options={options}
          // onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default Index;
