import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_USER_BETWEEN_RANGE,
  GET_GAMES,
  GET_GAME_REPORT,
  GET_SIGNUP_USERS,
  GET_STAKES_REPORT,
  GETALLWINNINGS,
  USER_STAKES_REPORT,
} from "../../Graphql/Queries";
import { Form, Tabs, Tab, Button, Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import "./styles.css";
import Table from "react-bootstrap/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandableTable from "./ExpandableTable";
import { formatCurrency, formatDateFull } from "../../utils";
import axios from "axios";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../components/Notification";
import ExpandableTable2 from "./ExpandableTable2";
import "./styles.css";
import Select from "react-select";

const ReportOverview = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [reportType, setReportType] = useState("User Stakes Report");
  const [games, setGames] = useState(null);
  const [gameType, setGameType] = useState("");
  const [gameStartPeriod, setGameStartPeriod] = useState(null);
  const [gameEndPeriod, setGameEndPeriod] = useState(null);
  const [gameReport, setGameReport] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [show, setShow] = useState(false);
  const [resolvingpayment, setResolvingPayment] = useState([]);
  const [signupUsers, setSignupUsers] = useState([]);
  const [stakesCount, setStakesCount] = useState([]);
  const [allstakes, setAllStakes] = useState([]);
  const [userStakeReport, setUserStakeReport] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [tableData, setTableData] = useState();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const WinnersListoptions = {
    filterType: "checkbox",
    filter: false,
    print: false,
    // onRowClick: handleRowClick,
    // onRowSelectionChange: (e, i, v) => setSelectedRows(v),
    customToolbarSelect: () => {},
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  /** Helper Method to convert date object to timestamp for GraphQL query */
  const convertToTimeStamp = (date) => {
    let newDate = new Date(date);
    return newDate.toISOString();
  };

  const formik = useFormik({
    initialValues: {
      dateAfter: null,
      dateBefore: null,
      _in: [],
    },

    onSubmit: () => {
      if (reportType === "User Report") {
        getUsersWithinRange();
      } else if (reportType === "Staked Payment Report") {
        // getPaymentsWithinRange()
      } else if (reportType === "Game Report") {
        // getGameReportWithinRange()
      } else if (reportType === "Signup Report") {
        getSignuReportWithinRange();
      } else {
        getUserStakeReport();
      }
    },
  });

  /** Definition of GraphQL queries */
  const [getUsersWithinRange] = useLazyQuery(GET_USER_BETWEEN_RANGE, {
    variables: {
      _adminAfter: convertToTimeStamp(formik.values.dateAfter),
      _adminBefore: convertToTimeStamp(formik.values.dateBefore),
      _customerAfter: convertToTimeStamp(formik.values.dateAfter),
      _customerBefore: convertToTimeStamp(formik.values.dateBefore),
    },

    onCompleted: (data) => {
      let users = [];

      for (let i = 0; i < data.admin.length; i++) {
        users.push(data.admin[i]);
      }
      for (let i = 0; i < data.customer.length; i++) {
        users.push(data.customer[i]);
      }
      // console.log(users)
      setAllUsers(users);
    },

    onError: () => {
      ErrorNotification("Failed to load users within specified range");
    },
  });

  useQuery(GET_GAMES, {
    onCompleted: (data) => {
      let lotteryGames = [];
      data.games.map((game) => {
        lotteryGames.push({
          id: game.id,
          label: game.name,
          value: game.id,
        });
      });
      setGames(lotteryGames);
    },

    onError: () => {
      ErrorNotification("Failed to get Games");
    },
  });

  useQuery(USER_STAKES_REPORT, {
    onCompleted: (data) => {
      setUserStakeReport(data.view_staked_summary);
    },
    onError: () => {
      ErrorNotification("Failed to get user stakes report");
    },
  });

  /** Lazy Query definition for game report */
  const [getGameReportWithinRange] = useLazyQuery(GET_GAME_REPORT, {
    variables: {
      _startDate: gameStartPeriod,
      _endDate: gameEndPeriod,
      _in: formik.values._in,
    },
    onCompleted: (data) => {
      setGameReport(data.game);

      // console.log(data);
    },

    onError: () =>
      ErrorNotification("Failed to load game report for specified range"),
  });

  const [getWinnersReportWithinRange] = useLazyQuery(GETALLWINNINGS, {
    onCompleted: (data) => {
      setTableData(data.view_all_winnings);
    },

    onError: () => ErrorNotification("Failed to get winners report"),
  });

  const [getSignuReportWithinRange] = useLazyQuery(GET_SIGNUP_USERS, {
    variables: {
      _startDate: convertToTimeStamp(formik.values.dateAfter),
      _endDate: convertToTimeStamp(formik.values.dateBefore),
    },

    onCompleted: (data) => {
      setSignupUsers(data.customer_aggregate.nodes);
    },

    onError: () => ErrorNotification("Failed to user data"),
  });

  const [getStakesReportWithinRange] = useLazyQuery(GET_STAKES_REPORT, {
    variables: {
      _startDate: convertToTimeStamp(gameStartPeriod),
      _endDate: convertToTimeStamp(gameEndPeriod),
      _in: formik.values._in,
    },

    onCompleted: (data) => {
      setStakesCount([]);
      setStakesCount((prevArray) => [...prevArray, data.stake_aggregate]);
      setAllStakes(data.stakes);
    },

    onError: () => ErrorNotification("Failed to load stake report"),
  });

  const WinnersListcolumns = [
    {
      name: "full_name",
      label: "Full Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: "game_name",
      label: "Game Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: "event_code",
      label: "Event Code",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div style={{ position: "relative", left: "50px" }}>{val}</div>
          );
        },
      },
    },

    {
      name: "stake_type",
      label: "Stake Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: "winning_amount",
      label: "Winning Amount(GHS)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div style={{ position: "relative", left: "50px" }}>{val}</div>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date won",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return formatDateFull(val);
        },
      },
    },
  ];

  const ResolveStake = async (reference, index) => {
    try {
      setResolvingPayment((prevStates) => [
        ...prevStates.slice(0, index),
        true,
        ...prevStates.slice(index + 1),
      ]);
      const token = localStorage.getItem("accesstoken");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      axiosInstance.get(`stake/checkout/${reference}/resolve`, {
        headers,
      });
      setResolvingPayment((prevStates) => [
        ...prevStates.slice(0, index),
        false,
        ...prevStates.slice(index + 1),
      ]);
      SuccessNotification("Stake payment resolved successfully");
    } catch (error) {
      setResolvingPayment((prevStates) => [
        ...prevStates.slice(0, index),
        false,
        ...prevStates.slice(index + 1),
      ]);
      console.log(error.response);
      ErrorNotification(error.response.data.message);
    }
  };

  /** Showing the stake details modal for the game report */
  const showGameDetails = (rowIndex, dataIndex) => {
    setShow(true);
    setCurrentEvent(gameReport[dataIndex.dataIndex]);
  };

  /** Hide the stake details modal */
  const handleClose = () => {
    setShow(false);
  };

  /** Utility method to show staked numbers of a user */
  const showLines = (lines) => {
    if (lines) {
      let res = "[";

      for (let i = 0; i < lines?.length; i++) {
        res = res + "[ " + lines[i].toString() + " ], ";
      }

      res = res + " ] ";

      return res;
    } else {
      return "";
    }
  };

  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  const handleGameSelect = (e) => {
    setGameType(e.target.value);
  };

  const userTableColumns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "__typename",
      label: "User Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
  ];

  const signUpTableColumns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: "relative", left: 25 }}>{val && val.name}</p>
          );
        },
      },
    },
  ];

  const gameReportTableColumns = [
    {
      name: "product",
      label: "Product Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val.name;
        },
      },
    },
    {
      name: "schedule_start",
      label: "Game Start Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "schedule_end",
      label: "Game End Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "event_code",
      label: "Game Event Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "results",
      label: "Winning Numbers",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val == null) {
            return "";
          } else {
            let result = "[";

            if (val.length > 0) {
              result = result + val[0];

              for (let i = 1; i < val.length; i++) {
                result = result + ", " + val[i];
              }
            }

            result = result + "]";
            return result;
          }
        },
      },
    },
  ];

  useEffect(() => {
    if (reportType === "Game Report" && formik.values._in.length > 0) {
      getGameReportWithinRange();
    } else if (reportType === "Stake Report" && formik.values._in.length > 0) {
      getStakesReportWithinRange();
    }
  }, [formik.values._in]);

  const processInput = () => {
    let arr = [];

    selectedOption.map((option) => {
      arr.push(option.value);
    });

    formik.setFieldValue("_in", arr);
  };

  const userTableOptions = {
    filterType: "checkbox",
    filter: false,
    print: false,
    // selectableRows: false,
    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    // onRowClick: handleRowClick,
    customToolbarSelect: () => {},
  };

  const gameReportOptions = {
    filterType: "dropdown",
    responsive: "standard",
    filter: false,
    print: false,
    selectableRows: "none",
    expandableRows: true,
    expandableRowsHeader: false,
    isRowExpandable: () => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      let stakes = gameReport[rowMeta.rowIndex].stakes;

      return (
        <TableRow>
          <TableCell colSpan={7}>
            <ExpandableTable
              rowData={stakes}
              ResolveStake={ResolveStake}
              resolvingpayment={resolvingpayment}
            />
          </TableCell>
        </TableRow>
      );
    },
    onRowClick: showGameDetails,
    customToolbarSelect: () => {},
  };

  const stakesTableColumns = [
    {
      name: "aggregate",
      label: "Total Stakes",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 25 }}>{val?.count}</p>;
        },
      },
    },
    {
      name: "aggregate",
      label: "Total amount staked",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: "relative", left: 25 }}>
              GHS {val?.sum.staked_amount}
            </p>
          );
        },
      },
    },
  ];

  const stakeReportOptions = {
    filterType: "dropdown",
    responsive: "standard",
    filter: false,
    print: false,
    selectableRows: "none",
    expandableRows: true,
    expandableRowsHeader: false,
    isRowExpandable: () => {
      return true;
    },
    renderExpandableRow: () => {
      // let stakes = gameReport[rowMeta.rowIndex].stakes

      return (
        <TableRow>
          <TableCell colSpan={5}>
            <ExpandableTable2 rowData={allstakes} />
          </TableCell>
        </TableRow>
      );
    },
    onRowClick: showGameDetails,
    customToolbarSelect: () => {},
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Tabs
        defaultActiveKey="table"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="table" title="Table View" style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <p
              style={{
                marginTop: 15,
                marginRight: 10,
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              Filter by:
            </p>
            <div>
              <Form.Control
                required
                as="select"
                type="select"
                onChange={handleChange}
                // onInput={(e) => setProduct(e.target.value)}
                style={{
                  backgroundColor: "white",
                  color: "#37517e",
                  border: "solid",
                  height: "45px",
                }}
              >
                <option value={"User Report"}>User Report</option>
                <option value={"Game Report"}>Game Report</option>
                <option value={"Signup Report"}>Signup Report</option>
                <option value={"Stake Report"}>Stakes Report</option>
                <option value={"Winners Report"}>Winners Report</option>
                <option value={"User Stakes Report"}>User Stakes Report</option>
                {/* <option value={'Staked Payment Report'}>
                  Staked Payment Report
                </option>
                <option value={'Payout Report'}>Payout Report</option> */}
              </Form.Control>
            </div>

            {reportType === "User Report" ||
            reportType === "Staked Payment Report" ||
            reportType === "Signup Report" ? (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50%",
                  alignItems: "center",
                  marginLeft: 10,
                  justifyContent: "space-between",
                }}
                onSubmit={formik.handleSubmit}
              >
                <div style={{ display: "flex", width: "100%", paddingTop: 15 }}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">From</span>
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="dateAfter"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingTop: 15,
                    marginLeft: 25,
                  }}
                >
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">To</span>
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="dateBefore"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginLeft: 25 }}>
                  <Button className="btn btn-primary" type="submit">
                    Get Report
                  </Button>
                </div>
              </Form>
            ) : reportType === "User Stakes Report" ? null : (
              <Form style={{ display: "flex", flexDirection: "row" }}>
                <Form.Group
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 15,
                  }}
                >
                  {reportType === "Game Report" ||
                  reportType === "Stake Report" ? (
                    <>
                      <Select
                        id="_in"
                        onChange={setSelectedOption}
                        name="_in"
                        isMulti
                        options={games}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select game(s)"
                      />
                    </>
                  ) : // <>
                  //   <Form.Control
                  //     as={'select'}
                  //     type={'select'}
                  //     // onChange={handleCustomerSelect}
                  //   >
                  //     <option value={null}>Select usertype</option>
                  //     {customers
                  //       ? customers.map((game, key) => {
                  //           return (
                  //             <option key={key} value={game.id}>
                  //               {game.name}
                  //             </option>
                  //           )
                  //         })
                  //       : null}
                  //   </Form.Control>
                  // </>
                  null}
                </Form.Group>
                <Form.Group style={{ margin: 10 }}>
                  <div
                    style={{ display: "flex", width: "100%", paddingTop: 15 }}
                  >
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">From</span>
                      </div>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                          e.preventDefault();
                          let myDate = new Date(e.target.value)
                            .toISOString()
                            .substring(0, 10);
                          setGameStartPeriod(myDate);
                        }}
                        name="dateAfter"
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group style={{ margin: 10 }}>
                  <div
                    style={{ display: "flex", width: "100%", paddingTop: 15 }}
                  >
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">To</span>
                      </div>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                          e.preventDefault();
                          let myDate = new Date(e.target.value)
                            .toISOString()
                            .substring(0, 10);
                          setGameEndPeriod(myDate);
                        }}
                        name="dateAfter"
                      />
                    </div>
                  </div>
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {reportType === "Game Report" ? (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        processInput();
                      }}
                      className="btn btn-primary"
                    >
                      Get Report
                    </Button>
                  ) : reportType === "Winners Report" ? (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        getWinnersReportWithinRange();
                      }}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Get Report
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        processInput();
                      }}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Get Report
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </div>
          <div style={{ padding: 20 }}>
            {reportType && reportType == "Signup Report" && (
              <p>Total signups: {signupUsers.length}</p>
            )}

            {reportType === "User Report" ? (
              <MUIDataTable
                title={"All Users"}
                data={allUsers ? allUsers : []}
                columns={userTableColumns}
                options={userTableOptions}
              />
            ) : reportType === "Game Report" ? (
              <MUIDataTable
                title={"Game Report"}
                data={gameReport ? gameReport : []}
                columns={gameReportTableColumns}
                options={gameReportOptions}
              />
            ) : reportType === "Signup Report" ? (
              <MUIDataTable
                title={"Signup Reports"}
                data={signupUsers ? signupUsers : []}
                columns={signUpTableColumns}
                // options={gameReportOptions}
              />
            ) : reportType === "Stake Report" ? (
              <MUIDataTable
                title={"Stake Report"}
                data={stakesCount ? stakesCount : []}
                columns={stakesTableColumns}
                options={stakeReportOptions}
              />
            ) : reportType === "Winners Report" ? (
              <MUIDataTable
                title={"All Winners List Report"}
                data={tableData ? tableData : []}
                columns={WinnersListcolumns}
                options={WinnersListoptions}
              />
            ) : reportType === "User Stakes Report" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>User Stakes Dashboard</h1>

                <div className="card-container">
                  {userStakeReport ? (
                    <>
                      {userStakeReport.map((item, key) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: 15,
                            width: 300,
                            maxWidth: "1200px",
                            height: 200,
                            padding: 15,
                            // borderColor: borderColor,
                          }}
                          className="card"
                        >
                          <h5
                            style={{
                              textAlign: "center",
                              marginBottom: 10,
                            }}
                          >
                            {item.staked_by?.toUpperCase()} STAKE SUMMARY
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 15,
                              justifyContent: "start",
                              alignItems: "start",
                            }}
                          >
                            <span>
                              Paid Stakes:{" "}
                              {formatCurrency(item.total_paid_amount)}
                            </span>
                            <span>
                              Attempted Stakes:{" "}
                              {formatCurrency(item.total_staked_amount)}
                            </span>
                            <span>Count of Records: {item.total_records}</span>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: 'solid',
                        }}
                      >
                        <CircularProgress
                          style={{
                            position: "relative",
                            marginTop: "25%",
                            left: "55%",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </Tab>
        <Tab eventKey="graphics" title="Graphical View"></Tab>
      </Tabs>

      <StakeModal
        event={currentEvent}
        handleClose={handleClose}
        show={show}
        // gameType={gameType}
        showLines={showLines}
      />
    </div>
  );
};

const StakeModal = ({ event, handleClose, show, showLines }) => {
  return (
    <Modal show={show} onHide={handleClose} animation={true} size={"lg"}>
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title">
          Stake Info Event Code: {event?.event_code}
        </Modal.Title>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Table bordered striped>
          <tbody>
            <tr>
              <td>Agent Commission Rate</td>
              <td>{event?.stakes[0]?.agent_commission_rate}</td>
            </tr>
            <tr>
              <td>Agent Commission Amount</td>
              <td>{event?.stakes[0]?.agent_commission_amount}</td>
            </tr>
            <tr>
              <td>Amount Per stakes</td>
              <td>{event?.stakes[0]?.amount_per_stakes}</td>
            </tr>
            <tr>
              <td>Number of stakess</td>
              <td>{event?.stakes[0]?.num_stakess}</td>
            </tr>
            <tr>
              <td>Paid Amount</td>
              <td>{event?.stakes[0]?.paid_amount}</td>
            </tr>
            <tr>
              <td>Is Paid</td>
              <td>{event?.stakes[0]?.is_paid.toString()}</td>
            </tr>
            <tr>
              <td>Is Processed</td>
              <td>{event?.stakes[0]?.is_processed}</td>
            </tr>
            <tr>
              <td>Losing Lines</td>
              <td>{showLines(event?.stakes[0]?.losing_lines)}</td>
            </tr>
            <tr>
              <td>Losing Picks</td>
              <td>
                {event?.stakes[0]?.losing_picks
                  ? event?.stakes[0]?.losing_picks
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Is Claimed</td>
              <td>{event?.stakes[0]?.is_claimed.toString()}</td>
            </tr>
            <tr>
              <td>Picked Lines</td>
              <td>{showLines(event?.stakes[0]?.picked_lines)}</td>
            </tr>
            <tr>
              <td>Picks</td>
              <td>{event?.stakes[0]?.picks ? event?.stakes[0]?.picks : ""}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ReportOverview;
