import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import verified from "../../assets/verified.svg";
import revoked from "../../assets/google-unverified.svg";

const AdminCard = ({
  admin,
  email,
  firstName,
  lastName,
  phoneNumber,
  status,
  refetch,
}) => {
  const navigate = useNavigate();
  return (
    <Link
      // to={{
      //   pathname: '/admin/admins-view',
      //   state: admin,
      //   data: {
      //     refetch: refetch,
      //   },
      // }}
      onClick={(e) => {
        e.preventDefault();
        navigate("/admin/admins-view", { state: admin });
      }}
      style={{ margin: 15, textDecoration: "none" }}
    >
      <Card
        style={{
          borderRadius: 15,
          width: 350,
          height: 270,
          padding: -15,
          background: "#78909c",
        }}
      >
        <Card.Header>
          {status === "active" ? (
            <Card.Img
              variant="bottom"
              src={verified}
              style={{
                height: 50,
                width: 50,
                padding: 10,
              }}
            />
          ) : (
            <Card.Img
              variant="bottom"
              src={revoked}
              style={{
                height: 50,
                width: 50,
                padding: 10,
              }}
            />
          )}
        </Card.Header>
        <Card.Body>
          <Card.Title
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "white",
            }}
          >
            {firstName} {lastName}
            {/* #ffee58 */}
          </Card.Title>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ color: "white" }}>Email: {email}</p>
            <p style={{ color: "white" }}>Phone: {phoneNumber}</p>
            {status === "active" ? (
              <p
                style={{
                  background: "#76ff03",
                  borderRadius: 5,
                  width: 110,
                  textAlign: "center",
                  padding: 5,
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                {status}
              </p>
            ) : (
              <p
                style={{
                  background: "#D32F2F",
                  borderRadius: 5,
                  width: "50%",
                  textAlign: "center",
                  padding: 5,
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                {status}
              </p>
            )}
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default AdminCard;
