import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_AGENT_COMMISSION_PRICING } from '../../Graphql/Queries';
import {
  ADD_COMMISSION_RATE,
  DELETE_AGENT_COMMISSION_PRICING,
} from '../../Graphql/Mutations';
import { Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import Swal from 'sweetalert2';

const PricingView = () => {
  const [commissionRates, setcommissionRates] = useState([]);
  const [computedAs, setComputedAs] = useState('');

  const { refetch } = useQuery(GET_ALL_AGENT_COMMISSION_PRICING, {
    onCompleted: (data) => {
      setcommissionRates(data.agent_commission_pricing);
    },

    onError: () => {
      ErrorNotification('Failed to get Agent commission pricing');
    },
  });

  const formik = useFormik({
    initialValues: {
      commissionRate: 0,
      minimiumStakeAmount: 0,
      maximumStakeAmount: 0,
    },

    onSubmit: () => {
      addCommissionRate();
    },
  });

  const handleChange = (e) => {
    setComputedAs(e.target.value);
  };

  const call = () => {
    SuccessNotification('Successfully added commission rate ');
    refetch();
  };

  const [addCommissionRate] = useMutation(ADD_COMMISSION_RATE, {
    variables: {
      max_stake_amount: Number(formik.values.maximumStakeAmount),
      min_stake_amount: Number(formik.values.minimiumStakeAmount),
      rate: Number(formik.values.commissionRate),
      compute_as: computedAs,
    },
    onCompleted: () => call(),
    onError: () => ErrorNotification('Failed to add commission rate'),
  });

  const [deletePricing] = useMutation(DELETE_AGENT_COMMISSION_PRICING, {
    onCompleted: () => {
      SuccessNotification('Successfully deleted Pricing');
      refetch();
    },
    onError: () => ErrorNotification('Failed to delete commission rate'),
  });

  const deleteRow = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this Commission Pricing?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,decline',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePricing({
          variables: {
            _id: id,
          },
        });
      }
    });
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        <div style={{ margin: 15, padding: 15 }}>
          <h2>Commission Rates</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Commission Rate</th>
                <th>Minimum Stake Amount</th>
                <th>Maximum Stake Amount</th>
                <th>Computed As</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {commissionRates
                ? commissionRates.map((rate, key) => {
                  return (
                    <tr key={key}>
                      <td> {rate.rate}</td>
                      <td> {rate.min_stake_amount}</td>
                      <td> {rate.max_stake_amount}</td>
                      <td> {rate.compute_as}</td>
                      <td>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteRow(rate.id);
                          }}
                          variant="danger"
                        >
                            Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
        </div>
        <div style={{ margin: 15, padding: 10 }}>
          <h2>Add New Commission Rate</h2>
          <div>
            <Form
              onSubmit={formik.handleSubmit}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>Commission Rate</Form.Label>
                <Form.Control
                  onChange={formik.handleChange}
                  name="commissionRate"
                  type="number"
                  min="0"
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>Minimum Stake Amount</Form.Label>
                <Form.Control
                  onChange={formik.handleChange}
                  type="number"
                  min="0"
                  name="minimiumStakeAmount"
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>Maximum Stake Amount</Form.Label>
                <Form.Control
                  onChange={formik.handleChange}
                  name="maximumStakeAmount"
                  type="number"
                  min="0"
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>Computed As</Form.Label>
                <Form.Control onChange={handleChange} as="select" type="select">
                  <option value={null}>Select an option</option>
                  <option value={'percentage'}>Percentage</option>
                  <option value={'fixed'}>Fixed</option>
                </Form.Control>
              </Form.Group>
              <div>
                <Button
                  style={{ position: 'relative', marginTop: 41 }}
                  className="btn btn-primary"
                  type="submit"
                >
                  Add commmission rate
                </Button>

                <input type="submit" id="btn" disabled />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingView;
