export const AdminDetails = {
	name: 'Admin',
	content: [
		{
			label: 'Admin First Name',
			type: 'text',
			name: 'first_name',
			placeholder: '',
		},
		{
			label: 'Admin Middle Name',
			type: 'text',
			name: 'middle_name',
			placeholder: '',
		},
		{
			label: 'Admin Last Name',
			type: 'text',
			name: 'surname',
			placeholder: '',
		},
		{
			label: 'Admin Phone Number',
			type: 'number',
			name: 'phone_number',
			placeholder: 'Prefix with 233',
		},
		{
			label: 'Admin Role',
			type: 'select',
			name: 'role_id',
		},
		{
			label: 'Admin Email',
			type: 'email',
			name: 'email',
			placeholder: '',
		},
	],
};

export const CustomerDetails = {
	name: 'Customer',
	content: [
		{
			label: 'Customer First Name',
			type: 'text',
			name: 'first_name',
			placeholder: '',
		},
		{
			label: 'Customer Middle Name',
			type: 'text',
			name: 'middle_name',
			placeholder: '',
		},
		{
			label: 'Customer Last Name',
			type: 'text',
			name: 'surname',
			placeholder: '',
		},
		{
			label: 'Customer Mobile Phone Number',
			type: 'number',
			placeholder: 'Prefix with 233',
			name: 'phone_number',
		},
		{
			label: 'Customer Email',
			type: 'email',
			placeholder: '',
			name: 'email',
		},
		{
			label: 'Customer Address',
			type: 'text',
			placeholder: '',
			name: 'address',
		},
		{
			label: 'National ID number',
			type: 'text',
			placeholder: '',
			name: 'national_id',
		},
		{
			label: 'Customer Digital Address',
			type: 'text',
			placeholder: '',
			name: 'digital_address',
		},
		{
			label: 'Customer Town / City',
			type: 'text',
			placeholder: '',
			name: 'town_city',
		},
		{
			label: 'Customer Region',
			type: 'select',
			name: 'region_id',
		},
		{
			label: 'Mobile Money Network',
			type: 'select',
			placeholder: '',
			name: 'momo_network',
			option: [
				{
					name: 'mtn',
					value: 'mtn-gh',
				},
				{
					name: 'airteltigo',
					value: 'tigo-gh',
				},
				{
					name: 'vodafone',
					value: 'vodafone-gh',
				},
			],
		},
		{
			label: 'Does the user give consent?',
			type: 'check',
			name: 'consent_given',
		},
	],
};

export const AgentDetails = {
	name: 'Agent',
	content: [
		{
			label: 'Agent First Name',
			type: 'text',
			placeholder: '',
			name: 'first_name',
		},
		{
			label: 'Agent Middle Name',
			type: 'text',
			placeholder: '',
			name: 'middle_name',
		},
		{
			label: 'Agent Last Name',
			placeholder: '',
			type: 'text',
			name: 'surname',
		},
		{
			label: 'Agent Mobile Money Phone Number',
			placeholder: 'Prefix with 233',
			type: 'number',
			name: 'phone_number',
		},
		{
			label: 'Agent Email',
			placeholder: '',
			type: 'email',
			name: 'email',
		},
		{
			label: 'Agent Address',
			type: 'text',
			placeholder: '',
			name: 'address',
		},
		{
			label: 'Agent Digital Address',
			type: 'text',
			placeholder: '',
			name: 'digital_address',
		},
		{
			label: 'National ID number',
			type: 'text',
			placeholder: '',
			name: 'national_id',
		},
		{
			label: 'Agent Town / City',
			type: 'text',
			placeholder: '',
			name: 'town_city',
		},
		{
			label: 'Agent Region',
			type: 'select',
			name: 'region_id',
		},
		{
			label: 'Mobile Money Network',
			placeholder: '',
			type: 'select',
			name: 'momo_network',
			option: [
				{
					name: 'mtn',
					value: 'mtn-gh',
				},
				{
					name: 'airteltigo',
					value: 'tigo-gh',
				},
				{
					name: 'vodafone',
					value: 'vodafone-gh',
				},
			],
		},
		{
			label: 'Does the user give consent?',
			type: 'check',
			name: 'consent_given',
		},
	],
};

export const UpdateCustomerForm = {
	name: 'UpdateCustomer',
	content: [
		{
			label: 'Address',
			type: 'text',
			placeholder: '',
			name: 'address',
		},
		{
			label: 'Digital Address',
			type: 'text',
			placeholder: '',
			name: 'digital_address',
		},
		{
			label: 'Ghana Card',
			type: 'text',
			placeholder: '',
			name: 'ghana_card',
		},
		{
			label: 'Phone Number',
			type: 'number',
			placeholder: 'Prefix with 233',
			name: 'phone_number',
		},
		{
			label: 'Town / City',
			type: 'text',
			placeholder: '',
			name: 'town_city',
		},
	],
};
