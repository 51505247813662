import { BsTrashFill } from 'react-icons/bs';
import { BsPencil } from 'react-icons/bs';
import React from 'react';

function TableRows({
  rowsData,
  deleteTableRows,
  handleChange,
  onMakeInput,
  updateState,
}) {
  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: ['Poppins']
  //     }
  //   })
  // }, [])

  return rowsData?.map((data, index) => {
    const { max, min, name, odds, num_comb, multiplier } = data;

    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={name}
            onChange={(evnt) => handleChange(index, evnt)}
            name="name"
            className="form-control font-loader"
          />
        </td>
        <td>
          <input
            type="number"
            value={odds}
            onChange={(evnt) => handleChange(index, evnt)}
            name="odds"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <input
            type="number"
            value={max}
            onInput={(e) => onMakeInput(index, e)}
            onChange={(evnt) => handleChange(index, evnt)}
            name="max"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <input
            type="number"
            value={min}
            onInput={(e) => onMakeInput(index, e)}
            onChange={(evnt) => handleChange(index, evnt)}
            name="min"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <input
            type="number"
            value={multiplier}
            onInput={(e) => onMakeInput(index, e)}
            onChange={(evnt) => handleChange(index, evnt)}
            name="multiplier"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <input
            type="text"
            value={num_comb}
            onInput={(e) => onMakeInput(index, e)}
            onChange={(evnt) => handleChange(index, evnt)}
            name="num_comb"
            className="form-control font-loader"
          />{' '}
        </td>
        <td>
          <button
            className="btn btn-outline-info font-loader"
            onClick={(e) => {
              e.preventDefault();
              updateState(index);
            }}
          >
            {<BsPencil />}
          </button>
        </td>
        <td>
          <button
            className="btn btn-outline-danger font-loader"
            onClick={(e) => {
              e.preventDefault();
              deleteTableRows(index);
            }}
          >
            {<BsTrashFill />}
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRows;
