import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import Go_back from '../../assets/arrow-left.svg';
import {
  GET_ALL_PRODUCTS_TYPES,
  GET_ALL_PRODUCT_OWNERS,
} from '../../Graphql/Queries';
import axios from 'axios';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import AddProductPropoerty from './addTableRows/Content/addTableRow';
import { useFormik } from 'formik';
import { ADD_PRODUCT, UPDATE_PRODUCT } from '../../Graphql/Mutations';
import { useLocation } from 'react-router-dom';
import { formatDateFull } from '../../utils';

const CreateProduct = () => {
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState();
  const [productOwners, setProductOwners] = useState();
  const [selectedfile, setSelectedFile] = useState(null);
  const [imgSrc, setImgSrc] = useState('');
  // const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem('accesstoken');

  useEffect(() => {
    const downloadFile = (filename) => {
      var config = {
        method: 'get',
        url: ` https://backend.staging.megafortunelottery.com/storage/file/?itemname=${filename}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: '',
      };

      axios(config)
        .then((response) => {
          setImgSrc(response.data.download_url);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    
    {
      location.state && downloadFile(location.state.image);
    }
  }, []);

  const gotoSetup = () => navigate("/admin/setup")
  // function gotoSetup() {
  //   navigate("/admin/setup")
  // }

  const handleFile = (e) => {
    const size = e.target.files[0].size / 1024 / 1024;
    if (size > 1) {
      alert('File size must not exceed 1MB');
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  const onFileUpload = () => {
    setLoading(true);

    const token = localStorage.getItem('accesstoken');

    if (selectedfile == null) {
      updateProduct();
    } else {
      const formData = new FormData();
      formData.append('file', selectedfile);

      var config = {
        method: 'post',
        url: 'https://backend.staging.megafortunelottery.com/storage/upload/',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      axios(config)
        .then((response) => {
          setLoading(false);
          formik.setFieldValue('image', response.data.data);
          if (location.state?.id !== undefined) {
            updateProduct();
          } else {
            SubmitProductRequest();
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const { data } = useQuery(GET_ALL_PRODUCTS_TYPES);

  const { data: OwnerData } = useQuery(GET_ALL_PRODUCT_OWNERS);

  useEffect(() => {
    if (data) {
      setProductTypes(data.product_type);
    }
  }, [data]);

  useEffect(() => {
    if (location.state != null) {
      localStorage.setItem('prod_obj', JSON.stringify(location.state));
      localStorage.setItem('productID', location.state.id);
    }
  }, [location.state]);

  useEffect(() => {
    if (OwnerData) {
      setProductOwners(OwnerData.product_owner);
    }
  }, [OwnerData]);

  const addProperty = (data) => {
    formik.setFieldValue('digit_options', data);
  };

  const formik = useFormik({
    initialValues: {
      name: location.state?.name,

      image: location.state?.image,

      description: location.state?.description,

      product_type_id: location.state?.product_type.id,

      schedule_type: location.state?.schedule_type,

      run_start: location.state?.run_start,

      run_end: location.state?.run_end,

      schedule_day: location.state?.schedule_day,

      schedule_start: location.state?.schedule_start,

      schedule_end: location.state?.schedule_end,

      owner_id: '',

      claim_expiry_hours: location.state?.claim_expiry_hours,

      digit_options: location.state?.properties.digit_options,

      min_stake_amount: location.state?.min_stake_amount,

      slogan: location.state?.slogan,

      prefix: location.state?.prefix,

      odd: location.state?.games.length > 0 ? location.state?.games[0].odds : 0,

      max_stake_amount: location.state?.max_stake_amount,
      max_digit: location.state?.properties.max_digit,
    },

    onSubmit: (values) => {
      console.log(values);
      onFileUpload();
    },
  });

  const [SubmitProductRequest, { loading: SubmitRequestLoading }] = useMutation(
    ADD_PRODUCT,
    {
      variables: {
        name: formik.values.name,

        image: formik.values.image,

        description: formik.values.description,

        product_type_id: formik.values.product_type_id,

        schedule_type: formik.values.schedule_type,

        run_start: formik.values.run_start,

        run_end: formik.values.run_end,

        schedule_day: [formik.values.schedule_day],

        schedule_start: formik.values.schedule_start,

        schedule_end: formik.values.schedule_end,

        min_stake_amount: formik.values.min_stake_amount,

        owner_id: formik.values.owner_id,

        claim_expiry_hours: formik.values.claim_expiry_hours,

        slogan: formik.values.slogan,

        prefix: formik.values.prefix,

        odds: formik.values.odd,

        max_stake_amount: formik.values.max_stake_amount,

        properties: {
          max_digit: formik.values.max_digit,

          digit_options: formik.values.digit_options,
        },
      },
      onCompleted: () => {
        formik.resetForm({ values: formik.initialValues });
        SuccessNotification('Successfully submitted request');
      },

      onError: (error) => {
        console.log(error);
        ErrorNotification('An error occurreed while creating game');
      },
    },
  );

  const [updateProduct, { loading: UpdatetRequestLoading }] = useMutation(
    UPDATE_PRODUCT,
    {
      variables: {
        image: formik.values.image,

        productId: location.state && location.state.id,

        description: formik.values.description,

        schedule_type: formik.values.schedule_type,

        run_start: formik.values.run_start,

        run_end: formik.values.run_end,

        schedule_day: formik.values.schedule_day,

        schedule_start: formik.values.schedule_start,

        schedule_end: formik.values.schedule_end,

        min_stake_amount: formik.values.min_stake_amount,

        claim_expiry_hours: formik.values.claim_expiry_hours,

        slogan: formik.values.slogan,

        prefix: formik.values.prefix,

        odds: formik.values.odd,

        max_stake_amount: formik.values.max_stake_amount,

        properties: {
          max_digit: formik.values.max_digit,

          digit_options: formik.values.digit_options,
        },
      },
      onCompleted: () => {
        formik.resetForm({ values: formik.initialValues });
        SuccessNotification('Successfully updateded this product');
        setLoading(false);
      },

      onError: (error) => {
        console.log(error);
        ErrorNotification('An error occurreed while creating game');
      },
    },
  );

  const days = [
    { id: 'sun', name: 'Sunday' },
    { id: 'mon', name: 'Monday' },
    { id: 'tue', name: 'Tuesday' },
    { id: 'wed', name: 'Wednesday' },
    { id: 'thu', name: 'Thursday' },
    { id: 'fri', name: 'Friday' },
    { id: 'sat', name: 'Saturday' },
  ];

  return (
    <Container style={{ padding: 50 }} maxWidth={false}>
      <button style={{ cursor: 'pointer' }} onClick={gotoSetup}>
        {' '}
        <img src={Go_back} alt="/" />
      </button>
      <div
        style={{
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          padding: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
        >
          <p style={{ fontWeight: 'bolder' }}>
            {location.state?.id ? 'Update Product' : 'Create New Product'}
          </p>
          {location.state?.id && (
            <button
              className="btn btn-info"
              onClick={() => navigate('/admin/product-games')}
            >
              <img style={{ position: 'relative', top: -1 }} alt="/" /> Manage
              Events
            </button>
          )}
        </div>

        <hr />
        <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
          <div className="row" style={{ marginBottom: 20 }}>
            <div className="row">
              <div className="col">
                <input
                  defaultValue={formik.values.name}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  disabled={location.state?.id !== undefined ? true : false}
                />
              </div>
              <div className="col">
                <div>
                  <select
                    defaultValue={formik.values.product_type_id}
                    onChange={formik.handleChange}
                    className="form-select"
                    aria-label="Default select example"
                    name="product_type_id"
                  >
                    <option value="Staff">Choose a product id</option>
                    {productTypes?.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </div>
                {location.state?.product_type && (
                  <p>Selected option: {location.state.product_type.category}</p>
                )}
              </div>
              <div className="col">
                <input
                  defaultValue={selectedfile?.name}
                  onChange={(e) => handleFile(e)}
                  type="file"
                  name={'image'}
                  className="form-control"
                  placeholder="Last name"
                />
                {location.state?.image && (
                  <p>
                    Selected option: <img src={imgSrc} height="40" alt="game" />{' '}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 20 }}>
            <div className="row">
              <div className="col">
                <input
                  defaultValue={formik.values.min_stake_amount}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                  placeholder="Minimum stake amount"
                  name="min_stake_amount"
                />
              </div>
              <div className="col">
                <input
                  defaultValue={formik.values.max_stake_amount}
                  onChange={formik.handleChange}
                  name="max_stake_amount"
                  className="form-control"
                  placeholder="Maximum stake amount"
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  defaultValue={formik.values.slogan}
                  onChange={formik.handleChange}
                  name="slogan"
                  className="form-control"
                  placeholder="Enter slogan for the game"
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 20, width: '100%' }}>
            <div className="col">
              <div className="">
                <select
                  defaultValue={formik.values.owner_id}
                  onChange={formik.handleChange}
                  className="form-select"
                  name="owner_id"
                  aria-label="Default select example"
                >
                  <option value="Staff">Choose the owner id</option>
                  {productOwners?.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
              {location.state?.owner && (
                <p>Selected option: {location.state.owner.name}</p>
              )}
            </div>
            <div className="col">
              <input
                defaultValue={formik.values.odd}
                onChange={formik.handleChange}
                name="odd"
                className="form-control"
                placeholder="Enter odd"
              />
            </div>

            <div className="col">
              <textarea
                defaultValue={formik.values.description}
                onChange={formik.handleChange}
                name="description"
                className="form-control"
                placeholder="Enter description"
                rows="1"
              ></textarea>
            </div>
          </div>

          <div className="row" style={{ marginBottom: 20, width: '100%' }}>
            <div className="col">
              <div>
                <select
                  defaultValue={formik.values.schedule_type}
                  onChange={formik.handleChange}
                  name="schedule_type"
                  className="form-select form-control"
                  aria-label="Default select example"
                >
                  <option value="">Choose schedule type</option>
                  <option value="day of week">day of week</option>
                </select>
              </div>
              {location.state?.product_type && (
                <p>Selected option: Day of week</p>
              )}
            </div>

            <div className="col">
              <div>
                <select
                  defaultValue={formik.values.schedule_day}
                  onChange={formik.handleChange}
                  name="schedule_day"
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="Staff">Choose Schedule day</option>
                  {days.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.name}
                    </option>
                  ))}
                </select>
              </div>
              {location.state?.schedule_day && (
                <p>Selected option: {location.state.schedule_day}</p>
              )}
            </div>
          </div>

          <div className="row" style={{ marginBottom: 20, width: '100%' }}>
            <div className="col">
              <div style={{ display: 'flex' }}>
                <label>
                  Choose schedule start
                  <input
                    defaultValue={formik.values.schedule_start}
                    onChange={formik.handleChange}
                    name="schedule_start"
                    type="date"
                    className="form-control"
                    placeholder="Last name"
                  />
                </label>
              </div>
              {location.state?.schedule_start && (
                <p>
                  Selected option:{' '}
                  {formatDateFull(location.state.schedule_start)}
                </p>
              )}
            </div>

            <div className="col">
              <div>
                <div style={{ display: 'flex' }}>
                  <label>
                    Choose run start
                    <input
                      type="time"
                      defaultValue={formik.values.run_start}
                      onChange={formik.handleChange}
                      name="run_start"
                      className="form-control"
                      placeholder="run start"
                    />
                  </label>
                </div>
                {location.state?.product_type && (
                  <p>Selected option: {location.state.run_start}</p>
                )}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: 20, width: '100%' }}>
            <div className="col">
              <div style={{ display: 'flex' }}>
                <label>
                  Choose schedule end
                  <input
                    defaultValue={formik.values.schedule_end}
                    onChange={formik.handleChange}
                    name="schedule_end"
                    type="date"
                    className="form-control"
                    placeholder="Last name"
                  />
                </label>
              </div>
              {location.state?.schedule_end && (
                <p>
                  Selected option: {formatDateFull(location.state.schedule_end)}
                </p>
              )}
            </div>

            <div className="col">
              <div style={{ display: 'flex' }}>
                <label>
                  Choose run end
                  <input
                    defaultValue={formik.values.run_end}
                    onChange={formik.handleChange}
                    name="run_end"
                    type="time"
                    className="form-control"
                    placeholder="Last name"
                  />
                </label>
              </div>
              {location.state?.product_type && (
                <p>Selected option: {location.state.run_end}</p>
              )}
            </div>
          </div>

          <div className="row" style={{ marginBottom: 20, width: '100%' }}>
            <div className="col">
              <div style={{ display: 'flex' }}>
                <input
                  defaultValue={formik.values.prefix}
                  onChange={formik.handleChange}
                  name="prefix"
                  type="text"
                  className="form-control"
                  placeholder="Enter prefix"
                />
              </div>
            </div>

            <div className="col">
              <div style={{ display: 'flex' }}>
                <input
                  defaultValue={formik.values.max_digit}
                  onChange={formik.handleChange}
                  name="max_digit"
                  type="text"
                  className="form-control"
                  placeholder="Enter max digits"
                />
              </div>
            </div>

            <div className="col">
              <div style={{ display: 'flex' }}>
                <input
                  defaultValue={formik.values.claim_expiry_hours}
                  onChange={formik.handleChange}
                  name="claim_expiry_hours"
                  type="text"
                  className="form-control"
                  placeholder="Enter claim expiry hours "
                />
              </div>
            </div>
          </div>

          <hr />

          <div
            className="row"
            style={{
              width: '100%',
              height: 250,
              overflowX: 'hidden',
            }}
          >
            {location.state?.properties ? (
              <AddProductPropoerty
                setContents={addProperty}
                populatedData={location.state.properties.digit_options}
              />
            ) : (
              <AddProductPropoerty
                setContents={addProperty}
                populatedData={[]}
              />
            )}
          </div>

          <button
            style={{ marginTop: 40 }}
            type={'submit'}
            className="btn btn-primary form-control"
          >
            {SubmitRequestLoading || loading || UpdatetRequestLoading
              ? 'Loading...'
              : location.state?.id
                ? 'Update Product'
                : 'Create New Product'}
          </button>
        </form>
      </div>
    </Container>
  );
};

CreateProduct.propTypes = {
  classNameName: PropTypes.string,
};

export default CreateProduct;
