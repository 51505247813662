import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import { SEND_NOTIFICATION } from '../../../Graphql/Mutations';
import { GET_ALL_USERS } from '../../../Graphql/Queries';
import {
	ErrorNotification,
	SuccessNotification,
} from '../../../components/Notification';

const Index = () => {
	const [hideReciepient, setReciepient] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [allusers, setUsers] = useState();
	const [usertype, setuserType] = useState('1');
	// const token = localStorage.getItem("token");

	const [state, setState] = React.useState({
		title: '',
		body: '',
		phone: '',
		schedule_date: '',
	});

	const [CreateSmsRecord] = useMutation(SEND_NOTIFICATION, {
		onCompleted: () => {
			SuccessNotification('Sms sent successfully');
		},
		onError: () => ErrorNotification('Sms failed to send'),
	});

	useQuery(GET_ALL_USERS, {
		onCompleted: (data) => {
			let userArray = [];

			for (var i = 0; i < data.customer.length; i++) {
				if (data.customer.user_type === 'agent') {
					let d = {
						id: data.customer[i].id,
						label: data.customer[i].full_name,
						value: data.customer[i].phone_number,
						usertype: data.customer[i].user_type,
					};
					userArray.push(d);
				} else {
					let d = {
						id: data.customer[i].id,
						label: data.customer[i].full_name,
						value: data.customer[i].phone_number,
						usertype: data.customer[i].user_type,
					};
					userArray.push(d);
				}
			}

			setUsers(userArray);
		},

		onError: () => {
			ErrorNotification('Failed to load user list');
		},
	});

	const sms_action = (title, message) => {
		let target = null;
		if (usertype === '1') {
			target = ['agents', 'customers'];
		} else if (usertype === '2') {
			target = 'agents';
		} else if (usertype === '3') {
			target = 'customers';
		} else if (usertype === '4') {
			target = null;
		}

		let customers = [];

		if (usertype === '1') {
			for (var i = 0; i < allusers.length; i++) {
				let obj = {
					customer_id: allusers[i].id,
				};
				customers.push(obj);
			}
		} else if (usertype === '4') {
			for (var a = 0; a < selectedOption.length; a++) {
				let obj = {
					customer_id: selectedOption[a].id,
				};
				customers.push(obj);
			}
		}

		CreateSmsRecord({
			variables: {
				channels: ['sms'],
				target: target === null ? null : [target],
				plain_text: message,
				title: title,
				recipients: customers,
			},
		});
	};

	const setReciepientShow = (value) => {
		if (value === '4') {
			setReciepient(true);
		} else {
			setReciepient(false);
		}
	};

	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});
	}

	return (
		<Container style={{ padding: 50 }} maxWidth={false}>
			<div
				style={{
					boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
					padding: 40,
				}}
			>
				<p style={{ fontWeight: 'bolder' }}>Compose New SMS</p>
				<hr />
				<form style={{ width: '100%' }}>
					<div className="form-group">
						<label htmlFor="exampleInputEmail1">Select sms user type</label>
						<select
							onChange={(e) => {
								{
									setReciepientShow(e.target.value);
									setuserType(e.target.value);
								}
							}}
							className="form-select"
							aria-label="Default select example"
						>
							<option value="1">All users</option>
							<option value="2">All agents</option>
							<option value="3">All customers</option>
							<option value="4">Selected users</option>
						</select>
					</div>
					<div className="form-group">
						<label htmlFor="exampleInputEmail1">Sms Title</label>

						<input
							onChange={handleChange}
							name="title"
							type="text"
							className="form-control"
							// defaultValue={values.title}
						/>
					</div>

					<div className="form-group">
						<label htmlFor="exampleInputEmail1">Sms Body</label>
						<textarea
							onChange={handleChange}
							// defaultValue={values.body}
							name="body"
							cols="40"
							rows="5"
							className="form-control"
						/>
					</div>

					{hideReciepient ? (
						<div className="form-group ">
							<label htmlFor="contact" className="font-loader">
                Choose Contact to send sms
								<span style={{ color: 'red' }}> *</span>
							</label>
							<Select
								id="contact"
								onChange={setSelectedOption}
								name="phone"
								isMulti
								options={allusers}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</div>
					) : null}

					{/* <div class="form-group">
            <label for="exampleInputEmail1">Sms Plan</label>
            <select
              onChange={(e) => {
                setSmsPlan(e.target.value);
              }}
              class="form-select"
              aria-label="Default select example"
            >
              <option value="1">Send Now</option>
              <option value="2">Schedule Sms</option>
            </select>
          </div> */}

					{/* {smsplan === "2" && (
            <div class="form-group">
              <label for="exampleInputEmail1">Schedule Date</label>
              <input
                onChange={handleChange}
                name="schedule_date"
                type="date"
                class="form-control"
                // defaultValue={values.title}
              />
            </div>
          )} */}

					<button
						onClick={(e) => {
							e.preventDefault();
							sms_action(state.title, state.body);
						}}
						className="btn btn-primary form-control"
					>
            Send SMS
					</button>
				</form>
			</div>
		</Container>
	);
};

Index.propTypes = {
	className: PropTypes.string,
};

export default Index;
