import React from 'react';
import { Column } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite/no-important';
import MiniCardComponent from './MiniCardComponent';
import WriterTodayTrendsComponent from './WriterTodayTrends';

const styles = StyleSheet.create({
  cardsContainer: {},
  cardRow: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0,1fr))',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginTop: 0,
    },
  },
  spinnerContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },

  miniCardContainer: {
    flexGrow: 1,
    marginRight: 30,
    '@media (max-width: 768px)': {
      marginTop: 30,
      maxWidth: 'none',
    },
  },
  todayTrends: {
    marginTop: 30,
  },
  lastRow: {
    marginTop: 30,
  },
  unresolvedTickets: {
    marginRight: 30,
    '@media (max-width: 1024px)': {
      marginRight: 0,
    },
  },
  tasks: {
    marginTop: 0,
    '@media (max-width: 1024px)': {
      marginTop: 30,
    },
  },
});

function WriterComponent() {
  let today_game = null;
  let total_winners = 0;
  // const [grapghType, setGrapghType] = useState("winnings")

  // const handleChange = (value) => {
  //   setGrapghType(value)

  // }

  const dashboardData = [
    {
      id: 1,
      title: 'Total Writers',
      value: 25,
    },
    {
      id: 2,
      title: 'Total Stakes',
      value: (4565765).toLocaleString(),
    },
    {
      id: 3,
      title: 'Today\'s Stakes',
      value: (245).toLocaleString(),
    },
    {
      id: 4,
      title: 'Commission',
      value: (1435).toLocaleString(),
    },
  ];
  return (
    <Column>
      <>
        <div className={css(styles.cardRow)}>
          {dashboardData.map((item, key) => (
            <MiniCardComponent
              key={key}
              className={css(styles.miniCardContainer)}
              title={item.title}
              value={item.value}
            />
          ))}
        </div>
        <div className={css(styles.todayTrends)}>
          <WriterTodayTrendsComponent
            winningNumbers={[]}
            todayGame={today_game}
            // handleChange={handleChange}
            // data={grapghType==="winnings" ? winnersGrapgh : payoutGrapgh }
            data={payout}
            payoutdata={payout}
            todayWinners={total_winners}
          />
        </div>
      </>
    </Column>
  );
}

const payout = [
  {
    x: 'january',
    y: 40,
  },
  {
    x: 'february',
    y: 497140,
  },
  {
    x: 'march',
    y: 19880,
  },
  {
    x: 'april',
    y: 41460,
  },
  {
    x: 'may',
    y: 40,
  },
  {
    x: 'june',
    y: 0,
  },
  {
    x: 'july',
    y: 0,
  },
  {
    x: 'august',
    y: 0,
  },
  {
    x: 'september',
    y: 0,
  },
  {
    x: 'october',
    y: 0,
  },
  {
    x: 'november',
    y: 0,
  },
  {
    x: 'december',
    y: 0,
  },
];

export default WriterComponent;
