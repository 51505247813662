import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@material-ui/core';
import './index.css';
import { Tabs, Tab } from 'react-bootstrap';
// import { useHistory } from "react-router-dom";
import TableList from './smsPage';
import EmailTableList from './EmailPage';

const Index = () => {
  return (
    <Container
      // style={{}}
      maxWidth={false}
    >
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          lg={8}
          sm={6}
          xl={12}
          xs={12}
          style={{
            marginTop: 40,
            marginLeft: '1%',
            marginRight: '1%',
            borderRadius: 10,
            height: '100vh',
            backgroundColor: 'white',
          }}
        >
          <div style={{ padding: 10, width: '100%' }}>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Email Notification">
                <EmailTableList />
              </Tab>
              <Tab eventKey="profile" title="Sms Notification">
                <TableList />
              </Tab>
            </Tabs>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

Index.propTypes = {
  className: PropTypes.string,
};

export default Index;
