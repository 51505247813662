import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.css';

const AdminModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.close}
      animation={true}
      dialogClassName="main-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      style={{ marginTop: 30 }}
      {...props}
    >
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title">
          {props.title}
        </Modal.Title>
        <button
          onClick={() => props.handleClose()}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body style={{ maxHeight: '700px', overflowY: 'auto' }}>
        {props.body}
      </Modal.Body>
    </Modal>
  );
};

export default AdminModal;
