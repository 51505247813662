import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import { Form } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import './index.css';
import { BiFilter } from 'react-icons/bi';
import {
  GET_ALL_REGIONS,
  GET_ALL_AGENT_FUNDS_OWED,
} from '../../Graphql/Queries';
import { ErrorNotification } from '../../components/Notification';
import AdminModal from '../../components/Modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { formatAmount } from '../../utils';

const AgentPayouyIndex = () => {
  // const [loading, setLoading] = useState(false);
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [regions, setRegions] = useState([]);
  const [agent_data, setAgentData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  // const [maxselectedWinners, setMaxSelectedWinners] = useState([]);
  // const [selectedWinners, setSelectedWinners] = useState();
  const [regionTotal, setRegionTotal] = useState(0);

  const { loading: regionLoading } = useQuery(GET_ALL_REGIONS, {
    onCompleted: (data) => {
      setRegions(data.regions);
    },
    onError: () => {
      ErrorNotification('Failed to load regions');
    },
  });

  const [getAgentFunds, { loading: agentfundsLoading }] = useLazyQuery(
    GET_ALL_AGENT_FUNDS_OWED,
    {
      variables: {
        region_id: selectedRegion,
      },

      onCompleted: (data) => {
        let fundList = [];
        //   console.log('agentData', agentfundsData.customer)
        let agents = data.customer;
        let totalBalance = agents.reduce(
          (acc, curr) => acc + curr.customer_account?.balance,
          0,
        );
        console.log('total Balance', totalBalance);
        setRegionTotal(totalBalance);
        if (data.customer.length > 0) {
          for (var i = 0; i < data.customer.length; i++) {
            let cData = {
              id: data.customer[i].id,
              agent_name: data.customer[i].full_name,
              region: data.customer[i].region.name,
              phone: data.customer[i].phone_number,
              total_balance: data.customer[i].customer_account.balance,
              debt: data.customer[i].customer_account.balance,
              debt_status:
                data.customer[i].customer_account.balance > 0 ? true : false,
            };

            fundList.push(cData);
          }
        }
        setAgentData(fundList);
      },

      onError: () => {
        ErrorNotification('Failed to load agents fund owned');
      },
    },
  );

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    onRowSelectionChange: (v) => {
      let rowWinners = [];
      let maxWinners = [];
      v.map((r) => {
        // console.log(agent_data[r]);

        if (agent_data[r].debt > 5000) {
          let c = {
            customer_id: agent_data[r].id,
            game_id: '2eeecdgsha-10eushaa-12nsnajjd',
          };

          maxWinners.push(c);
        } else {
          let c = {
            customer_id: agent_data[r].id,
            game_id: '2eeecdgsha-10eushaa-12nsnajjd',
          };

          rowWinners.push(c);
        }
      });
      // console.log(rowWinners);
      // console.log(maxWinners);
      // setSelectedWinners(rowWinners);
      // setMaxSelectedWinners(maxWinners);
    },
    // customToolbarSelect: () => {},
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'agent_name',
      label: 'Agent full name',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'region',
      label: 'Region',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'phone',
      label: 'Phone number',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'total_balance',
      label: 'TOTAL BALANCE(GHS)',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },
    // {
    //   name: 'debt',
    //   label: 'PAYABLE AMOUNT(GHS)',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: val => {
    //       return (
    //         <p style={{ position: 'relative', left: 30, top: 7 }}>
    //           {formatAmount(val)}
    //         </p>
    //       )
    //     }
    //   }
    // },

    {
      name: 'debt_status',
      label: 'DEBT STATUS',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          if (val == false) {
            return (
              <FiCheckCircle
                style={{
                  position: 'relative',
                  left: 30,
                  color: 'green',
                  top: 7,
                }}
              />
            );
          } else {
            return (
              <FaRegTimesCircle
                style={{ position: 'relative', left: 30, color: 'red', top: 7 }}
              />
            );
          }
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Close game here'}
        body={<></>}
        handleClose={handleclose2}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            float: 'right',
            width: '50%',
          }}
        >
          <div style={{ marginRight: 15, width: '100%', marginBottom: 10 }}>
            <Form.Control
              required
              as="select"
              type="select"
              onInput={(e) => setSelectedRegion(e.target.value)}
              style={{
                backgroundColor: 'white',
                color: '#37517e',
                border: 'solid',
                width: '100%',
                height: '36px',
              }}
            >
              <option value="">Select region</option>
              {regions?.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </Form.Control>
          </div>

          {/* <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">From</span>
                            </div>
                            <input type="date" class="form-control" placeholder="Some text" />
                        </div>
                    </div>

                    <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">To</span>
                            </div>
                            <input type="date" class="form-control" placeholder="Some text" />
                        </div>
                    </div> */}

          <div style={{ marginRight: 15, marginBottom: 10 }}>
            <button
              className="btn btn-outline-info"
              style={{ height: 36 }}
              onClick={() => getAgentFunds()}
            >
              <BiFilter size={21} /> Filter
            </button>
          </div>

          <div
            style={{
              marginBottom: 10,
              width: 500,
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 5,
            }}
          >
            <span>Regional Balance:</span>
            <span>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'GHS',
              }).format(regionTotal)}
            </span>
          </div>
        </div>
      </div>

      {regionLoading || agentfundsLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <MUIDataTable
            data={agent_data}
            title={''}
            columns={columns}
            options={options}
            // onRowClick={handleRowClick}
          />

          {/* <div style={{ display: "flex", flexDirection: "row", float: "right" }}>

                        <div style={{ position: "relative", top: 10, marginRight: 20 }}>
                            <button className="btn btn-success" onClick={() => raiseAlert()}>
                                Pay with Momo

                            </button>
                        </div>

                        <div style={{ position: "relative", top: 10 }}>
                            <button className="btn btn-warning" >
                                Pay with Cheque

                            </button>
                        </div>
                    </div> */}
        </>
      )}
    </div>
  );
};

export default AgentPayouyIndex;
