import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Spinner from './Spinner';
import { api } from '../utils/Api';

function StakesDetailComponent({ number, raiseAlert }) {
  const loading = false;
  const [stakesdata, setStakesData] = useState();
  const role = localStorage.getItem('role');

  // const handleRowClick = (rowData) => {};

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    // onRowClick: handleRowClick,
    selectableRows: 'none',
    expandableRowsOnClick: true,
    isRowExpandable: () => {
      return true;
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val}</p>;
        },
      },
    },
    {
      name: 'full_name',
      label: 'Full Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val && val}</p>;
        },
      },
    },

    {
      name: 'phone_number',
      label: 'Phone number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 10 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'amt_per_stake_total',
      label: 'Amount per stake total',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val}</p>;
        },
      },
    },
    {
      name: 'num_stakes',
      label: 'Number of stakes',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'commission_amt',
      label: 'Commission Amount(GHS) unformated',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 80 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'commission_amt_fmt_20',
      label: 'Commission amount 20% (GHS) formatted',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'commission_amt_fmt_35',
      label: 'Commission amount 35% GHS formatted',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'paid_amount',
      label: 'Paid amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val}</p>;
        },
      },
    },
  ];

  async function getStakes() {
    // let token = localStorage.getItem("accesstoken");
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    //   "Content-Type": "application/json", // You can specify other headers as needed
    // };

    const apiUrl = `api/v1/payments/unpaid/stake/list?phone_number=${number}`;

    try {
      const response = await api.get(apiUrl);
      // Handle a successful response here
      console.log('POST request successful:', response.data);
      setStakesData(response.data.data);
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('POST request error:', error);
    }
  }

  useEffect(() => {
    getStakes();
  }, []);

  return (
    <div>
      {role && role === 'finance' && (
        <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
          <button
            className="btn btn-success"
            onClick={() => {
              raiseAlert(number);
            }}
          >
            <img alt="" style={{ position: 'relative', top: -100 }} /> Pay Agent
          </button>
        </div>
      )}

      <div
        style={{
          paddingTop: '40px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {loading ? (
          <div
            style={{
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </div>
        ) : (
          <MUIDataTable
            data={stakesdata}
            title=""
            columns={columns}
            options={options}
            // onRowClick={handleRowClick}
          />
        )}
      </div>
    </div>
  );
}

export default StakesDetailComponent;
