import React, { useState } from "react";
import { GET_ALL_WRITERS, GET_ALL_REGIONS } from "../../Graphql/Queries";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const WriterFmWriterView = () => {
  // const [pendingAgents, setPendingAgents] = useState([]);
  const [approvedAgentes, setApprovedAgents] = useState([]);
  // const history = useHistory();
  const navigate = useNavigate();
  const [regions, setRegions] = useState();
  // const [agentName, setAgentRegion] = useState();

  const { loading: agentDataLoading } = useQuery(GET_ALL_WRITERS, {
    onCompleted: (data) => {
      let pending = [];
      let approved = [];

      for (let i = 0; i < data.writer?.length; i++) {
        if (data.writer[i].status !== "active") {
          pending.push(data.writer[i]);
        } else {
          approved.push(data.writer[i]);
        }
      }

      setApprovedAgents(approved);
      // setPendingAgents(pending);
    },
  });

  // export const dataRefetch = () => {

  // }

  const { loading: regionDataLoading } = useQuery(GET_ALL_REGIONS, {
    onCompleted: (data) => {
      setRegions(data.regions);
    },
  });

  const getRegionNamme = (id) => {
    let regionName = "";

    setRegions(regions.regions);

    regionName = regions.regions.filter((region) => {
      return region.id === id;
    });

    // console.log(regionName[0].name)
    // setAgentRegion(regionName[0]?.name);

    return regionName[0]?.name;
  };

  // const handleRowClickPending = (rowIndex, dataIndex) => {
  //   console.log(rowIndex)
  //   history.push({
  //     pathname: "/admin/writer-analytic",
  //     state: pendingAgents[dataIndex.dataIndex],
  //   });
  // };

  const handleRowClickApproved = (rowIndex, dataIndex) => {
    console.log(rowIndex);
    navigate("/admin/writer-analytic", {
      state: approvedAgentes[dataIndex.dataIndex],
    });
  };

  // const optionsPending = {
  //   filterType: "checkbox",
  //   filter: false,
  //   print: false,
  //   selectableRows: false,
  //   // download: false,
  //   // viewColumns: false,
  //   // onCellClick: handleCell,
  //   onRowClick: handleRowClickPending,
  //   customToolbarSelect: () => {},
  //   sortOrder: {
  //     name: "created_at",
  //     direction: "desc",
  //   },
  // };

  const optionsApproved = {
    filterType: "checkbox",
    filter: false,
    print: false,
    selectableRows: false,
    responsive: "scroll",
    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    onRowClick: handleRowClickApproved,
    customToolbarSelect: () => {},
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 40,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 40,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "region_id",
      label: "Region",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 10,
            }}
          >
            {regions !== null && getRegionNamme(value)}
          </div>
        ),
      },
    },
    {
      name: "town_city",
      label: "town",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 10,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 30,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              backgroundColor: value === "active" ? "#50C878" : "pink",
              position: "relative",
              left: 1,
              padding: "2px",
              width: "6rem",
              color: "white",
              textAlign: "center",
              borderRadius: "10px",
            }}
          >
            {value}
          </div>
        ),
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          padding: 15,
        }}
      >
        <BiArrowBack
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          size={50}
        />
      </div>

      {agentDataLoading && regionDataLoading ? (
        <CircularProgress
          style={{ position: "relative", marginTop: "25%", left: "55%" }}
        />
      ) : (
        <div style={{ width: "100%", padding: 20 }}>
          <MUIDataTable
            title={"My Writers"}
            data={approvedAgentes ? approvedAgentes : []}
            columns={columns}
            options={optionsApproved}
          />
        </div>
      )}
    </div>
  );
};

export default WriterFmWriterView;
