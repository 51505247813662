import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS } from '../../Graphql/Queries';
import { CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from 'react-icons/bi';
import { ErrorNotification } from '../../components/Notification';

const CustomerIndex = () => {
	const [customers, setCustomers] = useState(null);
	// const history = useHistory();
	const navigate = useNavigate();
	const { loading: CustomerDataLoading } = useQuery(GET_ALL_CUSTOMERS, {
		onCompleted: (data) => {
			setCustomers(data.customers);
		},

		onError: () => {
			ErrorNotification('Failed to load customer data');
		},
	});

	const handleRowClick = (rowIndex, dataIndex) => {
		console.log('Row Index', rowIndex);
		// console.log("Data Index", dataIndex)
		// console.log(customers[dataIndex.rowIndex])
		navigate(
			 '/admin/customer-details', {
			state: customers[dataIndex.dataIndex],
		});
	};

	const options = {
		filterType: 'checkbox',
		filter: false,
		print: false,
		selectableRows: false,
		responsive: 'scroll',
		// download: false,
		// viewColumns: false,
		// onCellClick: handleCell,
		onRowClick: handleRowClick,
		customToolbarSelect: () => {},
		sortOrder: {
			name: 'last_name',
			direction: 'asc',
		},
	};

	const columns = [
		{
			name: 'first_name',
			label: 'First Name',
			option: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'last_name',
			label: 'Last Name',
			option: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'email',
			label: 'Email Address',
			option: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'phone_number',
			label: '',
			option: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					if (val === 'null') {
						return 'No phone available';
					} else {
						return val.product.name;
					}
				},
			},
		},
		{
			name: 'status',
			label: '',
			option: {
				filter: true,
				sort: true,
			},
		},
	];

	return (
		<div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
			<div
				style={{
					padding: 15,
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<BiArrowBack
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						e.preventDefault();
						navigate(-1);
					}}
					size={50}
				/>
				<h1 style={{ marginLeft: 10 }}>Customers</h1>
			</div>

			<div
				style={{
					padding: 10,
				}}
			>
				{CustomerDataLoading ? (
					<CircularProgress
						style={{ position: 'relative', marginTop: '20%', left: '55%' }}
					/>
				) : (
				// customers?.map((customer, key) => {
				//   return (
				//     <CustomerCard
				//       key={key}
				//       customer={customer}
				//       firstName={customer.first_name}
				//       lastName={customer.last_name}
				//       email={customer.email}
				//       phoneNumber={
				//         customer?.phone_number ? customer?.phone_number : null
				//       }
				//       status={customer?.status}
				//     />
				//   )
				// })
					<MUIDataTable
						title={'All Customers'}
						data={customers ? customers : []}
						columns={columns}
						options={options}
					/>
				)}
			</div>
		</div>
	);
};

export default CustomerIndex;
