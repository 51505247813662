import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminModal from '../../components/Modal';
import MessageDetail from '../SmsView/Sms/MessageDetail';
import { useQuery } from '@apollo/client/react';
// import { SMS_LOG } from "../../Graphql/Mutations";
import { GET_ALL_SENT_EMAIL } from '../../Graphql/Queries';
import { formatDateFull } from '../../utils';
import { IoEye } from 'react-icons/io5';
import { IoTrashOutline } from 'react-icons/io5';
import Spinner from '../../components/Spinner';
import './styles.css';
import { ErrorNotification } from '../../components/Notification';

const TableList = () => {
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [rawData, setRawData] = useState();
  const [smsData, setsmsData] = useState();
  const [detail, setDetail] = useState('');
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'message',
      label: 'Message',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div style={{ width: '100%' }}>
              {val.length > 60 ? val.substring(0, 50) + '...' : val}
            </div>
          );
        },
      },
    },

    {
      name: 'sent_date',
      label: 'Date Sent',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'reciepient',
      label: 'Reciepient',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      align: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val === 'Sent' ? (
                <div className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center">
                  <p className="text-green">{val.toLowerCase()}</p>
                </div>
              ) : val === 'Pending' ? (
                <div className="px-4  mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center">
                  <p className="text-orange">{val.toLowerCase()}</p>
                </div>
              ) : (
                <div className="px-3 py-1 mt-2 divheight bg-blueLight rounded-12 flex items-center justify-center">
                  <p className="text-blue">{val.toLowerCase()}</p>
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'Actions',
      label: 'Actions',
      options: {
        filter: true,
        sort: true,
        customBodyRender: () => {
          return (
            <div
              style={{
                textAlign: 'center',
                marginLeft: 20,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <IoEye
                size={15}
                color="grey"
                style={{ marginRight: 10, cursor: 'pointer' }}
                onClick={() => setShowMDetailModal(true)}
              />
              <IoTrashOutline size={15} color="red" />
            </div>
          );
        },
      },
    },
  ];

  const { loading: RequestLoading } = useQuery(GET_ALL_SENT_EMAIL, {
    variables: {
      createdBy: localStorage.getItem('token'),
    },

    onCompleted: (data) => {
      let tabledata = [];
      let m = data.notification;
      setRawData(data.notification);
      for (var i = 0; i < m.length; i++) {
        let c = {
          title: m[i].title,
          id: m[i].id,
          status: m[i].processed_at !== null ? 'Sent' : 'Failed',
          sent_date: formatDateFull(m[i].processed_at),
          sent_by: 'Paul Dwamena',
          message: m[i].message,
          group: m[i].recipients,
          reciepient: m[i].target !== null ? m[i].target : 'Selected users',
        };

        tabledata.push(c);
      }
      setsmsData(tabledata);
    },

    onError: () => ErrorNotification('Failed to load all sent notifications'),
  });

  const finobject = (data, id) => {
    let y = data.find((x) => x.id === id);
    return y;
  };

  const handleRowClick = (rowData) => {
    let chosenItem = finobject(rawData, rowData[0]);
    // console.log(chosenItem);
    setDetail(chosenItem);
    setShowMDetailModal(true);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: handleRowClick,
    sortOrder: {
      name: 'sent_date',
      direction: 'desc',
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Message details'}
        body={<MessageDetail detail={detail} />}
        handleClose={handleclose2}
      />
      {RequestLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={smsData}
          title={'Sent Email List'}
          columns={columns}
          options={options}
          onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default TableList;
