import React from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner";
import "./index.css";
import { GET_WRITER_STAKES } from "../../Graphql/Queries";
import { CircularProgress, Card, CardContent } from "@material-ui/core";
// import { useHistory } from 'react-router-dom';
// import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { ErrorNotification } from "../../components/Notification";

const WriterAnalytic = () => {
  const loading = false;
  const responsive = "simple";
  // const history = useHistory();
  const navigate = useNavigate();

  useQuery(GET_WRITER_STAKES, {
    variables: {
      writer_id: "4139c822-954b-42a6-b207-fed81866ba37",
    },

    onCompleted: (data) => {
      console.log(data);
    },

    onError: () => ErrorNotification("Failed to get writer stakes"),
  });

  const options = {
    filterType: "checkbox",
    responsive,

    // responsive: "scrollMaxHeight",

    sortOrder: {
      name: "Date_Purchased",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "proxy_id",
      label: "Writer id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Proxy Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "proxy_type",
      label: "Proxy Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "broker_commission",
      label: "Broker Commission",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "broker_commission_amt",
      label: "Broker Commission amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "quoted_amt",
      label: "Quoted amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sys_commission",
      label: "System Commission",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sys_commission_amt",
      label: "System commission amount",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 15,
        }}
      >
        <BiArrowBack
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          size={50}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card className="TopCards card">
            <CardContent>
              <div>
                <p className="TopCardsText">Most staked game</p>
                <p style={{ position: "relative", left: 40, fontSize: 35 }}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ marginTop: 9, marginRight: 5 }}
                    />
                  ) : (
                    <p>Bingo Bash</p>
                  )}
                </p>
              </div>
            </CardContent>
          </Card>

          <Card className="TopCards card">
            <CardContent>
              <div>
                <p className="TopCardsText">Total Stakes</p>
                <p style={{ position: "relative", left: 40, fontSize: 35 }}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ marginTop: 9, marginRight: 5 }}
                    />
                  ) : (
                    400
                  )}
                </p>
              </div>
            </CardContent>
          </Card>

          <Card className="TopCards card">
            <CardContent>
              <div>
                <p className="TopCardsText">Total Commission(GHS)</p>
                <p style={{ position: "relative", left: 40, fontSize: 35 }}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ marginTop: 9, marginRight: 5 }}
                    />
                  ) : (
                    50000
                  )}
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div style={{}}>
        {loading ? (
          <Spinner />
        ) : (
          <MUIDataTable
            style={{ marginTop: 100 }}
            title={"Stakes"}
            data={[]}
            columns={columns}
            options={options}
          />
        )}
      </div>
    </div>
  );
};

export default WriterAnalytic;
