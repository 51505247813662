import React from 'react';
import Card from 'react-bootstrap/Card';
import Tile from './ImgTile';

const ProductCard = ({
  productName,
  productImage,
  // gameDay,
  // productType,
  startDay,
  endDay,
}) => {
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  };

  let start = new Date(startDay);
  let end = new Date(endDay);

  return (
    <Card bg="#FFFFFF" style={{ borderRadius: 15, width: 325, height: 250 }}>
      {<Tile productImage={productImage} />}

      <Card.Body style={{ position: 'relative', top: -5 }}>
        <Card.Title
          style={{
            fontSize: 16,
            fontWeight: 'bold',
          }}
        >
          {productName}
        </Card.Title>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ textTransform: 'capitalize' }}>
            Started On: {start.toLocaleDateString('en-GB', options)}
          </p>
          <p style={{ textTransform: 'capitalize' }}>
            End On: {end.toLocaleDateString('en-GB', options)}
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
