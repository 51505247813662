import React from 'react';
import Select from 'react-select';

export function SmsForm({
  handleChange,
  values,
  setReciepientShow,
  hideReciepient,
  sendSms,
  setSelectedOption,
  loading,
  setSmsPlan,
  users,
  smsplan,
}) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',

        borderRadius: 5,
      }}
    >
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Sms Title
            <input
              onChange={handleChange}
              name="title"
              type="text"
              className="form-control"
              defaultValue={values.title}
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Set the sms type
            <select
              onChange={(e) => {
                setReciepientShow(e.target.value);
              }}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="1">All users</option>
              <option value="2">Selected users</option>
            </select>
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Sms Body (less than 180 words)
            <textarea
              onChange={handleChange}
              defaultValue={values.body}
              name="body"
              cols="40"
              rows="5"
              className="form-control"
            />
          </label>
        </div>

        {hideReciepient ? (
          <div className="form-group ">
            <label htmlFor="contact" className="font-loader">
              Choose Contact to send sms
              <span style={{ color: 'red' }}> *</span>
              <Select
                id="contact"
                onChange={setSelectedOption}
                name="phone"
                isMulti
                options={users}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </label>
          </div>
        ) : null}

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Sms Plan
            <select
              onChange={(e) => {
                setSmsPlan(e.target.value);
              }}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="1">Send Now</option>
              <option value="2">Schedule Sms</option>
            </select>
          </label>
        </div>

        {smsplan === '2' && (
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Schedule Date
              <input
                onChange={handleChange}
                name="date"
                type="date"
                className="form-control"
                defaultValue={values.title}
              />
            </label>
          </div>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            sendSms();
          }}
          className="btn btn-primary form-control"
        >
          {loading ? 'Loading...' : 'Send SMS'}
        </button>
      </form>
    </div>
  );
}

export function SendEmail({
  handleChange,
  values,
  setReciepientShow,
  hideReciepient,
  sendSms,
  loading,
}) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',

        borderRadius: 5,
      }}
    >
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Email Subject
            <input
              onChange={handleChange}
              name="title"
              type="text"
              className="form-control"
              defaultValue={values.title}
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Set the reciepient type
            <select
              onChange={(e) => {
                setReciepientShow(e.target.value);
              }}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="1">All users</option>
              <option value="2">Selected users</option>
            </select>
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Sms Body (less than 180 words)
            <textarea
              onChange={handleChange}
              defaultValue={values.body}
              name="body"
              cols="40"
              rows="5"
              className="form-control"
            />
          </label>
        </div>

        {hideReciepient ? (
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Enter reciepient email
              <input
                onChange={handleChange}
                type="text"
                className="form-control"
                name="phone"
                defaultValue={values.phone}
              />
            </label>
          </div>
        ) : null}

        {/* <div class="form-group">
         <SearchComponent data={users.users} />
        </div> */}

        <button
          onClick={(e) => {
            e.preventDefault();
            sendSms();
          }}
          className="btn btn-primary form-control"
        >
          {loading ? 'Loading...' : 'Send SMS'}
        </button>
      </form>
    </div>
  );
}
