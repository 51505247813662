import React, { useState, useEffect } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite/no-important";
import MiniCardComponent from "./MiniCardComponent";
import TodayTrendsComponent from "./TodayTrendsComponent";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_TOTAL_AGENTS,
  GET_TOTAL_WRITERS,
  GET_TOTAL_CUSTOMERS,
  GET_ALL_WINNERS_PAYOUT,
  GET_ALL_AGENT_COMMISSION,
  GET_TODAY_WINNING_NUMBERS,
  GET_TODAY_WINNERS,
  MONTHLYWINNING_GRAPH_DATA,
  GET_GAME_OF_DAY_NEW,
  MONTHLY_PAYOUT_GRAPH,
  GET_TOTAL_STAKES_REPORT,
  GETLASTGAME,
} from "../../Graphql/Queries";
import Spinner from "../../components/Spinner";
import { getToday } from "../../utils";
import MostStakedNumber from "./MostStaked/MostStakedComponent";
import { formatAmount } from "../../utils";
import { ErrorNotification } from "../../components/Notification";

const styles = StyleSheet.create({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
  },
  cardRow: {
    display: "grid",
    marginTop: 30,
    "@media (min-width: 400px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media (min-width: 600px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    "@media (min-width: 900px)": {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  spinnerContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },

  miniCardContainer: {
    flexGrow: 1,
    marginRight: 30,
    "@media (max-width: 768px)": {
      marginTop: 30,
      maxWidth: "none",
    },
  },
  todayTrends: {
    marginTop: 30,
  },
  lastRow: {
    marginTop: 30,
  },
  unresolvedTickets: {
    marginRight: 30,
    "@media (max-width: 1024px)": {
      marginRight: 0,
    },
  },
  tasks: {
    marginTop: 0,
    "@media (max-width: 1024px)": {
      marginTop: 30,
    },
  },
});

/** Helper Method to convert date object to timestamp for GraphQL query */
const convertToTimeStamp = (date) => {
  let newDate = new Date(date);
  return newDate.toISOString();
};

const getDayOfWeek = () => {
  const dayOfWeekName = new Date().toLocaleString("default", {
    weekday: "long",
  });
  let f = dayOfWeekName.substring(0, 3);
  return f.toLowerCase();
}; 

const ContentComponent = () => {
  const [agents, setAgents] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [winningNumbers, setwinningNumbers] = useState(null);
  const [totalpayouts, setTotalPayouts] = useState(0);
  const [agentCommission, setagentCommission] = useState(0);
  const [today_game, setTodayGame] = useState(null);
  const [total_winners, setTotalWinners] = useState(0);
  const [winnersGrapgh, setWinnersGraph] = useState(null);
  const [payoutGrapgh, setpayoutGraph] = useState(null);
  const [grapghType, setGrapghType] = useState("winnings");
  const [product1_id, setProduct1Id] = useState("");
  const [product2_id, setProduct2Id] = useState("");
  const [lastgame_id, setLastGameId] = useState("");
  const [lastgame2_id, setLastGame2Id] = useState("");
  const [writer_count, setWriterCount] = useState(null);

  const [getStakeAggregates, { data: stakesAggregate }] = useLazyQuery(
    GET_TOTAL_STAKES_REPORT,
    {},
  );

  const [getStakeAggregates2, { data: stakesAggregate2 }] = useLazyQuery(
    GET_TOTAL_STAKES_REPORT,
    {},
  );

  const { loading: agentsLoading } = useQuery(GET_TOTAL_WRITERS, {
    variables: {
      status: "active",
    },

    onCompleted: (data) => {
      setWriterCount(data.writer_aggregate.aggregate.count);
    },

    onError: () => {
      ErrorNotification("Failed to load number of writers");
    },
  });

  // const { loading: agentsLoading } = useQuery(GET_TOTAL_AGENTS, {
  //   variables: {
  //     customer_type: "agent",
  //   },

  //   onCompleted: (data) => {
  //     setAgents(data.customer_aggregate.aggregate.count);
  //   },

  //   onError: () => {
  //     ErrorNotification("Failed to load number of agents");
  //   },
  // });

  const { data: totalCustomersData, loading: customersLoading } = useQuery(
    GET_TOTAL_CUSTOMERS,
    {
      variables: {
        customer_type: "customer",
      },
      onCompleted: (result) => {
        if (result) {
          setCustomers(result.customer_aggregate.aggregate.count);
        }
      },
    },
  );

  const { data: payoutGrapghData, loading: payoutGrapghLoading } = useQuery(
    MONTHLY_PAYOUT_GRAPH,
    {
      variables: {
        year: "2023",
      },
      onCompleted: (result) => {
        if (result) {
          let _data = [];
          const keys = Object.keys(result);

          keys.forEach((key) => {
            let value = result[key].aggregate.sum.total_payout;
            let obj = {
              x: key,
              y: value == null ? 0 : value,
            };

            _data.push(obj);
          });

          setpayoutGraph(_data);
        }
      },
    },
  );

  const { data: lastgame } = useQuery(GETLASTGAME, {
    onCompleted: (data) => {
      if (data) {
        console.log('Last Game ID', data)
        setLastGameId(data.game[0].product.id);
      }
    },
  });

  // const { data: lastgame2 } = useQuery(GETLASTGAME, {
  //   onCompleted: (data) => {
  //     if (data) {
  //       console.log('Last Game 2 ID', data)
  //       setLastGame2Id(data.game[1].product.id);
  //     }
  //   },
  // });
  const [event1_code, setEvent1Code] = useState("000");
  const [event2_code, setEvent2Code] = useState("000");
  const { data: TodayGame, loading: todayGameLoading } = useQuery(
    GET_GAME_OF_DAY_NEW,
    {
      variables: {
        schedule_day: [getDayOfWeek().toString()],
      },
      onCompleted: (result) => {
        if (result) {
          if (result.game.length > 0) {
            setProduct1Id(result.game[0].product.id);
            localStorage.setItem(
              "today_game_product_id",
              result.game[0].product.id,
            );

            if (result.game.length > 1) {
              const prod2Id = result.game[1].product.id;
              setProduct2Id(prod2Id);
              localStorage.setItem("today_game_product2_id", prod2Id);
            }
            console.log('On Complete Game', result.game)
            
            setTodayGame(result.game);
            // setEvent1Code(today_game[0]?.event_code)
            setEvent1Code(result.game[0]?.event_code)
            setEvent2Code(result.game[1]?.event_code)
          }
        }
      },
    },
  );

  const { loading: winnerGrapghLoading } = useQuery(MONTHLYWINNING_GRAPH_DATA, {
    variables: {
      year: "2023",
    },
    onCompleted: (result) => {
      if (result) {
        let _data = [];
        const keys = Object.keys(result);

        keys.forEach((key) => {
          let value = result[key].aggregate.sum.total_won;
          let obj = {
            x: key,
            y: value == null ? 0 : value,
          };

          _data.push(obj);
        });

        setWinnersGraph(_data);
      }
    },
  });

  const { loading: payoutLoading } = useQuery(GET_ALL_WINNERS_PAYOUT, {
    onCompleted: (result) => {
      if (result) {
        if (result.winning_claim_aggregate.aggregate.sum.amount == null) {
          setTotalPayouts(0);
        } else {
          setTotalPayouts(result.winning_claim_aggregate.aggregate.sum.amount);
        }
      }
    },
  });

  const { loading: CommissionLoading } = useQuery(GET_ALL_AGENT_COMMISSION, {
    onCompleted: (result) => {
      if (result) {
        if (
          result.agent_commission_payment_aggregate.aggregate.sum.amount == null
        ) {
          setagentCommission(0);
        } else {
          setagentCommission(
            result.agent_commission_payment_aggregate.aggregate.sum.amount,
          );
        }
      }
    },
  });

  const { loading: WinningLoading } = useQuery(GET_TODAY_WINNING_NUMBERS, {
    variables: {
      gameDay: getToday(),
    },
    onCompleted: (result) => {
      if (result) {
        if (result.game.length > 0) {
          setwinningNumbers(result.game[0].results);
        }
      }
    },
  });

  const { loading: totalwinnersloading } = useQuery(GET_TODAY_WINNERS, {
    variables: {
      date: getToday(),
    },
    onCompleted: (data) => {
      if (data) {
        setTotalWinners(data.stake_aggregate.aggregate.count);
      }
    },
  });

  const handleChange = (value) => {
    setGrapghType(value);
  };
  
  useEffect(() => {
    let currentDate = new Date();
    let yesterday = new Date(currentDate);

    let yestyMinusDate = convertToTimeStamp(
      yesterday.setDate(yesterday.getDate() - 1),
    );

    let yestyPlusDate = convertToTimeStamp(
      yesterday.setDate(yesterday.getDate() + 1),
    );
    
    getStakeAggregates({
      variables: {
        _startDate: yestyMinusDate,
        _endDate: yestyPlusDate,
        _productId: product1_id?.length === 0 ? lastgame_id : product1_id,
      },
    });

    getStakeAggregates2({
      variables: {
        _startDate: yestyMinusDate,
        _endDate: yestyPlusDate,
        _productId: product2_id?.length === 0 ? lastgame_id : product2_id,
      },
    });
    // console.log("DET2", product2_id, yestyMinusDate, yestyPlusDate)
  }, [product2_id, lastgame2_id]);

  useEffect(() => {}, [grapghType]);

  useEffect(() => {
    if (TodayGame) {
      if (TodayGame.game.length > 0) {
        const prod1Id = TodayGame.game[0].product.id;
        setProduct1Id(prod1Id);
        localStorage.setItem("today_game_product_id", prod1Id);

        if (TodayGame.game.length > 1) {
          const prod2Id = TodayGame.game[1].product.id;
          setProduct2Id(prod2Id);
          localStorage.setItem("today_game_product2_id", prod2Id);
        }
        console.log("GEN PROD ID", TodayGame.game)
        setTodayGame(TodayGame.game);
      }
    }
  }, [TodayGame]);

  const commonStatsData = [
    {
      id: 1,
      title: "Writers",
      value: writer_count,
    },
    // {
    //   id: 1,
    //   title: "Agents",
    //   value: agents,
    // },
    {
      id: 2,
      title: "Clients/Customers",
      value: customers,
    },
    {
      id: 3,
      title: "Winners total payouts",
      value: formatAmount(totalpayouts),
    },
    {
      id: 4,
      title: "Agent Commision payouts",
      value: formatAmount(agentCommission),
    },
  ];

  
  const dashboardData = [
    {
      id: 5,
      title: `Paid Stakes Count (${event1_code})`,
      value: stakesAggregate?.paid_stakes?.aggregate?.count
        ? stakesAggregate?.paid_stakes?.aggregate?.count
        : 0,
    },
    {
      id: 6,
      title: "Paid Stakes Total Amt",
      value: formatAmount(
        stakesAggregate?.paid_stakes?.aggregate?.sum?.staked_amount
          ? stakesAggregate?.paid_stakes?.aggregate?.sum?.staked_amount
          : 0,
      ),
    },
    {
      id: 7,
      title: "Attempted Stakes Count",
      value: stakesAggregate?.attempted_stakes?.aggregate?.count
        ? stakesAggregate?.attempted_stakes?.aggregate?.count
        : 0,
    },
    {
      id: 8,
      title: "Attempted Stakes Total Amt",
      value: formatAmount(
        stakesAggregate?.attempted_stakes?.aggregate?.sum?.staked_amount
          ? stakesAggregate?.attempted_stakes?.aggregate?.sum?.staked_amount
          : 0,
      ),
    },
  ];

  if(stakesAggregate2) {
    dashboardData.push({
        id: 5,
        title: `Paid Stakes Count (${event2_code})`,
        // title: "Paid Stakes Count | \n" + stakesAggregate2?.product_data[0].name,
        value: stakesAggregate2?.paid_stakes?.aggregate?.count
          ? stakesAggregate2?.paid_stakes?.aggregate?.count
          : 0,
      },)
    
      dashboardData.push(
        {
          id: 6,
          title: "Paid Stakes Total Amt",
          value: formatAmount(
            stakesAggregate2?.paid_stakes?.aggregate?.sum?.staked_amount
              ? stakesAggregate2?.paid_stakes?.aggregate?.sum?.staked_amount
              : 0,
          ),
        },
        {
          id: 7,
          title: "Attempted Stakes Count",
          value: stakesAggregate2?.attempted_stakes?.aggregate?.count
            ? stakesAggregate2?.attempted_stakes?.aggregate?.count
            : 0,
        },
        {
          id: 8,
          title: "Attempted Stakes Total Amt",
          value: formatAmount(
            stakesAggregate2?.attempted_stakes?.aggregate?.sum?.staked_amount
              ? stakesAggregate2?.attempted_stakes?.aggregate?.sum?.staked_amount
              : 0,
          ),
        }
      )

  }
  
  return (
    <Column>
      {agentsLoading ||
      customersLoading ||
      CommissionLoading ||
      payoutLoading ||
      todayGameLoading ||
      winnerGrapghLoading ||
      payoutGrapghLoading ||
      WinningLoading ||
      totalwinnersloading ? (
        <Spinner className={css(styles.spinnerContainer)} />
      ) : (
        <>
          <Row
            className={css(styles.cardsContainer)}
            wrap
            flexGrow={1}
            horizontal="space-between"
            breakpoints={{ 768: "column" }}
          >
            <div
              className={css(styles.cardRow)}
              wrap
              flexGrow={1}
              horizontal="space-between"
              breakpoints={{ 384: "column" }}
            >
              {commonStatsData.map((item, key) => (
                <MiniCardComponent
                  key={key}
                  className={css(styles.miniCardContainer)}
                  title={item.title}
                  value={item.value}
                />
              ))}

              {dashboardData.map((item, key) => (
                <MiniCardComponent
                  key={key}
                  className={css(styles.miniCardContainer)}
                  title={item.title}
                  value={item.value}
                />
              ))}
            </div>
          </Row>
          {winnersGrapgh !== null && payoutGrapghData !== null ? (
            <div className={css(styles.todayTrends)}>
              <TodayTrendsComponent
                winningNumbers={winningNumbers}
                todayGame={today_game}
                handleChange={handleChange}
                // data={grapghType==="winnings" ? winnersGrapgh : payoutGrapgh }
                data={winnersGrapgh}
                payoutdata={payoutGrapgh}
                todayWinners={total_winners}
              />
            </div>
          ) : null}

          <Row className={css(styles.lastRow)}>
            {today_game ? <MostStakedNumber game={today_game[0].id} /> : null}
          </Row>
        </>
      )}
    </Column>
  );
}

export default ContentComponent;
