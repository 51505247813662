import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import { Line } from 'react-chartjs-2';

export const formatDateFull = (date) => {
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };
  let dateToFormat = new Date(date);
  let formmateddate = dateToFormat.toLocaleDateString('en-GB', options);
  return formmateddate;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DFE0EB',
    borderRadius: 4,
    cursor: 'pointer',
  },
  graphContainer: {
    marginTop: 24,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  graphSection: {
    padding: 24,
  },
  graphSubtitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: '#9FA2B4',
    marginTop: 4,
    marginRight: 8,
  },
  graphTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 19,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#252733',
  },
  legendTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.1px',
    color: '#9FA2B4',
    marginLeft: 8,
  },
  separator: {
    backgroundColor: '#DFE0EB',
    width: 1,
    minWidth: 1,
  },
  statContainer: {
    borderBottom: '1px solid #DFE0EB',
    padding: '24px 32px 24px 32px',
    height: 'calc(114px - 48px)',
    ':last-child': {
      border: 'none',
    },
  },
  stats: {
    borderTop: '1px solid #DFE0EB',
    width: '100%',
  },
  statTitle: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    color: '#9FA2B4',
    whiteSpace: 'nowrap',
    marginBottom: 6,
  },
  statValue: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.3px',
    textAlign: 'center',
    color: '#252733',
  },
});

class WriterTodayTrendsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();

    this.state = {
      graphtype: 'winnings',

      line_data: {
        labels: [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
        ],
        datasets: [
          {
            label: 'Dataset',
            data: this.props?.data,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      },
      reportTypes: [
        { id: 'winnings', report_item: 'Winnings' },
        { id: 'payout', report_item: 'Payouts' },
      ],

      dashboardData: [
        {
          id: 1,
          title: 'Current Winning numbers',
          value: this.props.winningNumbers
            ? this.props.winningNumbers?.toString()
            : 'No winning numbers',
        },
        {
          id: 2,
          title: 'Today Game',
          value: this.props.todayGame
            ? this.props.todayGame[0]?.product.name +
              ` (${this.props.todayGame[0]?.event_code})`
            : 'No game today',
        },
        // {
        //     id: 3,
        //     title: "Total winners today",
        //     value: this.props.todayWinners,
        // },
      ],
    };
  }

  changedata(graphtype) {
    const chart = this.chartReference.current.chartInstance;
    if (graphtype === 'winnings') {
      chart.data.datasets[0].data = this.props.data;
      chart.update();
    } else {
      // console.log("I was called");
      chart.data.datasets[0].data = this.props.payoutdata;
      chart.update();
    }
  }

  renderLegend(color, title) {
    return (
      <Row vertical="center">
        <div
          style={{ width: 16, border: '2px solid', borderColor: color }}
        ></div>

        <span className={css(styles.legendTitle)}>{title}</span>
      </Row>
    );
  }

  renderStat(title, value) {
    return (
      <Column
        flexGrow={1}
        className={css(styles.statContainer)}
        vertical="center"
        horizontal="center"
      >
        <span className={css(styles.statTitle)}>{title}</span>
        <span className={css(styles.statValue)}>{value}</span>
      </Column>
    );
  }

  render() {
    return (
      <Row
        flexGrow={1}
        className={css(styles.container)}
        horizontal="center"
        breakpoints={{ 1024: 'column' }}
      >
        <Column
          wrap
          flexGrow={7}
          flexBasis="735px"
          className={css(styles.graphSection)}
          breakpoints={{
            1024: { width: 'calc(100% - 48px)', flexBasis: 'auto' },
          }}
        >
          <Row wrap horizontal="space-between">
            <Column>
              <span className={css(styles.graphTitle)}>
                Best Performing Writers
              </span>
              <span className={css(styles.graphSubtitle)}>
                {formatDateFull(new Date())}
              </span>
            </Column>
          </Row>
          <div className={css(styles.graphContainer)}>
            <Line ref={this.chartReference} data={this.state.line_data} />
          </div>
        </Column>
        <Column
          className={css(styles.separator)}
          breakpoints={{ 1024: { display: 'none' } }}
        >
          <div />
        </Column>
        <Column
          flexGrow={3}
          flexBasis="342px"
          breakpoints={{ 1024: css(styles.stats) }}
        >
          {this.state.dashboardData.map((item, key) => (
            <Column
              key={key}
              flexGrow={1}
              className={css(styles.statContainer)}
              vertical="center"
              horizontal="center"
            >
              <span className={css(styles.statTitle)}>{item.title}</span>
              <span className={css(styles.statValue)}>{item.value}</span>
            </Column>
          ))}
        </Column>
      </Row>
    );
  }
}

export default WriterTodayTrendsComponent;
