import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {
  SuccessNotification,
  ErrorNotification,
} from "../components/Notification";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { LOGINUSER } from "../Graphql/Queries";
const Login = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  let isAuthenticated = false;
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    phone_number: "",
    otp: "",
    otp_hashed: "",
    otp_expire_at: 0,
  });

  const onChange = (e) => {
    isOTPRequested
      ? setAdmin({ ...admin, [e.target.name]: e.target.value })
      : setAdmin({ ...admin, [e.target.name]: Number(e.target.value) });
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const logAdminIn = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post("/auth/admins/v1/signin", admin);
      SuccessNotification("OTP has been sent to phone number");
      setIsOTPRequested(true);
      setLoading(false);

      setAdmin({
        ...admin,
        otp_hashed: res?.data?.data?.otp_hashed,
        otp_expire_at: res?.data?.data?.expires_at,
      });
    } catch (error) {
      setLoading(false);
      ErrorNotification(error?.response?.data?.message);
    }
  };

  const getUserDetails = async () => {
    try {
      const token = localStorage.getItem("accesstoken");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await axiosInstance.get("/admin/me/info", {
        headers,
      });

      // console.log("Get user details");

      localStorage.setItem("adminId", res.data.data.id);

      // localStorage.setItem("tokenStatusCode", res.status);

      // history.push("/admin/dashboard");

      localStorage.setItem("tokenStatusCode", res.status);
      localStorage.setItem("admin_id", res?.data?.data?.id);

      // console.log("Moving to Dashboard");  
      navigate("/admin");
    } catch (error) {
      localStorage.setItem("tokenStatusCode", error.response.status);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post("/auth/admins/v1/signin", admin);

      console.log(res.data);
      SuccessNotification("Login Successful");
      localStorage.setItem("accesstoken", res.data.access_token);
      localStorage.setItem("customer_id", res.data.id);
      localStorage.setItem("usertype", "admin");
      getUserDetails();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorNotification(error.response.data.message);
      console.log(error);
    }
  };

  const [LoginUser] = useLazyQuery(LOGINUSER, {
    onCompleted: (data) => {
      console.log("ADMIN", data.admin);
      SuccessNotification("Successfully login");
      localStorage.setItem("token", data.admin[0].id);
      localStorage.setItem("role", data.admin[0].role.code);

      // history.push('/admin/dashboard')
    },
    onError: (error) =>
      ErrorNotification("An error occurred when logging in:" + error),
  });

  useEffect(() => {
    if (isAuthenticated) {
      // localStorage.setItem('auth0_id', user.sub)
      login();
    }
  }, [isAuthenticated]);

  const login = () => {
    try {
      LoginUser({
        variables: {
          user_id: localStorage.getItem("customer_id"),
        },
      });
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  return (
    <div className="main-container">
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Welcome back, admin</h1>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      {/* <div className="text-center">
										<img src="img/avatars/avatar.jpg" alt="Charles Hall" className="img-fluid rounded-circle" width="132" height="132" />
									</div> */}
                      <form data-testid="login-form" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <div>
                          <label className="form-label">
                            Phone Number
                            <input
                              onChange={onChange}
                              className="form-control form-control-lg"
                              type="text"
                              name="phone_number"
                              value={admin.phone_number}
                              data-testid="phoneNumber"
                            />
                          </label>
                          {/* <small>
                            <a href="index.html">Forgot password?</a>
                          </small> */}
                        </div>
                        {isOTPRequested ? (
                          <div className="mb-3">
                            <label className="form-label">
                              OTP
                              <input
                                onChange={onChange}
                                className="form-control form-control-lg"
                                type="text"
                                name="otp"
                                value={admin.otp}
                                data-testid="otpField"
                              />
                            </label>
                            {/* <small>
                            <a href="index.html">Forgot password?</a>
                          </small> */}
                          </div>
                        ) : null}
                        <div className="text-center mt-3">
                          {isOTPRequested ? (
                            <button
                              data-testid="verifyOtpBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                // loginWithRedirect()
                                verifyOTP();
                              }}
                              className="btn btn-lg btn-primary"
                            >
                              {/* {RequestLoading ? <Spinner animation="grow"/> : "Login" } */}
                              {loading ? "Verifying..." : " Verify OTP"}
                            </button>
                          ) : (
                            <button
                              data-testid="loginBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                // loginWithRedirect()
                                logAdminIn();
                              }}
                              className="btn btn-lg btn-primary"
                            >
                              {/* {RequestLoading ? <Spinner animation="grow"/> : "Login" } */}
                              {loading ? "logging in..." : "Login to continue"}
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    {/* <div style={{ textAlign: "center", marginTop: 20 }}>
                        <NavLink to="/register">
                          Dont have an account yet? Register
                        </NavLink>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
