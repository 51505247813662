import React, { useState } from 'react';
import './auth.css';
// import { useHistory } from 'react-router-dom';
import {
	SuccessNotification,
	ErrorNotification,
} from '../components/Notification';
import axios from 'axios';
import Logo from '../assets/megLogo.png';
import { useNavigate } from "react-router-dom";

function WriterLogin() {
	const [password, setPassword] = useState('');
	const [phone, setPhone] = useState('');
	// const history = useHistory();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const submitrequest = async (evt) => {
		evt.preventDefault();
		setLoading(true);

		let data = {
			phone_number: `233${phone.slice(1)}`,
			platform: 'web',
			user_type: 'writer',
			password: password,
		};

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_BASE_URL}api/v1/writer/auth/signin`,
				data,
			);

			setLoading(false);
			SuccessNotification('Login Successful');
			localStorage.setItem('accesstoken', response.data.access_token);
			localStorage.setItem('customer_data', JSON.stringify(response.data.data));
			localStorage.setItem('usertype', 'writerfm');
			localStorage.setItem('tokenStatusCode', 200);
			navigate('/admin/dashboard');
		} catch (error) {
			setLoading(false);
			ErrorNotification(error.response.data.message);
		}
	};

	return (
		<div className="main-container">
			<main className="d-flex w-100">
				<div className="container d-flex flex-column">
					<div className="row vh-100">
						<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
							<div className="d-table-cell align-middle">
								{/* {isOTPRequested ? null : <div className="text-center mt-4">
                                    <h1 className="h2">Login to your account</h1>
                                </div>} */}

								<div className="text-center mt-8 mb-4">
									<img src={Logo} alt="Charles Hall" />
								</div>

								<div className="card">
									<div className="card-body">
										<div className="m-sm-4">
											<form>
												<div
													style={{ display: 'flex', flexDirection: 'column' }}
												>
													<div className="mb-3">
														<label className="form-label">
                              Phone Number
															<input
																onChange={(e) => setPhone(e.target.value)}
																className="form-control form-control-lg"
																type="number"
																name="number"
															/>
														</label>
													</div>
													<div className="mb-3">
														<label className="form-label">
                              Password
															<input
																onChange={(e) => setPassword(e.target.value)}
																className="form-control form-control-lg"
																type="password"
																name="password"
															/>
														</label>

														<span className="float-right mt-1 text-sm">
                              Forgot Password?
														</span>
													</div>
												</div>

												<div
													className="mt-4"
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<button
														onClick={(e) => submitrequest(e)}
														className="btn btn-lg"
														style={{
															width: '12rem',
															fontSize: 16,
															fontWeight: 'bolder',
															backgroundColor: '#FF7300',
															color: 'white',
															borderRadius: 15,
														}}
													>
														{loading ? 'Loading...' : 'Login'}
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}

export default WriterLogin;
