import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminModal from '../../../components/Modal';
import MessageDetail from './MessageDetail';
import { useQuery } from '@apollo/client/react';
// import { SMS_LOG } from "../../Graphql/Mutations";
import { GET_SMS_LOG } from '../../../Graphql/Queries';
import { formatDateFull } from '../../../utils';
import { IoPencilOutline } from 'react-icons/io5';
import { RiMailSendLine } from 'react-icons/ri';
import { IoTrashOutline } from 'react-icons/io5';
import Spinner from '../../../components/Spinner';
import './styles.css';

function isInThePast(date) {
	const today = new Date();

	today.setHours(0, 0, 0, 0);

	return date < today;
}

const TableList = () => {
	const [showDetailModal, setShowMDetailModal] = useState(false);
	const handleclose2 = () => setShowMDetailModal(false);
	const [rawData, setRawData] = useState();
	const [smsData, setsmsData] = useState();
	const [detail, setDetail] = useState('');

	const columns = [
		{
			name: 'id',
			label: 'id',
			options: {
				filter: true,
				sort: true,
				display: false,
			},
		},
		{
			name: 'title',
			label: 'Sms Title',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'message',
			label: 'Message',

			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<div style={{ width: '100%' }}>
							{val.length > 60 ? val.substring(0, 50) + '...' : val}
						</div>
					);
				},
			},
		},
		{
			name: 'sent_by',
			label: 'Scheduled BY',
			options: {
				filter: true,
				sort: false,
			},
		},

		{
			name: 'schedule_date',
			label: 'Schedule Date',
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: 'status',
			label: 'Status',

			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<div>
							{isInThePast(new Date(val)) === true ? (
								<div className="  mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center">
									<p className="text-orange">{'Date Overdue'}</p>
								</div>
							) : (
								<div className="py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center">
									<p className="text-green">{'Pending'}</p>
								</div>
							)}
						</div>
					);
				},
			},
		},
		{
			name: 'Actions',
			label: 'Actions',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<div>
							<IoPencilOutline
								size={15}
								className="mr-3 action-buttons"
								onClick={() => handleRowClick(val)}
								style={{ cursor: 'pointer' }}
							/>
							<RiMailSendLine
								size={15}
								className="mr-3 action-buttons"
								onClick={() => alert('mail clicked')}
							/>
							<IoTrashOutline
								size={15}
								className="mr-3 action-buttons"
								color="red"
								onClick={() => alert('trashed clicked')}
							/>
						</div>
					);
				},
			},
		},
	];

	const handleRowClick = (rowData) => {
		let chosenItem = finobject(rawData, rowData.id);
		setDetail(chosenItem);
		setShowMDetailModal(true);
	};

	const { loading: RequestLoading } = useQuery(GET_SMS_LOG, {
		onCompleted: (data) => {
			let tabledata = [];
			setRawData(data.sms_log);
			for (var i = 0; i < data.sms_log.length; i++) {
				if (data.sms_log[i].status === 'SCHEDULED') {
					let c = {
						title: data.sms_log[i].title,
						id: data.sms_log[i].id,
						status: data.sms_log[i].schedule_date,
						sent_date: formatDateFull(data.sms_log[i].sent_date),
						sent_by: data.sms_log[i].sender.name,
						message: data.sms_log[i].message,
						Actions: data.sms_log[i],
						sent_to:
              data.sms_log[i].recipient.customers.length + ' customer(s)',
						schedule_date: formatDateFull(data.sms_log[i].schedule_date),
					};

					tabledata.push(c);
				}
			}
			setsmsData(tabledata);
		},
	});

	const finobject = (data, id) => {
		let y = data.find((x) => x.id === id);
		return y;
	};

	const options = {
		filterType: 'checkbox',
		sortOrder: {
			name: 'Date_Purchased',
			direction: 'desc',
		},
	};

	return (
		<div style={{ padding: 20 }}>
			<AdminModal
				show={showDetailModal}
				close={!showDetailModal}
				title={'Message details'}
				body={<MessageDetail detail={detail} />}
				handleClose={handleclose2}
			/>
			{RequestLoading ? (
				<div
					style={{
						height: '100vh',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner />
				</div>
			) : (
				<MUIDataTable
					data={smsData}
					title={'Scheduled Sms List'}
					columns={columns}
					options={options}
					// onRowClick={handleRowClick}
				/>
			)}
		</div>
	);
};

export default TableList;
