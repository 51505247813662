import React, { useState } from 'react';
// import {  NavLink } from 'react-router-dom';
import { useNavigate, NavLink  } from "react-router-dom";
import { CREATE_ADMIN } from '../Graphql/Mutations';
import { useMutation } from '@apollo/client';
import {
	SuccessNotification,
	ErrorNotification,
} from '../components/Notification';
import { Spinner } from 'react-bootstrap';
import bcrypt from 'bcryptjs';

function Register() {
	const [email, setEmail] = useState('');
	const [fullname, setFullName] = useState('');
	const [password, setPassword] = useState('');
	const [phone, setPhone] = useState('');
	const [confirmpassword, setConfirmPassword] = useState('');
	let isOTPRequested = false;
	let loading = false;
	// const history = useHistory();
	const navigate = useNavigate();

	const [RegisterUser] = useMutation(CREATE_ADMIN, {
		onCompleted: () => {
			SuccessNotification('You have successfully created an account');
			navigate('/login');
		},
		onError: (error) => ErrorNotification(error),
	});

	const hashpassword = (password) => {
		var salt = bcrypt.genSaltSync(10);
		var hash = bcrypt.hashSync(password, salt);
		return hash;
	};

	const submitrequest = (evt) => {
		evt.preventDefault();
		if (password !== confirmpassword) {
			ErrorNotification('Passwords do not match');
		} else {
			try {
				RegisterUser({
					variables: {
						name: fullname,

						email: email,

						phone: phone,

						password: hashpassword(password),
					},
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<div>
			<main className="d-flex w-100">
				<div className="container d-flex flex-column">
					<div className="row vh-100">
						<div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
							<div className="d-table-cell align-middle">
								<div className="text-center mt-4">
									<h1 className="h2">Create an account</h1>
								</div>

								<div className="card">
									<div className="card-body">
										<div className="m-sm-4">
											{/* <div className="text-center">
										<img src="img/avatars/avatar.jpg" alt="Charles Hall" className="img-fluid rounded-circle" width="132" height="132" />
									</div> */}
											<form>
												{isOTPRequested ? (
													<div className="mb-3">
														<label className="form-label">
                              Enter OTP
															<input
																onChange={(e) => setPhone(e.target.value)}
																className="form-control form-control-lg custom-input"
																type="number"
																name="number"
															/>
														</label>
													</div>
												) : (
													<div>
														<div className="mb-3">
															<label className="form-label">
                                Full Name
																<input
																	onChange={(e) => setFullName(e.target.value)}
																	className="form-control form-control-lg"
																	type="text"
																	name="fullname"
																/>
															</label>
														</div>

														<div className="mb-3">
															<label className="form-label">
                                Email
																<input
																	onChange={(e) => setEmail(e.target.value)}
																	className="form-control form-control-lg"
																	type="email"
																	name="email"
																/>
															</label>
														</div>
														<div className="mb-3">
															<label className="form-label">
                                Phone Number
																<input
																	onChange={(e) => setPhone(e.target.value)}
																	className="form-control form-control-lg"
																	type="number"
																	name="number"
																/>
															</label>
														</div>
														<div className="mb-3">
															<label className="form-label">
                                Password
																<input
																	onChange={(e) => setPassword(e.target.value)}
																	className="form-control form-control-lg"
																	type="password"
																	name="password"
																/>
															</label>
														</div>
														<div className="mb-3">
															<label className="form-label">
																{' '}
                                Confirm Password
																<input
																	onChange={(e) =>
																		setConfirmPassword(e.target.value)
																	}
																	className="form-control form-control-lg"
																	type="password"
																	name="password"
																/>
															</label>
														</div>
														{isOTPRequested ? (
															<div
																className="mt-4"
																style={{
																	width: '100%',
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																<button
																	onClick={(e) => submitrequest(e)}
																	className="btn btn-lg btn-primary"
																>
																	{loading ? (
																		<Spinner animation="grow" />
																	) : (
																		'Verify OTP'
																	)}
																</button>
															</div>
														) : (
															<div
																className="mt-4"
																style={{
																	width: '100%',
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																<button
																	onClick={(e) => submitrequest(e)}
																	className="btn btn-lg"
																	style={{
																		width: '12rem',
																		fontSize: 16,
																		fontWeight: 'bolder',
																		backgroundColor: '#FF7300',
																		color: 'white',
																		borderRadius: 15,
																	}}
																>
																	{loading ? (
																		<Spinner animation="grow" />
																	) : (
																		'Login'
																	)}
																</button>
															</div>
														)}
													</div>
												)}
											</form>
										</div>
										<div style={{ textAlign: 'center', marginTop: 20 }}>
											<NavLink to="/login">
                        Already have an account? Login
											</NavLink>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}

export default Register;
