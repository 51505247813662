import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import Spinner from '../../components/Spinner';
import './index.css';
import { ErrorNotification } from '../../components/Notification';
import { AGENTCOMMISSIONSUMMARY } from '../../Graphql/Queries';
import { api } from '../../utils/Api';
import { SuccessNotification } from '../../components/Notification';
import AdminModal from '../../components/Modal';
import Swal from 'sweetalert2';
// import { ChequeDataComponent } from '../../components/chequeDataComponent'
import StakesDetailComponent from '../../components/StakesDetailComponent';

const AgentCommissionIndex = () => {
  let loading = false;
  const [winners, setWinners] = useState([]);
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [chosenNumber, setchosenNumber] = useState('');
  // const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedid, setSelectedId] = useState();

  const role = localStorage.getItem('role');

  const { refetch, data } = useQuery(AGENTCOMMISSIONSUMMARY);

  async function makepayment(number) {
    // setPaymentLoading(true);
    let body = '';

    try {
      const apiUrl = `api/v1/payments/agent/stake/update_old?phone_number=${number}`;

      api.post(apiUrl, body).then((res) => {
        if (res.status === 200) {
          // Handle a successful response here
          refetch();
          handleclose2();
          SuccessNotification('Agent commission paid successfully');
          // setPaymentLoading(false);
          setSelectedId(); // Make sure this function is correctly defined
        }
      });
    } catch (error) {
      // Handle any errors that occurred during the request
      handleclose2();
      // setPaymentLoading(false);
      ErrorNotification('An error occurred when making payment');
    } finally {
      // Code inside the finally block runs regardless of success or failure
      // You can add cleanup or other actions here
    }
  }

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    // onRowClick: handleRowClick,
    selectableRows: 'none',
    expandableRowsOnClick: true,
    isRowExpandable: () => {
      return true;
    },
  };

  const raiseAlert = (number) => {
    Swal.fire({
      title: 'Are you sure you want to make this payment',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,accept',
    }).then((result) => {
      if (result.isConfirmed) {
        //   console.log(paymentType)
        try {
          makepayment(number);
        } catch (error) {
          console.log(error);
          ErrorNotification('An error occured when creating payment');
        }
      }
    });
  };

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val}</p>;
        },
      },
    },
    {
      name: 'full_name',
      label: 'Full Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val && val}</p>;
        },
      },
    },

    {
      name: 'phone_number',
      label: 'Phone number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 10 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'num_stakes_total',
      label: 'Number of stakes',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'paid_amt_total',
      label: 'Total amount accrued (GHS)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val}</p>;
        },
      },
    },
    {
      name: 'commission_amt_20',
      label: 'Payable amount(GHS) unformatted',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    {
      name: 'commission_amt_20_fmt',
      label: 'Payable amount(GHS) 20% formatted',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },
    // {
    //     name: 'commission_amt_35',
    //     label: 'Payable amount(GHS) 35% unformatted',
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: val => {
    //             return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>
    //         }
    //     }
    // },
    {
      name: 'commission_amt_35_fmt',
      label: 'Payable amount(GHS) 35% formatted',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val && val}</p>;
        },
      },
    },

    {
      name: 'action',
      label: 'Actions',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                style={{ marginRight: 5 }}
                className="btn btn-outline-warning font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  setchosenNumber(tableMeta.rowData[2]);

                  setShowMDetailModal(true);
                }}
              >
                View details
              </button>

              {role === 'finance' ? (
                <button
                  style={{ marginRight: 5 }}
                  className="btn btn-outline-success font-loader"
                  disabled={selectedid === tableMeta.rowData[2]}
                  onClick={(e) => {
                    e.preventDefault();

                    setSelectedId(tableMeta.rowData[2]);
                    raiseAlert(tableMeta.rowData[2]);

                    // setSelectedBtnId(val);
                  }}
                >
                  {selectedid === tableMeta.rowData[2]
                    ? 'loading'
                    : 'Pay Agent'}
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setWinners(data.view_agent_unpaid_commissions);
    }
  }, [data]);

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Stake summary of agent'}
        body={
          <StakesDetailComponent
            number={chosenNumber}
            makepayment={makepayment}
            raiseAlert={raiseAlert}
          />
        }
        handleClose={handleclose2}
      />

      {loading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={winners}
          title="Agent Commission Summary"
          columns={columns}
          options={options}
          // onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default AgentCommissionIndex;
