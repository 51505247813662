import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { formatDateFull } from '../../utils';
import Spinner from '../../components/Spinner';
import { Button } from 'react-bootstrap';
import './index.css';
import { ErrorNotification } from '../../components/Notification';
import {
	GET_ALL_PRODUCTS_NAME,
	GET_AGENTS_TO_BE_PAID,
} from '../../Graphql/Queries';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { SuccessNotification } from '../../components/Notification';
import AgentExpandableTable from './AgentExpandableTable';
import AdminModal from '../../components/Modal';
import Swal from 'sweetalert2';
// import { ChequeDataComponent } from '../../components/chequeDataComponent'

const AgentPayoutIndex = () => {
	const [winners, setWinners] = useState([]);
	const [games, setGames] = useState([]);
	const [daily, setDaily] = useState(true);
	const [reportType, setReportType] = useState('Today Commission');
	const [gameType, setGameType] = useState('');
	const [maxselectedWinners, setMaxSelectedWinners] = useState([]);
	const [selectedWinners, setSelectedWinners] = useState();
	const [historyselectedWinners, sethistorySelectedWinners] = useState([]);
	const [initiatePayment, setInitiatePayment] = useState(false);
	const token = localStorage.getItem('accesstoken');
	const [paymentType, setPaymentType] = useState('momo');
	const [showDetailModal, setShowMDetailModal] = useState(false);
	const handleclose2 = () => setShowMDetailModal(false);

	const [state, setState] = useState({
		reference: '',
		account_number: '',
		bank_id: '',
	});

	function handleChange2(evt) {
		const value = evt.target.value;

		setState({
			...state,
			[evt.target.name]: value,
		});
	}

	const [
		getGameReportWithinRange,
		{ loading: gameDataWithinRangeLoading, refetch: searchrefetch },
	] = useLazyQuery(GET_AGENTS_TO_BE_PAID, {
		variables: {
			product_id: gameType,
		},

		onCompleted: (data) => {
			sethistorySelectedWinners(data.game);
		},

		onError: () => {
			ErrorNotification('Failed to load game report within specified range');
		},
	});

	const handleChange = (e) => {
		setReportType(e.target.value);
	};

	const handleGameSelect = (e) => {
		setGameType(e.target.value);
	};

	useQuery(GET_ALL_PRODUCTS_NAME, {
		onCompleted: (data) => {
			setGames(data.product);
		},
	});

	const { loading: todaywinningLoading, refetch: todayrefetch } = useQuery(
		GET_AGENTS_TO_BE_PAID,
		{
			variables: {
				product_id: localStorage.getItem('today_game_product_id'),
			},

			onCompleted: (data) => {
				setWinners(data.game);
			},

			onError: () => {
				ErrorNotification('Failed to load agents to be paid');
			},
		},
	);

	const options = {
		filter: true,
		filterType: 'dropdown',
		responsive: 'standard',
		expandableRows: true,
		expandableRowsHeader: false,
		selectableRows: 'none',
		expandableRowsOnClick: true,
		isRowExpandable: () => {
			return true;
		},

		renderExpandableRow: (rowMeta) => {
			let mywinners = winners[rowMeta.rowIndex].payable_game_agents;
			let game_id = winners[rowMeta.rowIndex].id;

			return (
				<TableRow>
					<TableCell colSpan={7}>
						<AgentExpandableTable
							rowData={mywinners}
							game_id={game_id}
							raiseAlert={raiseAlert}
							setSelectedWinners={setSelectedWinners}
							setMaxSelectedWinners={setMaxSelectedWinners}
							setInitiatePayment={setInitiatePayment}
							initiatePayment={initiatePayment}
							state={state}
							handleChange={handleChange2}
							setPaymentType={setPaymentType}
							paymentType={paymentType}
							setShowMDetailModal={setShowMDetailModal}
							showDetailModal={showDetailModal}
							handleclose2={handleclose2}
						/>
					</TableCell>
				</TableRow>
			);
		},
		// onRowExpansionChange: ( rowsExpanded) =>
		//   console.log(rowsExpanded),
	};

	//   filterType: "checkbox",
	//   filter: false,
	//   print: false,
	//   onRowSelectionChange: (e, i, v) => {
	//     setOpen(!open);

	//     let rowWinners = [];
	//     let maxWinners = [];
	//     let counter = 0;
	//     v.map((r) => {
	//       // if (historyselectedWinners[r].total_commission_balance
	//       //   > 5000) {
	//       //   let c = {
	//       //     agent_id: historyselectedWinners[r].agent_id,
	//       //     game_id: historyselectedWinners[r].game_id
	//       //   }

	//       //   setPaymentType('cheque')
	//       //   setSelectedItem(historyselectedWinners[r])
	//       //   setInitiatePayment(true)

	//       //   maxWinners.push(c)
	//       // } else {
	//       //   let c = {
	//       //     agent_id: historyselectedWinners[r].agent_id,
	//       //     game_id: historyselectedWinners[r].game_id
	//       //   }

	//       //   setInitiatePayment(true)
	//       //   rowWinners.push(c)
	//       // }

	//       console.log(historymaxselectedWinners[r]);
	//     });

	//     setSelectedWinners(rowWinners);
	//     setMaxSelectedWinners(maxWinners);
	//   },
	//   customToolbarSelect: () => {},
	//   sortOrder: {
	//     name: "created_at",
	//     direction: "desc",
	//   },
	// };

	const makePayment = () => {
		let customerList = [];
		console.log('selectedWinners', selectedWinners);

		if (selectedWinners.length > 0) {
			for (var i = 0; i < selectedWinners.length; i++) {
				customerList.push({
					id: selectedWinners[i].agent_id,
					reference: null,
					account_number: null,
					bank_id: null,
				});
			}
		}

		let payload = {
			game_id: selectedWinners[0].game_id,
			agent_list: customerList,
			channel: 'mobilemoney',
		};

		// setLoading(false);

		var config = {
			method: 'post',
			url: 'https://backend.megafortunelottery.com/commissions/pay',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: payload,
		};

		axios(config)
			.then(() => {
				// setLoading(false);

				if (daily) {
					todayrefetch();
					// window.location.reload();
				} else {
					searchrefetch();
					// window.location.reload();
				}

				SuccessNotification('Agent commission paid successfully');
			})
			.catch(function (error) {
				console.log(error);

				// setLoading(false);
				ErrorNotification('An error occured when making payment');
			});
	};

	const payWithCheque = () => {
		let customerList = [];
		console.log(maxselectedWinners);
		if (maxselectedWinners.length > 0) {
			for (var i = 0; i < maxselectedWinners.length; i++) {
				let m = {
					id: maxselectedWinners[i].agent_id,
					reference: state.reference,
					account_number: state.account_number,
					bank_id: state.bank_id,
				};
				customerList.push(m);
			}
		}
		let payload = {
			game_id: maxselectedWinners[0].game_id,
			channel: 'cheque',
			customer_list: customerList,
		};
		// setLoading(false);

		// console.log(payload);

		var config = {
			method: 'post',
			url: 'https://backend.megafortunelottery.com/commissions/pay',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: payload,
		};

		axios(config)
			.then(() => {
				// setLoading(false);
				setShowMDetailModal(false);

				if (daily) {
					todayrefetch();
				} else {
					searchrefetch();
				}

				SuccessNotification('Payment done successfully');
			})
			.catch(function (error) {
				console.log(error);

				// setLoading(false);
				ErrorNotification('An error occured when making payment');
			});
	};

	const raiseAlert = () => {
		if (initiatePayment == false) {
			ErrorNotification('You cannot initiate payment');
		} else {
			Swal.fire({
				title: 'Are you sure you want to make this payment',
				text: 'You won\'t be able to revert this!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes,accept',
			}).then((result) => {
				if (result.isConfirmed) {
					//   console.log(paymentType)
					try {
						if (paymentType === 'momo') {
							makePayment();
						} else {
							payWithCheque();
						}
					} catch (error) {
						// console.log(error);
						ErrorNotification('An error occured when creating payment');
					}
				}
			});
		}
	};

	const historyoptions = {
		filter: true,
		filterType: 'dropdown',
		responsive: 'standard',
		expandableRows: true,
		expandableRowsHeader: false,
		selectableRows: 'none',
		expandableRowsOnClick: true,
		isRowExpandable: () => {
			return true;
		},

		renderExpandableRow: (rowMeta) => {
			let winners =
        historyselectedWinners[rowMeta.rowIndex].payable_game_agents;
			let game_id = historyselectedWinners[rowMeta.rowIndex].id;

			return (
				<TableRow>
					<TableCell colSpan={7}>
						<AgentExpandableTable
							rowData={winners}
							game_id={game_id}
							raiseAlert={raiseAlert}
							setSelectedWinners={setSelectedWinners}
							setMaxSelectedWinners={setMaxSelectedWinners}
							setInitiatePayment={setInitiatePayment}
							initiatePayment={initiatePayment}
							state={state}
							handleChange={handleChange2}
							setPaymentType={setPaymentType}
							paymentType={paymentType}
							setShowMDetailModal={setShowMDetailModal}
							showDetailModal={showDetailModal}
							handleclose2={handleclose2}
						/>
					</TableCell>
				</TableRow>
			);
		},
		// onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
		//   console.log(rowsExpanded),
	};

	const historycolumns = [
		{
			name: 'event_code',
			label: 'Event Code',
			options: {
				filter: true,
				customBodyRender: (val) => {
					return (
						<p style={{ position: 'relative', left: 30, bottom: -10 }}>{val}</p>
					);
				},
			},
		},
		{
			name: 'product',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<p style={{ position: 'relative', left: 20, bottom: -10 }}>
							{val.name}
						</p>
					);
				},
			},
		},
		{
			name: 'closed_at',
			label: 'Date',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<p style={{ position: 'relative', left: 15, bottom: -10 }}>
							{formatDateFull(val)}
						</p>
					);
				},
			},
		},
		{
			name: 'payable_game_agents',
			label: 'Number of agents',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<p style={{ position: 'relative', left: 25, bottom: -10 }}>
							{val && val.length}
						</p>
					);
				},
			},
		},
	];

	return (
		<div style={{ padding: 20 }}>
			<AdminModal
				show={showDetailModal}
				close={!showDetailModal}
				title={'Pay with cheque'}
				handleClose={handleclose2}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',

					marginBottom: 5,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '50%',
						padding: 15,
					}}
				>
					<p
						style={{
							width: '10%',
							marginTop: 15,
							fontWeight: 600,
							fontSize: 15,
						}}
					>
            Filter by:
					</p>
					<div style={{ width: '30%', marginBottom: 10 }}>
						<Form.Control
							required
							as="select"
							type="select"
							onChange={handleChange}
							onInput={() => setDaily(false)}
							style={{
								backgroundColor: 'white',
								color: '#37517e',
								border: 'solid',
								width: '100%',
								height: '45px',
							}}
						>
							<option value={'Today Commission'}>Today Commission</option>
							<option value={'Past Commission'}>Past Commission</option>
						</Form.Control>
					</div>
				</div>

				<div
					style={{
						padding: 15,
						position: 'relative',
						right: reportType === 'Past Commission' ? '28%' : null,
						marginTop: reportType === 'Today Commission' ? null : '-2%',
					}}
				>
					{reportType === 'Today Commission' ? null : (
						<Form style={{ display: 'flex', flexDirection: 'row' }}>
							<Form.Group style={{ margin: 10 }}>
								<Form.Label>Select a game you want</Form.Label>
								<>
									<Form.Control
										as={'select'}
										type={'select'}
										onChange={handleGameSelect}
									>
										<option value={null}>Select a game</option>
										{games
											? games.map((game, key) => {
												return (
													<option key={key} value={game.id}>
														{game.name}
													</option>
												);
											})
											: null}
									</Form.Control>
								</>
							</Form.Group>
							{/* <Form.Group style={{ margin: 10 }}>
                <Form.Label>From:</Form.Label>
                <Form.Control
                  type='date'
                  onChange={e => {
                    e.preventDefault()
                    let myDate = new Date(e.target.value)
                      .toISOString()
                      .substring(0, 10)
                    setGameStartPeriod(myDate)
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>To:</Form.Label>
                <Form.Control
                  type='date'
                  onChange={e => {
                    e.preventDefault()
                    let myDate = new Date(e.target.value)
                      .toISOString()
                      .substring(0, 10)
                    setGameEndPeriod(myDate)
                  }}
                ></Form.Control>
              </Form.Group> */}
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									paddingTop: 25,
								}}
							>
								<Button
									onClick={(e) => {
										e.preventDefault();
										getGameReportWithinRange();
									}}
									className="btn btn-primary"
									type="submit"
								>
                  Search
								</Button>
							</div>
						</Form>
					)}
				</div>
			</div>

			{todaywinningLoading || gameDataWithinRangeLoading ? (
				<div
					style={{
						height: '100vh',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner />
				</div>
			) : reportType === 'Today Commission' ? (
				<MUIDataTable
					data={winners}
					title="Game Report"
					columns={historycolumns}
					options={options}
				/>
			) : (
				<MUIDataTable
					data={historyselectedWinners}
					title="Game Report"
					columns={historycolumns}
					options={historyoptions}
				/>
			)}

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginBottom: 30,
					marginTop: 20,
				}}
			>
				<p style={{ fontWeight: 'bolder' }}></p>

				<div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
					{/* <button
            disabled={open || paymentType === 'cheque'}
            className='btn btn-success'
            onClick={() => {
              raiseAlert()
            }}
          >
            <img style={{ position: 'relative', top: -1 }} />{' '}
            {loading ? 'loading' : 'Pay with momo'}
          </button> */}

					{/* <button disabled={paymentType === "momo"} className="btn btn-warning" onClick={() => { setShowMDetailModal(true); setInitiatePayment(true) }}>
            Pay with Cheque

          </button>
 */}
				</div>
			</div>
		</div>
	);
};

export default AgentPayoutIndex;
