import React from "react";
import "./UserCard.css";
import { GrUserAdmin } from "react-icons/gr";
import { GiSpy } from "react-icons/gi";
import { FiUser } from "react-icons/fi";
import { Button } from "react-bootstrap";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const UserCard = ({ name, borderColor, value }) => {
  // const history = useHistory();
  const navigate = useNavigate();

  const goto = (name) => {
    if (name === "Agents") {
      navigate("/admin/Agents");
    } else if (name === "Admins") {
      navigate("/admin/admins");
    } else if (name === "Customers") {
      navigate("/admin/customers");
    } else if (name === "WriterFms") {
      navigate("/admin/writerFms");
    } else {
      navigate("/admin/Writers");
    }
  };

  return (
    <div
      className="card"
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 15,
        width: 300,
        maxWidth: "1200px",
        height: 200,
        padding: 15,
        borderColor: borderColor,
      }}
    >
      <div>
        <h2>{name}</h2>
      </div>

      <div style={{ textAlign: "center" }}>
        {name === "Admins" ? (
          <GrUserAdmin size={50} />
        ) : name === "Agents" ? (
          <GiSpy size={50} />
        ) : (
          <FiUser size={50} />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          justifyContent: "space-between",
          margin: 5,
        }}
      >
        <h4>Count: {value}</h4>
        <Button
          onClick={(e) => {
            e.preventDefault();
            goto(name);
          }}
        >
          Details
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
