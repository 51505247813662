import React, { useState } from 'react';
import './auth.css';
import Logo from '../assets/megLogo.png';
import {
  ErrorNotification,
  SuccessNotification,
} from '../components/Notification';
import AdminModal from '../components/Modal';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { validateIDCard } from '../utils';
import { TermsAndConditonContent } from '../components';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function Writerregister() {
  // const history = useHistory();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [state, setState] = useState({
    momo_network: '',
    phone_number: '',
  });
  const [optCode, setoptCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentStep, setStep] = useState(1);
  const [showModal, setModal] = useState(false);
  const networks = [
    {
      label: 'Mtn',
      value: 'mtn-gh',
    },
    {
      label: 'Airteltigo',
      value: 'tigo-gh',
    },
    {
      label: 'Vodafone',
      value: 'vodafone-gh',
    },
  ];

  const regions = [
    {
      id: 'e28d611a-1bd7-4958-8a4b-033589075b4a',
      name: 'WESTERN',
    },
    {
      id: '6d879e8a-e599-4d26-83f5-bb05c58da2d1',
      name: 'CENTRAL',
    },
    {
      id: '52c2962b-b8b6-4509-96da-396fdc8244a8',
      name: 'GREATER ACCRA',
    },
    {
      id: '6d2f6c34-fdbe-4178-bdc2-848dd665869d',
      name: 'EASTERN',
    },
    {
      id: '62a286f0-7dd4-4cf0-8e01-677b90a00d6a',
      name: 'ASHANTI',
    },
    {
      id: '327e8362-8d06-4bb3-889b-45afeaf7c94e',
      name: 'WESTERN NORTH',
    },
    {
      id: '4f76e5b2-731e-4bb5-ab84-27f01b8b389b',
      name: 'AHAFO',
    },
    {
      id: '81a89249-0229-412c-a1d2-12c5d2a6e90a',
      name: 'BONO',
    },
    {
      id: '7c518386-4b9d-4957-b9d6-5612cd2262fd',
      name: 'BONO EAST',
    },
    {
      id: 'e35fb955-2876-4255-9ab4-7248ac38cc27',
      name: 'OTI',
    },
    {
      id: '73d8b96f-a276-4ae5-9bfa-02628fa47c27',
      name: 'NORTHERN',
    },
    {
      id: '98cb99b7-2a65-4f1d-8105-be91a6d9d239',
      name: 'SAVANNAH',
    },
    {
      id: 'f796fa0f-6297-46ce-aae0-8a8cac68b71b',
      name: 'UPPER WEST',
    },
    {
      id: '5a02ed4a-91d2-47e8-990c-f4e3f9b936b1',
      name: 'NORTH EAST',
    },
    {
      id: 'c047f4cf-5167-429e-8665-ebb2b6d87415',
      name: 'UPPER EAST',
    },
    {
      id: '2ce09dfd-1ec5-4ace-9ecf-b8f6e1db26be',
      name: 'VOLTA',
    },
  ];

  const validationSchema = Yup.object().shape({
    momo_network: Yup.string().required('Momo Network is required'),
    national_id: Yup.string().required('National ID is required'),
    first_name: Yup.string().required('First Name is required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email(),
    dob: Yup.date()
      .max(new Date(), 'Date of birth cannot be in the future')
      .test('is-over-18', 'You must be over 18 years old', function (value) {
        const dob = new Date(value);
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 18);

        return dob <= minDate;
      }),
    address: Yup.string().required('Address is required'),
    digital_address: Yup.string().required('Digital Address is required'),
    town_city: Yup.string().required('Town/City is required'),
    region_id: Yup.string().required('Region is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must include at least one lowercase letter, one uppercase letter, one number, and one special character (@$!%*?&) and be at least 8 characters long',
      ),
    consent_given: Yup.boolean().oneOf([true], 'Consent is required'),
    phone_number: Yup.string()
      .required('Phone Number is required')
      .test('valid-id-card', 'Invalid ID Card', (value) => {
        return validateIDCard(value);
      }),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords does not match'),
  });

  const initialState = {
    momo_network: '',
    national_id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    dob: '',
    address: '',
    digital_address: '',
    town_city: '',
    region_id: '',
    password: '',
    phone_number: '',
    confirm_password: '',
  };

  const handleOtpRequest = async () => {
    const formattedPhoneNumber = `233${state?.phone_number.substring(1)}`;

    const isPhoneNumberValid =
      state?.phone_number?.trim().length >= 10 &&
      state?.phone_number?.trim().length < 11;

    if (!isPhoneNumberValid) {
      ErrorNotification('Phone number must be 10 digits');
      return;
    }

    if (state?.momo_network === 'null' || !state?.momo_network) {
      ErrorNotification('Select your network to proceed');
      return;
    }

    try {
      setLoading(true);
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: formattedPhoneNumber,
          momo_network: state?.momo_network,
        }),
      };
      const req = await fetch(
        `${baseURL}api/v1/writerfm/auth/createotp`,
        options,
      );
      const res = await req.json();
      if (req.ok) {
        const { expire_at, momo_network, otp_hashed } = res.data;
        let otpData = {
          momo_network: momo_network,
          phone_number: formattedPhoneNumber,
          otp_hashed: otp_hashed,
          otp_expire_at: expire_at,
        };

        localStorage.setItem('otp', JSON.stringify(otpData));
        setStep(currentStep + 1);
        SuccessNotification(
          'An new OTP has been sent to your mobile device on',
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      ErrorNotification('Something went wrong. Try again');
    }
  };

  const verifyOpt = async () => {
    if (optCode?.trim()?.length < 0) {
      ErrorNotification('Enter otp code');
      return;
    }

    let data = JSON.parse(localStorage.getItem('otp'));

    let formattedDataWithCode = {
      ...data,
      otp: optCode,
    };

    try {
      setLoading(true);
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(formattedDataWithCode),
      };
      const req = await fetch(
        `${baseURL}api/v1/writerfm/auth/verifyotp`,
        options,
      );
      if (req.ok) {
        SuccessNotification('Phone number verified');
        setStep(currentStep + 1);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      ErrorNotification('Sorry! validation was not successful');
    }
  };

  const toggleModal = () => setModal(!showModal);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formValues = {};
    formData.forEach((value, key) => {
      formValues[key] = value;
    });

    if (!validateIDCard(formValues?.national_id)) {
      ErrorNotification('Invalid Ghana card format');
      return;
    }

    try {
      setLoading(true);

      let storageData = JSON.parse(localStorage.getItem('otp'));
      let formattedData = {
        phone_number: storageData?.phone_number,
        momo_network: storageData?.momo_network,
        national_id: formValues?.national_id,
        first_name: formValues?.first_name,
        middle_name: formValues?.middle_name,
        last_name: formValues?.last_name,
        email: formValues?.email,
        dob: formValues?.dob,
        user_type: 'writerfm',
        address: formValues?.address,
        digital_address: formValues?.digital_address,
        town_city: formValues?.town_city,
        region_id: formValues?.region_id,
        platform: 'mobile',
        password: formValues?.password,
        device_id: '',
        consent_given: state?.consent_given,
      };

      const options = {
        headers: {
          'Content-Type': 'application/json',
          'access-control-allow-credentials': true,
        },
      };

      const response = await axios.post(
        `${baseURL}api/v1/writerfm/auth/signup`,
        formattedData,
        options,
      );

      console.log(response);
      if (response.status === 200) {
        SuccessNotification('Registration successful');
        navigate('/writer-login');
      } else {
        ErrorNotification('Sign up was not successful');
      }

      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
      ErrorNotification('Sign up was not successful');
    }
  };

  return (
    <>
      <div className="main-container">
        <AdminModal
          show={showModal}
          close={!showModal}
          title={'Terms and Conditions'}
          body={
            <TermsAndConditonContent
              closeModal={toggleModal}
              state={state}
              setCheck={setState}
            />
          }
          handleClose={toggleModal}
        />
        <main className="d-flex w-100">
          <div className="container d-flex flex-column">
            <div className="row vh-100">
              <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="text-center mt-8 mb-4">
                    <img src={Logo} alt="Charles Hall" />
                  </div>

                  {currentStep === 1 && (
                    <div className="card">
                      <div className="card-body">
                        <div className="m-sm-4">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div className="mb-3">
                              <label className="form-label">
                                Phone Number
                                <input
                                  value={state?.phone_number}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      phone_number: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-lg"
                                  type="number"
                                  name="number"
                                  placeholder="Phone number"
                                />
                              </label>
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Select Network
                                <select
                                  value={state?.momo_network}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      momo_network: e.target.value,
                                    })
                                  }
                                  className="form-control form-control-lg"
                                  type="network"
                                  name="network"
                                  placeholder="Select network provider"
                                >
                                  <option value={'null'}>Select network</option>
                                  {networks?.map((el, index) => (
                                    <option key={index} value={el?.value}>
                                      {el?.label}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            </div>
                          </div>

                          <div
                            className="mt-4"
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <button
                              className="btn btn-lg"
                              style={{
                                width: '12rem',
                                fontSize: 16,
                                fontWeight: 'bolder',
                                backgroundColor: '#FF7300',
                                color: 'white',
                                borderRadius: 15,
                              }}
                              onClick={handleOtpRequest}
                            >
                              {loading ? 'Please wait' : 'Next'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="card">
                      <div className="card-body">
                        <div className="m-sm-4">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div className="mb-3">
                              <label className="form-label">
                                Enter OTP code sent to {state?.phone_number}
                                <input
                                  value={state?.optCode}
                                  onChange={(e) => setoptCode(e.target.value)}
                                  className="form-control form-control-lg"
                                  type="number"
                                  name="optCode"
                                  placeholder="OTP code"
                                />
                              </label>
                            </div>
                          </div>

                          <div
                            className="mt-4"
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <button
                              className="btn btn-lg"
                              style={{
                                width: '12rem',
                                fontSize: 16,
                                fontWeight: 'bolder',
                                backgroundColor: '#FF7300',
                                color: 'white',
                                borderRadius: 15,
                              }}
                              onClick={verifyOpt}
                            >
                              {loading ? 'Please wait' : 'Confirm'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentStep === 3 && (
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-">
                          <div className="card custom-card">
                            <div className="card-body">
                              <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                              >
                                <form onSubmit={handleFormSubmit}>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="fName"
                                        className="form-label capitalize"
                                      >
                                        First name
                                      </label>
                                      <Field
                                        name="first_name"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="First name"
                                        id="fName"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="first_name"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="lName"
                                        className="form-label capitalize"
                                      >
                                        Last name
                                      </label>
                                      <Field
                                        name="last_name"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Last name"
                                        id="lName"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="last_name"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="mName"
                                        className="form-label capitalize"
                                      >
                                        Middle name
                                      </label>
                                      <Field
                                        name="middle_name"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Middle name"
                                        id="mName"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label capitalize"
                                      >
                                        Email
                                      </label>
                                      <Field
                                        name="email"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Email"
                                        id="email"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="email"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="address"
                                        className="form-label capitalize"
                                      >
                                        Address
                                      </label>
                                      <Field
                                        name="address"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Address"
                                        id="address"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="address"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="digital_address"
                                        className="form-label capitalize"
                                      >
                                        Digital address
                                      </label>
                                      <Field
                                        name="digital_address"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Digital address"
                                        id="digital_address"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="digital_address"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="national_id"
                                        className="form-label capitalize"
                                      >
                                        Ghana card number
                                      </label>
                                      <Field
                                        name="national_id"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Ghana card number"
                                        id="national_id"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="national_id"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="dob"
                                        className="form-label capitalize"
                                      >
                                        Date of birth
                                      </label>
                                      <Field
                                        name="dob"
                                        id="dob"
                                        type="date"
                                        className="form-control form-control-lg w-100"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="dob"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="town_city"
                                        className="form-label capitalize"
                                      >
                                        Town / city
                                      </label>
                                      <Field
                                        name="town_city"
                                        id="town_city"
                                        type="text"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Town or city"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="town_city"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="region_id"
                                        className="form-label capitalize"
                                      >
                                        Region
                                      </label>
                                      <Field
                                        name="region_id"
                                        id="region_id"
                                        as="select"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Region"
                                      >
                                        <option>Select Region</option>
                                        {regions?.map((region) => (
                                          <option
                                            value={region?.id}
                                            key={region?.id}
                                          >
                                            {region?.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        className="error-message"
                                        name="region_id"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="password"
                                        className="form-label capitalize"
                                      >
                                        Password
                                      </label>
                                      <Field
                                        name="password"
                                        id="password"
                                        type="password"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Password"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="password"
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label
                                        htmlFor="confirm_password"
                                        className="form-label capitalize"
                                      >
                                        Confirm password
                                      </label>
                                      <Field
                                        name="confirm_password"
                                        id="confirm_password"
                                        type="password"
                                        className="form-control form-control-lg w-100"
                                        placeholder="Confirm password"
                                      />
                                      <ErrorMessage
                                        className="error-message"
                                        name="confirm_password"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <button
                                      className="d-flex align-items-center"
                                      onClick={() => toggleModal()}
                                    >
                                      <input
                                        type="checkbox"
                                        value={state?.consent_given}
                                        onChange={() => toggleModal()}
                                        onKeyDown={() => toggleModal()}
                                      />
                                      <span
                                        className="ml-2"
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '500',
                                        }}
                                      >
                                        Read Terms and Conditions and agree to
                                        it
                                      </span>
                                    </button>
                                  </div>
                                  {state?.consent_given && (
                                    <div className="mt-4">
                                      <button
                                        type="submit"
                                        className="btn btn-lg"
                                        style={{
                                          width: '12rem',
                                          fontSize: 16,
                                          fontWeight: 'bold',
                                          backgroundColor: '#FF7300',
                                          color: 'white',
                                          borderRadius: 15,
                                        }}
                                      >
                                        {loading ? 'Signing up...' : 'Sign up'}
                                      </button>
                                    </div>
                                  )}
                                </form>
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Writerregister;
