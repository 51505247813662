import React from 'react';
import Card from 'react-bootstrap/Card';
import Tile from './ImgTile';
import backArrow from '../../assets/arrow-left.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const game = location.state;
  // console.log("LOC", location, game);

  const {
    name,
    image,
    is_active,
    product_type: { category },
    properties: { digit_options },
  } = game;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: 15 }}>
      <div style={{width: '150px', padding: 10}}>
        <button style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
          <img src={backArrow} alt="Back Button" />{' '}Back
        </button>
      </div>
      <div
        style={{
          width: 120,
          height: 120,
          border: 'solid',
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: 10,
        }}
      >
        {<Tile productImage={image} />}
      </div>
      <h2>{name}</h2>
      <p>
        Game State:{' '}
        {is_active ? (
          <span style={{ fontWeight: 'bold' }}>Active</span>
        ) : (
          <span style={{ fontWeight: 'bold' }}>Inactive</span>
        )}&nbsp;|&nbsp;
        Category of Game:{' '}
        <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
          {category}
        </span>
      </p>
      {/* <p>
        Category of Game:{' '}
        <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
          {category}
        </span>
      </p> */}
      {/* <hr/> */}
      <h2>Digit Options</h2>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {digit_options &&
          digit_options.map((option) => {
            return (
              <Card
                key={option.name}
                style={{ width: 300, height: 200, margin: 5 }}
              >
                <Card.Body>
                  <Card.Title>{option.name}</Card.Title>
                  <div>
                    Odds:{' '}
                    <span style={{ fontWeight: 'bold' }}>{option.odds}</span>
                  </div>
                  <div>
                    Min:{' '}
                    <span style={{ fontWeight: 'bold' }}>{option.min}</span>
                  </div>
                  <div>
                    Max:{' '}
                    <span style={{ fontWeight: 'bold' }}>{option.max}</span>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default ProductDetails;
