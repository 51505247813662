import React, { useState } from 'react';
import {
	GET_ALL_WRITERFMS,
	GET_ALL_WRITERS_OF_WRITERFM,
	GET_UNASSIGNED_WRITERS,
} from '../../Graphql/Queries';
import { Tabs, Tab } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import {
	ErrorNotification,
	SuccessNotification,
} from '../../components/Notification';
import { CustomModal } from '../../components';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ADDWRITERTOWRITERFM } from '../../Graphql/Mutations';
import AdminModal from '../../components/Modal';
import { useNavigate } from "react-router-dom";

const WriterFmView = () => {
	// const history = useHistory();
	const navigate = useNavigate();
	const [selectedWriters, setSelectedWriters] = useState([]);
	const [showModal, setModal] = useState(false);
	const [showWritersModal, setShowWritersModal] = useState(false);
	const [selectedWriterID, setSelectedID] = useState();

	const toggleModal = () => setModal(!showModal);

	const toggleShowWritersModal = () => setShowWritersModal(!showWritersModal);

	const {
		data: writerData,
		loading: writerDataLoading,
		refetch: RefreshWritersOfWeriterfm,
	} = useQuery(GET_ALL_WRITERS_OF_WRITERFM, {
		variables: {
			writer_fm: selectedWriterID,
		},
		onError: () => {
			ErrorNotification('Failed to load writers of FM');
		},
	});

	const { data: writerfms } = useQuery(GET_ALL_WRITERFMS, {
		onError: () => {
			ErrorNotification('Failed to load writer FM');
		},
	});

	const { data: writers, refetch } = useQuery(GET_UNASSIGNED_WRITERS, {
		onError: () => {
			ErrorNotification('Failed to load unassigned writers');
		},
	});

	const handleRowWriters = (rowIndex, dataIndex) => {
		console.log(rowIndex);
		toggleShowWritersModal();
		setSelectedID(writerfms?.writer_fms[dataIndex.dataIndex]?.id);
		RefreshWritersOfWeriterfm();
	};

	const handleRowClickAssignWriters = (rowIndex, dataIndex) => {
		console.log(rowIndex);
		navigate(
			'/admin/writer-details', {
			state: writers?.writer[dataIndex.dataIndex],
		});
	};

	const writersOptions = {
		filterType: 'checkbox',
		filter: false,
		print: false,
		selectableRows: false,
		onRowClick: handleRowWriters,
		customToolbarSelect: () => {},
		sortOrder: {
			name: 'created_at',
			direction: 'desc',
		},
		responsive: 'scroll',
	};

	const assignWriterOption = {
		filterType: 'checkbox',
		filter: false,
		print: false,
		onRowClick: handleRowClickAssignWriters,
		customToolbarSelect: () => {},
		sortOrder: {
			name: 'created_at',
			direction: 'desc',
		},
		selectableRows: 'multiple',
		responsive: 'scroll',
		onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
			console.log(currentRowsSelected);
			const selected = allRowsSelected.map(
				(data) => writers?.writers[data?.index],
			);
			setSelectedWriters(selected);
		},
	};

	const assingedWriterOption = {
		filter: false,
		print: false,
		sortOrder: {
			name: 'created_at',
			direction: 'desc',
		},
		selectableRows: false,
		responsive: 'scroll',
	};

	const columns = [
		{
			name: 'first_name',
			label: 'First Name',
			options: {
				customBodyRender: (value) => (
					<div
						style={{
							position: 'relative',
							left: 40,
						}}
					>
						{value}
					</div>
				),
			},
		},
		{
			name: 'last_name',
			label: 'Last Name',
			options: {
				customBodyRender: (value) => (
					<div
						style={{
							position: 'relative',
							left: 40,
						}}
					>
						{value}
					</div>
				),
			},
		},
		{
			name: 'email',
			label: 'Email',
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value) => <div>{value}</div>,
			},
		},
		{
			name: 'phone_number',
			label: 'Phone Number',
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value) => <div>{value}</div>,
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value) => (
					<div
						style={{
							position: 'relative',
							left: 20,
						}}
					>
						{value}
					</div>
				),
			},
		},
	];

	const writersColumns = [
		{
			name: 'fullname',
			label: 'fullname',
			options: {
				customBodyRender: (value) => <div>{value}</div>,
			},
		},
		{
			name: 'email',
			label: 'email',
			options: {
				customBodyRender: (value) => <div>{value}</div>,
			},
		},
		{
			name: 'phone_number',
			label: 'phone_number',
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value) => <div>{value}</div>,
			},
		},
		{
			name: 'status',
			label: 'status',
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value) => <div>{value}</div>,
			},
		},
	];

	const onAssignWriters = () => {
		if (selectedWriters?.length < 1) {
			ErrorNotification('Select writers to assign');
			return;
		}
		toggleModal();
	};

	return (
		<>
			<AdminModal
				show={showWritersModal}
				close={!showWritersModal}
				title={'List of Writers'}
				body={
					<MUIDataTable
						title={'Assigned writers'}
						data={writerData?.writers ? writerData?.writers : []}
						columns={writersColumns}
						options={assingedWriterOption}
					/>
				}
				handleClose={toggleShowWritersModal}
			/>

			<CustomModal
				show={showModal}
				close={!showModal}
				title={'Assign Writers'}
				body={
					<SelectWriterCard
						selectedWriters={selectedWriters}
						data={writerfms?.writer_fms}
						refetch={refetch}
						toggleModal={toggleModal}
					/>
				}
				handleClose={toggleModal}
				size="md"
			/>
			<div>
				<Tabs
					defaultActiveKey="writers"
					id="uncontrolled-tab-example"
					className="mb-3"
				>
					<Tab eventKey="writers" title="Writerfms">
						<div
							style={{
								padding: 15,
							}}
						>
							<div
								style={{
									padding: 15,
								}}
							>
								<BiArrowBack
									style={{ cursor: 'pointer' }}
									onClick={(e) => {
										e.preventDefault();
										navigate(-1);
									}}
									size={50}
								/>
							</div>

							{writerDataLoading ? (
								<CircularProgress
									style={{
										position: 'relative',
										marginTop: '25%',
										left: '55%',
									}}
								/>
							) : writers?.length === 0 ? (
								<div style={{ width: '100%', textAlign: 'center', margin: 50 }}>
									<h1>There are no writers</h1>
								</div>
							) : (
								<MUIDataTable
									title={'Writerfms'}
									data={writerfms?.writer_fms ? writerfms?.writer_fms : []}
									columns={columns}
									options={writersOptions}
								/>
							)}
						</div>
					</Tab>
					<Tab eventKey="Assign" title="Assign new Writers">
						<div
							style={{
								padding: 15,
							}}
						>
							<div
								style={{
									padding: 15,
								}}
								className="d-flex justify-content-between"
							>
								<BiArrowBack
									style={{ cursor: 'pointer' }}
									onClick={(e) => {
										e.preventDefault();
										navigate(-1);
									}}
									size={50}
								/>
								<button
									type="button"
									className="btn btn-primary rounded"
									onClick={() => {
										onAssignWriters();
									}}
								>
                  Assign writerFm
								</button>
							</div>

							{writerDataLoading ? (
								<CircularProgress
									style={{
										position: 'relative',
										marginTop: '25%',
										left: '55%',
									}}
								/>
							) : (
								<MUIDataTable
									title={'Assign new writers'}
									data={writers?.writers ? writers?.writers : []}
									columns={columns}
									options={assignWriterOption}
								/>
							)}
						</div>
					</Tab>
				</Tabs>
			</div>
		</>
	);
};

const SelectWriterCard = ({ selectedWriters, data, refetch, toggleModal }) => {
	const animatedComponents = makeAnimated();
	const [selected, setSelected] = useState();
	let adminID = localStorage?.getItem('admin_id');

	const [addWriterToWriterFm, { loading }] = useMutation(ADDWRITERTOWRITERFM, {
		onCompleted: () => {
			refetch();
			SuccessNotification('Writers have been successfully added');
			toggleModal();
		},
		onError: () => {
			// console.log(error);
			ErrorNotification('Assignment failed. Try again');
		},
	});

	const formattedData = Object?.keys(data)?.map((key) => ({
		label: `${data[key]?.first_name} ${data[key]?.last_name} - ${data[key]?.phone_number}`,
		value: data[key]?.first_name,
		address: data[key]?.address,
		email: data[key]?.email,
		first_name: data[key]?.first_name,
		ghana_card: data[key]?.ghana_card,
		id: data[key]?.id,
		last_name: data[key]?.last_name,
		region: data[key]?.region,
		status: data[key]?.status,
	}));

	const handleSubmit = async () => {
		if (!selected) {
			ErrorNotification('Please select a writerfm');
			return;
		}

		const data = selectedWriters?.map((writer) => {
			// console.log(writer);
			return {
				created_by: adminID,
				writer_id: writer?.id,
				writerfm_id: selected?.id,
			};
		});

		const uniqueKeys = new Set();
		const deduplicatedData = data?.filter((item) => {
			const uniqueKey = item?.uniqueColumn1 + item?.uniqueColumn2;
			if (uniqueKeys?.has(uniqueKey)) {
				// Duplicate found, skip this item
				return false;
			}
			uniqueKeys.add(uniqueKey);
			return true;
		});

		// console.log(deduplicatedData);

		addWriterToWriterFm({
			variables: {
				data: deduplicatedData,
			},
		});
	};

	return (
		<div>
			<p style={{ fontSize: '16px', color: '#000' }}>Select writerfm</p>
			<div className="mb-3" style={{ fontSize: '#000' }}>
				<Select
					closeMenuOnSelect={false}
					components={animatedComponents}
					options={formattedData}
					onChange={(el) => setSelected(el)}
					value={selected}
				/>
			</div>

			<div className="mt-4">
				<button
					type="submit"
					className="btn btn-lg"
					style={{
						width: '12rem',
						fontSize: 16,
						fontWeight: 'semibold',
						backgroundColor: '#FF7300',
						color: 'white',
						borderRadius: 15,
					}}
					onClick={() => handleSubmit()}
				>
					{loading ? 'Loading...' : 'Proceed'}
				</button>
			</div>
		</div>
	);
};

export default WriterFmView;
