import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { formatDateFull } from '../../utils';
import Spinner from '../../components/Spinner';
import './index.css';
import { GET_ALL_PAYOUT_STATUS } from '../../Graphql/Queries';
import {
  ErrorNotification,
  SuccessNotification,
} from '../../components/Notification';

const PayoutResolver = () => {
  // const [loading, setLoading] = useState(false);
  const [winning_claim, setWinningClaim] = useState([]);
  const [loadingStates, setLoadingStates] = useState(
    Array(winning_claim.length).fill(false),
  );

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { loading: payoutLoading } = useQuery(GET_ALL_PAYOUT_STATUS, {
    onCompleted: (data) => {
      setWinningClaim(data.winning_claim);
    },
    onError: () => {
      ErrorNotification('Payment status query failed with error');
    },
  });

  const ResolveStake = async (reference, rowIndex) => {
    setLoadingStates((prevState) => {
      const newState = [...prevState];
      newState[rowIndex] = true;
      return newState;
    });

    try {
      const token = localStorage.getItem('accesstoken');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      axiosInstance.get(`payout/${reference}/resolve`, {
        headers,
      });

      SuccessNotification('Stake payment resolved successfully');
    } catch (error) {
      console.log(error.response);
      ErrorNotification(error.response.data.message);
    }

    setLoadingStates((prevState) => {
      const newState = [...prevState];
      newState[rowIndex] = false;
      return newState;
    });
  };

  const options = {
    filter: false,
    print: false,

    // customToolbarSelect: () => {},
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },

    {
      name: 'customer',
      label: 'Full name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>
              {val.full_name}
            </p>
          );
        },
      },
    },
    {
      name: 'account_number',
      label: 'Recipient number',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'amount',
      label: 'Amount(GHS)',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'reference',
      label: 'Reference',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'third_party_ref',
      label: 'Third Party Reference',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'payment_provider',
      label: 'Payment Provider',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val}</p>
          );
        },
      },
    },

    {
      name: 'created_at',
      label: 'Date Issued',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {formatDateFull(val)}
            </p>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Resolve payout',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (val) {
            return (
              <button
                className="btn btn-success"
                onClick={() => ResolveStake(tableMeta.rowData[1].id, rowIndex)}
                disabled={loadingStates[rowIndex]}
              >
                {loadingStates[rowIndex] ? 'Loading...' : 'Resolve Payout'}
              </button>
            );
          } else {
            return <p style={{ position: 'relative', left: 5, top: 10 }}></p>;
          }
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      ></div>

      {payoutLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MUIDataTable
            data={winning_claim}
            title={'RESOLVE PAYMENT ISSUES HERE'}
            columns={columns}
            options={options}
            // onRowClick={handleRowClick}
          />
        </div>
      )}
    </div>
  );
};

export default PayoutResolver;
