import React, { useState } from 'react';
import { useQuery, useLazyQuery, useSubscription } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { formatDateFull, getToday } from '../../utils';
import Spinner from '../../components/Spinner';
import { Button } from 'react-bootstrap';
import './index.css';
import { ErrorNotification } from '../../components/Notification';
import {
  GET_ALL_PRODUCTS_NAME,
  GET_ALL_CUSTOMER_WINNERS,
  GET_ALL_CUSTOMER_WINNERS_SUB,
} from '../../Graphql/Queries';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { SuccessNotification } from '../../components/Notification';
import ExpandableTable from './ExpandableTable';
import AdminModal from '../../components/Modal';
import Swal from 'sweetalert2';
// import { ChequeDataComponent2 } from '../../components/chequeDataComponent'
import { formatAmount } from '../../utils';

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState([]);
  const [games, setGames] = useState([]);
  const [daily, setDaily] = useState(true);
  const [reportType, setReportType] = useState('Today Winners');
  const [gameType, setGameType] = useState('');
  const [gameStartPeriod, setGameStartPeriod] = useState(null);
  const [gameEndPeriod, setGameEndPeriod] = useState(null);
  const [maxselectedWinners, setMaxSelectedWinners] = useState([]);
  const [selectedWinners, setSelectedWinners] = useState();
  const [historyselectedWinners, sethistorySelectedWinners] = useState([]);
  const [initiatePayment, setInitiatePayment] = useState(false);
  const token = localStorage.getItem('accesstoken');
  const [paymentType, setPaymentType] = useState('momo');
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [open, setOpen] = useState(true);
  const [totalWinnersSelected, setTotalWinnersSelected] = useState(false);
  const [selectedSum, setSelectedSum] = useState(0);

  const [state, setState] = useState({
    reference: '',
    account_number: '',
    bank_id: '',
  });

  function handleChange2(evt) {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const [
    getGameReportWithinRange,
    { loading: gameDataWithinRangeLoading, refetch: searchrefetch },
  ] = useLazyQuery(GET_ALL_CUSTOMER_WINNERS, {
    variables: {
      start_date: gameStartPeriod && gameStartPeriod.toString(),
      end_date: gameEndPeriod && gameEndPeriod.toString(),
      product_id: gameType,
    },

    onCompleted: (data) => {
      sethistorySelectedWinners(data.games);
    },

    onError: () => {
      ErrorNotification('Game report Query failed due to an error');
    },
  });

  const { loading: gamRealTimeLoading } = useSubscription(
    GET_ALL_CUSTOMER_WINNERS_SUB,
    {
      variables: {
        start_date: gameStartPeriod && gameStartPeriod.toString(),
        end_date: gameEndPeriod && gameEndPeriod.toString(),
        product_id: localStorage.getItem('gameType'),
      },
      onComplete: (data) => {
        sethistorySelectedWinners(data.games);
      },

      onError: () => {
        ErrorNotification('Real time data subscription failed due to error');
      },
    },
  );

  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  const handleGameSelect = (e) => {
    localStorage.setItem('gameType', e.target.value);
    setGameType(e.target.value);
  };

  useQuery(GET_ALL_PRODUCTS_NAME, {
    onCompleted: (data) => {
      setGames(data.product);
    },
  });

  const { loading: todaywinningLoading, refetch: todayrefetch } =
    useSubscription(GET_ALL_CUSTOMER_WINNERS_SUB, {
      variables: {
        start_date: getToday(),
        end_date: getToday(),
        product_id: localStorage.getItem('today_game_product_id'),
      },

      onComplete: (data) => {
        let winnersList = [];

        if (data.games.length > 0) {
          let has_winners = data.games.filter(
            (r) => r.payable_winners.length > 0,
          );

          if (has_winners.length > 0) {
            for (var i = 0; i < has_winners[0].payable_winners.length; i++) {
              // console.log(has_winners[0].claim_status)

              let u = {
                id: has_winners[0].id,
                fullname: has_winners[0].payable_winners[i].customer.full_name,
                amount: has_winners[0].payable_winners[i].total_winning_amount,
                staked_amount:
                  has_winners[0].payable_winners[i].total_stake_amount,
                game_name: has_winners[0].product.name,
                event_code: has_winners[0].event_code,
                closed_at: has_winners[0].closed_at,
                payment_status: has_winners[0].payable_winners[i].claim_status,
                customer_id: has_winners[0].payable_winners[i].customer.id,
              };

              winnersList.push(u);
            }
            setWinners(winnersList);
          }
        }
      },

      onError: () => {
        ErrorNotification('Failed to subscribe to all winners');
      },
    });

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    // isRowSelectable: false,
    onRowSelectionChange: (e, i, v) => {
      // console.log(v)

      let mySum = v.reduce((acc, curr) => acc + winners[curr].amount, 0);
      // console.log(selectedSum)
      setSelectedSum(mySum);

      setOpen(!open);

      let rowWinners = [];
      let maxWinners = [];
      // let counter = 0;
      v.map((r) => {
        if (winners[r].amount > 5000) {
          let c = {
            customer_id: winners[r].customer_id,
            game_id: winners[r].id,
          };

          setPaymentType('cheque');
          setInitiatePayment(true);

          maxWinners.push(c);
        } else {
          let c = {
            customer_id: winners[r].customer_id,
            game_id: winners[r].id,
          };

          setInitiatePayment(true);
          rowWinners.push(c);
        }
      });

      if (rowWinners.length == winners.length) {
        setTotalWinnersSelected(true);
      } else {
        setTotalWinnersSelected(false);
      }

      setSelectedWinners(rowWinners);
      setMaxSelectedWinners(maxWinners);
    },
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const makePayment = () => {
    let customerList = [];

    // console.log(totalWinnersSelected);

    if (selectedWinners.length > 0) {
      for (var i = 0; i < selectedWinners.length; i++) {
        let m = {
          id: selectedWinners[i].customer_id,
          reference: '',
          account_number: '',
          bank_id: '',
        };
        customerList.push(m);
      }
    }
    let payload = {
      game_id: selectedWinners[0].game_id,
      channel: 'mobilemoney',
      customer_list: totalWinnersSelected ? null : customerList,
    };
    // console.log(payload);
    setLoading(false);
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}winners/momo/pay`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    };

    axios(config)
      .then(() => {
        setLoading(false);

        // if (daily) {
        //   todayrefetch()

        // }
        // else {
        //   searchrefetch()

        // }

        SuccessNotification('Payment done successfully');
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
        ErrorNotification('An error occured when making payment');
      });
  };

  const payWithCheque = () => {
    let customerList = [];
    if (maxselectedWinners.length > 0) {
      for (var i = 0; i < maxselectedWinners.length; i++) {
        let m = {
          id: maxselectedWinners[i].customer_id,
          reference: state.reference,
          account_number: state.account_number,
          bank_id: state.bank_id,
        };
        customerList.push(m);
      }
    }
    let payload = {
      game_id: maxselectedWinners[0].game_id,
      channel: 'cheque',
      customer_list: customerList,
    };
    setLoading(false);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}winners/pay`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    };

    axios(config)
      .then(() => {
        setLoading(false);
        setShowMDetailModal(false);

        if (daily) {
          todayrefetch();
        } else {
          searchrefetch();
        }

        SuccessNotification('Payment done successfully');
      })
      .catch(function (error) {
        console.log(error);

        setLoading(false);
        ErrorNotification('An error occured when making payment');
      });
  };

  const raiseAlert = () => {
    if (initiatePayment == false) {
      ErrorNotification('You cannot initiate payment');
    } else {
      Swal.fire({
        title: 'Are you sure you want to make this payment',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes,accept',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(paymentType);
          try {
            if (paymentType === 'momo') {
              makePayment();
            } else {
              payWithCheque();
            }
          } catch (error) {
            console.log(error);
            ErrorNotification('An error occured when creating payment');
          }
        }
      });
    }
  };

  const historyoptions = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRows: 'none',
    expandableRowsOnClick: true,
    isRowExpandable: () => {
      return true;
    },

    renderExpandableRow: (rowMeta) => {
      let winners = historyselectedWinners[rowMeta.rowIndex].payable_winners;
      let game_id = historyselectedWinners[rowMeta.rowIndex].id;

      return (
        <TableRow>
          <TableCell colSpan={7}>
            <ExpandableTable
              gamRealTimeLoading={gamRealTimeLoading}
              rowData={winners}
              game_id={game_id}
              raiseAlert={raiseAlert}
              setSelectedWinners={setSelectedWinners}
              setMaxSelectedWinners={setMaxSelectedWinners}
              setInitiatePayment={setInitiatePayment}
              initiatePayment={initiatePayment}
              state={state}
              handleChange={handleChange2}
              setPaymentType={setPaymentType}
              paymentType={paymentType}
              setShowMDetailModal={setShowMDetailModal}
              showDetailModal={showDetailModal}
              handleclose2={handleclose2}
              setTotalWinnersSelected={setTotalWinnersSelected}
            />
          </TableCell>
        </TableRow>
      );
    },
    // onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
    //   console.log(rowsExpanded),
  };

  const columns = [
    {
      name: 'id',
      label: 'Game Id',
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val}</p>;
        },
      },
    },
    {
      name: 'fullname',
      label: 'Full Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 5 }}>{val}</p>;
        },
      },
    },
    {
      name: 'amount',
      label: 'Amount(GHS)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 50 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },

    {
      name: 'game_name',
      label: 'Game',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 10 }}>{val}</p>;
        },
      },
    },
    {
      name: 'event_code',
      label: 'Event Code',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 50 }}>{val}</p>;
        },
      },
    },
    {
      name: 'closed_at',
      label: 'Date Won',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 2 }}>
              {formatDateFull(val)}
            </p>
          );
        },
      },
    },

    {
      name: 'staked_amount',
      label: 'Staked amount(GHS)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 50 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },

    {
      name: 'payment_status',
      label: 'Payment status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val === 'paid' ? (
                <div
                  style={{
                    backgroundColor: '#90EE90', // lighter orange color
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: 'green',
                    fontWeight: 'bold',
                    padding: '5px 10px', // reduced padding
                    width: '100px',
                    lineHeight: '20px', // reduced line-height
                    margin: '0 auto',
                  }}
                >
                  {val}
                </div>
              ) : val === 'processing' ? (
                <div
                  style={{
                    backgroundColor: '#ADD8E6', // lighter orange color
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: 'blue',
                    fontWeight: 'bold',
                    padding: '5px 10px', // reduced padding
                    width: '100px',
                    lineHeight: '20px', // reduced line-height
                    margin: '0 auto',
                  }}
                >
                  {val}
                </div>
              ) : val === 'failed' ? (
                <div
                  style={{
                    backgroundColor: '#ffa07a', // lighter orange color
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: 'red',
                    fontWeight: 'bold',
                    padding: '5px 10px', // reduced padding
                    width: '100px',
                    lineHeight: '20px', // reduced line-height
                    margin: '0 auto',
                  }}
                >
                  {val}
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: '#D3D3D3', // lighter orange color
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: '#5A5A5A',
                    fontWeight: 'bold',
                    padding: '5px 10px', // reduced padding
                    width: '100px',
                    lineHeight: '20px', // reduced line-height
                    margin: '0 auto',
                  }}
                >
                  {val}
                </div>
              )}
            </div>
          );
        },
      },
    },
  ];

  const historycolumns = [
    {
      name: 'event_code',
      label: 'Event Code',
      options: {
        filter: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, bottom: -10 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'product',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 20, bottom: -10 }}>
              {val.name}
            </p>
          );
        },
      },
    },
    {
      name: 'closed_at',
      label: 'Date Won',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 15, bottom: -10 }}>
              {formatDateFull(val)}
            </p>
          );
        },
      },
    },
    {
      name: 'payable_winners',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 25, bottom: -10 }}>
              {val.length}
            </p>
          );
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Pay with cheque'}
        handleClose={handleclose2}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            padding: 15,
          }}
        >
          <p
            style={{
              width: '10%',
              marginTop: 15,
              fontWeight: 600,
              fontSize: 15,
            }}
          >
            Filter by:
          </p>
          <div style={{ width: '30%', marginBottom: 10 }}>
            <Form.Control
              required
              as="select"
              type="select"
              onChange={handleChange}
              onInput={() => setDaily(false)}
              style={{
                backgroundColor: 'white',
                color: '#37517e',
                border: 'solid',
                width: '100%',
                height: '45px',
              }}
            >
              <option value={'Today Winners'}>Today Winners</option>
              <option value={'Past Winners'}>Past Winners</option>
            </Form.Control>
          </div>

          <div
            style={{
              display: 'flex',
              marginLeft: 25,
              alignItems: 'center',
              position: 'relative',
              right: reportType === 'Past Winners' ? '-85%' : null,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>
              Total Amount of Selected Paymentes :{' '}
            </p>
            <p style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 18 }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'GHS',
              }).format(selectedSum)}
            </p>
          </div>
        </div>

        <div
          style={{
            padding: 15,
            position: 'relative',
            right: reportType === 'Past Winners' ? '28%' : null,
            marginTop: reportType === 'Today Winners' ? null : '-2%',
          }}
        >
          {reportType === 'Today Winners' ? null : (
            <Form style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>Select a game you want</Form.Label>
                <>
                  <Form.Control
                    as={'select'}
                    type={'select'}
                    onChange={handleGameSelect}
                  >
                    <option value={null}>Select a game</option>
                    {games
                      ? games.map((game, key) => {
                        return (
                          <option key={key} value={game.id}>
                            {game.name}
                          </option>
                        );
                      })
                      : null}
                  </Form.Control>
                </>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>From:</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => {
                    e.preventDefault();
                    let myDate = new Date(e.target.value)
                      .toISOString()
                      .substring(0, 10);
                    setGameStartPeriod(myDate);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ margin: 10 }}>
                <Form.Label>To:</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => {
                    e.preventDefault();
                    let myDate = new Date(e.target.value)
                      .toISOString()
                      .substring(0, 10);
                    setGameEndPeriod(myDate);
                  }}
                ></Form.Control>
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 25,
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    getGameReportWithinRange();
                  }}
                  className="btn btn-primary"
                  type="submit"
                >
                  Search
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>

      {todaywinningLoading || gameDataWithinRangeLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : reportType === 'Today Winners' ? (
        <MUIDataTable
          data={winners}
          title="Game Winners"
          columns={columns}
          options={options}
        />
      ) : (
        <MUIDataTable
          data={historyselectedWinners}
          title="Game History"
          columns={historycolumns}
          options={historyoptions}
        />
      )}

      {reportType === 'Today Winners' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
            marginTop: 20,
          }}
        >
          <p style={{ fontWeight: 'bolder' }}></p>

          <div
            style={{ display: 'flex', flexDirection: 'row', float: 'right' }}
          >
            <button
              disabled={paymentType === 'cheque'}
              className="btn btn-success mr-2"
              onClick={() => {
                raiseAlert();
              }}
            >
              <img alt="" style={{ position: 'relative', top: -1 }} />{' '}
              {loading ? 'loading' : 'Pay with momo'}
            </button>

            <button
              disabled={paymentType === 'momo'}
              className="btn btn-warning"
              onClick={() => {
                setShowMDetailModal(true);
                setInitiatePayment(true);
              }}
            >
              Pay with Cheque
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Index;
