import React from "react";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import SomethingWentWrong from "../auth/404";
import Login from "../auth/login";
import Register from "../auth/Register";
import Setups from "../views/SetupView/Setups";
import WriterLogin from "../auth/writerLogin";
import Writerregister from "../auth/writerregister";
import Index from "../views/DashboardView";
import ReportOverview from "../views/ReportsView/ReportOverview";
import PrivateRoutes from "../routes";

const DashboardLayout = React.lazy(() => import("../layouts/DashboardLayout"));

const ProtectedRoutes = () => {
  if (localStorage.getItem("tokenStatusCode") != 200) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export const Content = () => {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="admin" element={<DashboardLayout />}>
            <Route index element={<Index />} />
            <Route path="report-and-analytics" element={<ReportOverview />} />
            {PrivateRoutes.map((route, key) => (
              <Route key={key} path={route.layout + route.path} Component={route.component} />
            ))}
          </Route>
        </Route>
        <Route path={"/404"} Component={SomethingWentWrong} />
        {/* <Route path={"/example"} component={ExampleTest} /> */}
        <Route path="/writer-login" Component={WriterLogin} />
        <Route path="/writer-register" Component={Writerregister} />
        <Route path="/register" Component={Register} />
        <Route index Component={Login} />
        <Route path="/Products" Component={Setups} />
        {/* <Redirect to="/404" /> */}
      </Routes>
    </>
  );
};

export default ProtectedRoutes;
