import React from 'react';
import { formatDateFull } from '../../utils';

//get message detail with api detail
function MessageDetail(props) {
  // console.log(props.detail);
  const { title, sent_date, sender, message, recipient } = props.detail;
  const getnames = (arraydata) => {
    for (var i = 0; i < arraydata.length; i++) {
      return arraydata[i].full_name;
    }
  };
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', lineHeight: 1 }}>
        <p style={{ fontWeight: 'bold', paddingRight: 10 }}>Sms Title:</p>
        <p style={{ color: 'black' }}>{title}</p>
      </div>
      <hr />
      <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
        <p style={{ fontWeight: 'bold' }}>Sms Body</p>
        <p style={{ color: 'black', position: 'relative', top: -10 }}>
          {message}
        </p>
      </div>
      <hr />
      <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row' }}>
        <p style={{ fontWeight: 'bold', paddingRight: 10 }}>Sent to:</p>
        <p style={{ color: 'black' }}>{getnames(recipient.customers)}</p>
      </div>
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: 12 }}>Sent by: {sender.name}</p>
        <p style={{ fontSize: 12 }}>Date Sent: {formatDateFull(sent_date)}</p>
      </div>
    </div>
  );
}

export default MessageDetail;
