import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import MUIDataTable from "mui-datatables";
import { formatDateShort } from "../../utils";
import Spinner from "../../components/Spinner";
import "./index.css";
import { BiFilter } from "react-icons/bi";
import { GET_GAME_OF_DAY_NEW } from "../../Graphql/Queries";
import { SET_GAME_OF_DAY, TERMINATE_GAME } from "../../Graphql/Mutations";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../components/Notification";
import CloseGame from "../../components/CloseGame";
import CloseGameMultiple from "../../components/CloseGameMultiple";
import AdminModal from "../../components/Modal";
import { CLOSE_GAME } from "../../Graphql/Mutations";

import TerminateGameComponent from "../../components/terminateGame";

const Index = () => {
  // const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [game_id, setGameId] = useState();
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [selectedGameId, setSelectedGameId] = useState();
  const [showDetailModal2, setShowMDetailModal2] = useState(false);
  const handleclose3 = () => setShowMDetailModal2(false);

  const getDayOfWeek = () => {
    const dayOfWeekName = new Date().toLocaleString("default", {
      weekday: "long",
    });
    let f = dayOfWeekName.substring(0, 3);
    return f.toLowerCase();
  };

  const { loading: eventLoading, refetch } = useQuery(GET_GAME_OF_DAY_NEW, {
    variables: {
      schedule_day: [getDayOfWeek().toString()],
    },

    onCompleted: (data) => {
      setGames(data.game);
    },

    onError: () => {
      ErrorNotification("Failed to fetch game of the day");
    },
  });

  const [setupGameofDay] = useMutation(SET_GAME_OF_DAY, {
    onCompleted: () => {
      refetch();
      SuccessNotification("You have successfully disabled game of day ");
    },
    onError: () => ErrorNotification("Failed to set game of the day"),
  });

  const [terminateGame] = useMutation(TERMINATE_GAME, {
    onCompleted: () => {
      refetch();
      setShowMDetailModal2(false);
      SuccessNotification("You have successfully terminated game ");
    },
    onError: () => ErrorNotification("Failed terminate game"),
  });

  const [closeGameofDay] = useMutation(CLOSE_GAME, {
    onCompleted: () => {
      setShowMDetailModal(false);
      refetch();
      SuccessNotification("You have successfully closed the game ");
    },
    onError: () => ErrorNotification("An error occurred while closing game"),
  });

  const checkTime = () => {
    setShowMDetailModal(true);
  };

  useEffect(() => {
    if (games) {
      setGameId(games[0]?.id);
      // console.log("GAMES", games[0]["product"]["properties"]["max_digit"])
    }
  }, [games]);

  const getDatafromRow = (value) => {
    let id = value?.tableData[0][0];

    setupGameofDay({
      variables: {
        gameId: id,
        action: true,
      },
    });
  };

  const getDatafromRow2 = (value) => {
    let id = value?.tableData[0][0];
    setSelectedGameId(id);
  };

  const getRowDataNew = (value) => {
    // console.log("R_VAL", value)
    if (value != null) {
      localStorage.setItem('evl_gameId', value);
    }
  };

  const options = {
    filterType: "checkbox",
    filter: false,
    print: false,
    customToolbarSelect: () => { },
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "closed_at",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "event_code",
      label: "Event No.",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 50 }}>{val}</p>;
        },
      },
    },
    {
      name: "product",
      label: "Game Name",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: "relative", left: 30 }}>{val.name}</p>;
        },
      },
    },
    {
      name: "product",
      label: "EVENT DAY",

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: "relative", left: 30 }}>
              {val.schedule_day[0]}
            </p>
          );
        },
      },
    },
    {
      name: "schedule_start",
      label: "SCHEDULED DATE",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return <div>{formatDateShort(val)}</div>;
        },
      },
    },
    {
      name: "run_start",
      label: "START TIME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "run_end",
      label: "END TIME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "game_of_day",
      label: "Game of day",
      align: "right",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val == true ? (
                <div
                  className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center"
                  style={{ marginLeft: 20 }}
                >
                  <p className="text-green mt-4">{"Yes"}</p>
                </div>
              ) : val === false ? (
                <div
                  className="px-3  py-1 mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center"
                  style={{ marginLeft: 20 }}
                >
                  <p className="text-orange mt-4">{"No"}</p>
                </div>
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) => {
          return (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: 5 }}
                disabled={tableMeta?.rowData[1] == null ? false : true}
                className="btn btn-sm btn-outline-success font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  checkTime();
                  getDatafromRow2(tableMeta);
                }}
              >
                Close game
              </button>

              <button
                style={{ marginRight: 5 }}
                className="btn btn-sm btn-outline-warning font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  getDatafromRow(tableMeta);
                }}
              >
                Disable game
              </button>

              <button
                style={{ marginRight: 5 }}
                disabled={tableMeta?.rowData[1] == null ? false : true}
                className="btn btn-sm btn-outline-danger font-loader"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMDetailModal2(true);
                  getDatafromRow2(tableMeta);
                }}
              >
                Terminate game
              </button>
            </div>
          );
        },
      },
    },
  ];

  // console.log("Columns", columns)
  console.log("Options", games)
  return (
    <div>
        {games.length > 0 ? (
          <>
          {/* <table className="table table-bordered table-stripped table-sm table-responsive"> */}
          <table className="table table-bordered table-stripped table-responsive">
            <tr>
              <th colSpan={8}>&nbsp;</th>
            </tr>
            <tr>
              <th>Event Code</th>
              <th>Game Name</th>
              <th>Event Day</th>
              <th>Schedule Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Game of Day</th>
              <th>Actions</th>
            </tr>
            <>
              {games.map((item, index) => (
                <tr>
                  <td>{item["event_code"]}</td>
                  <td>{item["product"]["name"]}</td>
                  <td>{item["product"]["schedule_day"][0].toUpperCase()}</td>
                  <td>{item["schedule_start"]}</td>
                  <td>{item["run_start"]}</td>
                  <td>{item["run_end"]}</td>
                  <td>{item["game_of_day"]}</td>
                  <td>
                    <button
                      style={{ marginRight: 5 }}
                      disabled={item["id"] != null ? false : true}
                      className="btn btn-sm btn-outline-success font-loader"
                      onClick={(e) => {
                        e.preventDefault();
                        checkTime();
                        // getDatafromRow2(item["id"]);
                        getRowDataNew(item["id"]);
                      }}
                    >
                      Close&nbsp;Game
                    </button>
                    {/* <button className="btn btn-sm btn-outline-danger">Terminate</button> */}
                    <button
                      style={{ marginRight: 5 }}
                      className="btn btn-sm btn-outline-warning font-loader"
                      onClick={(e) => {
                        e.preventDefault();
                        // getDatafromRow(tableMeta);
                      }}
                    >
                      Disable&nbsp;Game
                    </button>
                    <button
                      style={{ marginRight: 5 }}
                      // disabled={tableMeta?.rowData[1] == null ? false : true}
                      className="btn btn-sm btn-outline-danger font-loader"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowMDetailModal2(true);
                        // getDatafromRow2(tableMeta);
                      }}
                    >
                      Terminate&nbsp;Game
                    </button>
                  </td>
                </tr>
              ))}
            </>
          </table>

            <div style={{ padding: 20 }}>
              <AdminModal
                show={showDetailModal}
                close={!showDetailModal}
                title={"Close game here"}
                body={<CloseGameMultiple id={game_id} detail={games} closeGameofDay={closeGameofDay} />}
                handleClose={handleclose2}
              />
              <AdminModal
                show={showDetailModal2}
                close={!showDetailModal2}
                title={"Terminate game"}
                body={
                  <TerminateGameComponent
                    id={selectedGameId ?? game_id}
                    terminateGame={terminateGame}
                    setShowMDetailModal2={setShowMDetailModal2}
                  />
                }
                handleClose={handleclose3}
              />
            </div>
          </>
        ) 
        : (<div className="alert alert-danger" role="alert">
            <p>Sorry! No events are available at the moment.</p>
          </div>)}
    </div>
    // <div style={{ padding: 20 }}>
    //   <AdminModal
    //     show={showDetailModal}
    //     close={!showDetailModal}
    //     title={"Close game here"}
    //     body={<CloseGameMultiple id={game_id} detail={games} closeGameofDay={closeGameofDay} />}
    //     handleClose={handleclose2}
    //   />
    //   <AdminModal
    //     show={showDetailModal2}
    //     close={!showDetailModal2}
    //     title={"Terminate game"}
    //     body={
    //       <TerminateGameComponent
    //         id={selectedGameId ?? game_id}
    //         terminateGame={terminateGame}
    //         setShowMDetailModal2={setShowMDetailModal2}
    //       />
    //     }
    //     handleClose={handleclose3}
    //   />

    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "space-between",
    //       marginBottom: 5,
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         float: "right",
    //         width: "50%",
    //       }}
    //     >
    //       <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
    //         <div className="input-group mb-3">
    //           <div className="input-group-prepend">
    //             <span className="input-group-text">From</span>
    //           </div>
    //           <input
    //             type="date"
    //             className="form-control"
    //             placeholder="Some text"
    //           />
    //         </div>
    //       </div>

    //       <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
    //         <div className="input-group mb-3">
    //           <div className="input-group-prepend">
    //             <span className="input-group-text">To</span>
    //           </div>
    //           <input
    //             type="date"
    //             className="form-control"
    //             placeholder="Some text"
    //           />
    //         </div>
    //       </div>

    //       <div style={{ marginRight: 15, width: "100%", marginBottom: 10 }}>
    //         <button className="btn btn-outline-info" style={{ height: 36 }}>
    //           <BiFilter size={21} /> Filter
    //         </button>
    //       </div>
    //     </div>
    //   </div>

    //   {eventLoading ? (
    //     <div
    //       style={{
    //         height: "100vh",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Spinner />
    //     </div>
    //   ) : (
    //     <MUIDataTable
    //       data={games && games}
    //       title={""}
    //       columns={columns}
    //       options={options}
    //     // onRowClick={handleRowClick}
    //     />
    //   )}
    // </div>
  );
};

export default Index;
