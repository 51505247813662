import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import Spinner from '../../components/Spinner';
import './index.css';
import { GET_REGIONAL_BALANCE } from '../../Graphql/Queries';
import AdminModal from '../../components/Modal';
import { ErrorNotification } from '../../components/Notification';

const RegionalBalance = () => {
  // const [loading, setLoading] = useState(false);
  const [showDetailModal, setShowMDetailModal] = useState(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [paild, setPaid] = useState([]);
  const [unpaild, setUnPaid] = useState([]);

  const { loading: regionLoading } = useQuery(GET_REGIONAL_BALANCE, {
    onCompleted: (data) => {
      setPaid(
        data.view_regional_agent_commissions.filter(
          (item) => item.is_commission_paid === true,
        ),
      );
      setUnPaid(
        data.view_regional_agent_commissions.filter(
          (item) => item.is_commission_paid === false,
        ),
      );
    },

    onError: () => {
      ErrorNotification('Failed to load regional commission data');
    },
  });

  const options = {
    filter: false,
    print: false,

    // customToolbarSelect: () => { },
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },

    {
      name: 'name',
      label: 'Region',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'total_commissions',
      label: 'Total Amount(GHS)',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {val.toFixed(2)}
            </p>
          );
        },
      },
    },
  ];

  const unpaidcolumns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },

    {
      name: 'name',
      label: 'Region',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'total_commissions',
      label: 'Total Amount(GHS)',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {val.toFixed(2)}
            </p>
          );
        },
      },
    },

    {
      name: 'region_id',
      label: 'Action',
      options: {
        filter: true,
        sort: true,
        customBodyRender: () => {
          return (
            <button className="btn btn-success" style={{ cursor: 'pointer' }}>
              Click to view more
            </button>
          );
          // return <button onClick={() => { localStorage.setItem("selected-region", val); history.push("/admin/agent-balance-details") }} className="btn btn-success" style={{ cursor: "pointer" }}>Click to view more</button>
        },
      },
    },
  ];

  // const handleRowClick = (rowData) => {
  //     // let chosenItem = finobject(rawData, rowData[0]);
  //     // if (chosenItem.policy_request == null) {
  //     //   history.push({
  //     //     pathname: "/admin/image-preview",
  //     //     state: {
  //     //       // location state
  //     //       data: chosenItem,
  //     //     },
  //     //   });
  //     // }
  // };

  return (
    <div style={{ padding: 20 }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Close game here'}
        body={<></>}
        handleClose={handleclose2}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      ></div>

      {regionLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MUIDataTable
            data={paild}
            title={'COMMISSION PAID'}
            columns={columns}
            options={options}
            // onRowClick={handleRowClick}
          />

          <div style={{ marginTop: 20 }}>
            <MUIDataTable
              data={unpaild}
              title={'COMMISSION UNPAID'}
              columns={unpaidcolumns}
              options={options}
              // onRowClick={handleRowClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionalBalance;
