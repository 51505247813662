import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Form } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import './index.css';

const Report = () => {
	let loading = false;
	const reportTypes = [
		{ id: 1, report_item: 'STAKES' },
		{ id: 2, report_item: 'COMMISSIONS' },
		{ id: 3, report_item: 'WRITERS' },
		{ id: 4, report_item: 'PERFORMANCE' },
	];

	const options = {
		filterType: 'checkbox',
		filter: false,
		print: false,
		responsive: 'scroll',
		// onRowClick: handleRowClick,
		// onRowSelectionChange: (e, i, v) => setSelectedRows(v),
		customToolbarSelect: () => {},
		sortOrder: {
			name: 'created_at',
			direction: 'desc',
		},
	};

	const tableData = [
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
		{
			id: '100032333-2443-34556fdfffgdggfdfg',
			name: 'Paul Dwamena',
			email: 'test@gmail.com',
			phone: '05093873827',
			state: 'ACTIVE',
			created_at: '25th January 2018',
		},
	];

	const columns = [
		{
			name: 'id',
			label: 'Customer ID',
			options: {
				filters: true,
				sort: true,
			},
		},

		{
			name: 'name',
			label: 'Full Name',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return val;
				},
			},
		},

		{
			name: 'email',
			label: 'Email',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return val;
				},
			},
		},
		{
			name: 'phone',
			label: 'Phone Number',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return val;
				},
			},
		},

		{
			name: 'state',
			label: 'Status',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return (
						<div>
							{val === 'ACTIVE' ? (
								<div className="divheight bg-greenLight">
									<p className="text-green">{val}</p>
								</div>
							) : val === 'INACTIVE' ? (
								<div className="divheight bg-blueLight">
									<p className="text-red">{val}</p>
								</div>
							) : val === 'REJECTED' ? (
								<div className="divheight bg-orangeLight">
									<p className="text-red">{val}</p>
								</div>
							) : null}
						</div>
					);
				},
			},
		},

		{
			name: 'created_at',
			label: 'Joined on',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (val) => {
					return val;
				},
			},
		},
	];

	// const handleRowClick = (rowData) => {
	// let chosenItem = finobject(rawData, rowData[0]);
	// if (chosenItem.policy_request == null) {
	//   history.push({
	//     pathname: "/admin/image-preview",
	//     state: {
	//       // location state
	//       data: chosenItem,
	//     },
	//   });
	// }
	// };

	return (
		<div style={{ padding: 20 }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginBottom: 5,
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
					<p
						style={{
							width: '10%',
							marginTop: 15,
							fontWeight: 600,
							fontSize: 15,
						}}
					>
            Filter by:
					</p>
					<div style={{ width: '30%', marginBottom: 10 }}>
						<Form.Control
							required
							as="select"
							type="select"
							// onInput={(e) => setProduct(e.target.value)}
							style={{
								backgroundColor: 'white',
								color: '#37517e',
								border: 'solid',
								width: '100%',
								height: '45px',
							}}
						>
							{reportTypes?.map((m) => (
								<option key={m.id} value={m.id}>
									{m.report_item}
								</option>
							))}
						</Form.Control>
					</div>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
					<div style={{ marginRight: 15, width: '100%', marginBottom: 10 }}>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">From</span>
							</div>
							<input
								type="date"
								className="form-control"
								placeholder="Some text"
							/>
						</div>
					</div>

					<div style={{ marginRight: 15, width: '100%', marginBottom: 10 }}>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">To</span>
							</div>
							<input
								type="date"
								className="form-control"
								placeholder="Some text"
							/>
						</div>
					</div>
				</div>
			</div>

			{loading ? (
				<div
					style={{
						height: '100vh',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner />
				</div>
			) : (
				<MUIDataTable
					data={tableData}
					title={''}
					columns={columns}
					options={options}
					// onRowClick={handleRowClick}
				/>
			)}
		</div>
	);
};

export default Report;
