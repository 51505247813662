import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import { formatDateFull } from '../../utils';
import { GETALLWINNINGS } from '../../Graphql/Queries';
import Spinner from '../../components/Spinner';
import './index.css';
import { ErrorNotification } from '../../components/Notification';

const WinnersReport = () => {
  const [tableData, setTableData] = useState();
  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    // onRowClick: handleRowClick,
    // onRowSelectionChange: (e, i, v) => setSelectedRows(v),
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const { loading: winnersLoading } = useQuery(GETALLWINNINGS, {
    onCompleted: (data) => {
      setTableData(data.view_all_winnings);
    },

    onError: () => ErrorNotification('Failed to load winners'),
  });

  const columns = [
    {
      name: 'full_name',
      label: 'Full Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: 'phone_number',
      label: 'Phone Number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: 'game_name',
      label: 'Game Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: 'event_code',
      label: 'Event Code',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div style={{ position: 'relative', left: '50px' }}>{val}</div>
          );
        },
      },
    },

    {
      name: 'stake_type',
      label: 'Stake Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return val;
        },
      },
    },

    {
      name: 'winning_amount',
      label: 'Winning Amount(GHS)',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div style={{ position: 'relative', left: '50px' }}>{val}</div>
          );
        },
      },
    },

    {
      name: 'created_at',
      label: 'Date won',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return formatDateFull(val);
        },
      },
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: 30 }}>
      {winnersLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={tableData}
          title={''}
          columns={columns}
          options={options}
        />
      )}

      {/* <div
                style={{ display: 'flex', flexDirection: 'row', float: 'right', marginTop: 20 }}
            >
                <button

                    className='btn btn-success mr-2'
                    onClick={() => {
                        alert("Hello")
                    }}
                >
                    <img style={{ position: 'relative', top: -1 }} />{' '}
                    {loading ? 'loading' : 'Make payment'}
                </button>


            </div> */}
    </div>
  );
};

export default WinnersReport;
