import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';

import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import { useMutation, useSubscription } from '@apollo/client/react';

import { formatDateFull } from '../../utils';
import Spinner from '../../components/Spinner';
import AdminModal from '../../components/Modal';
import { BsPlus } from 'react-icons/bs';
import { SUBSCRIPTION_OF_GAMES } from '../../Graphql/Queries';
import { CREATE_SCHEDULE } from '../../Graphql/Mutations';
import Go_back from '../../assets/arrow-left.svg';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useNavigate } from "react-router-dom";

const EventList = () => {
  // const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowMDetailModal] = useState(false);
  // const handleclose = () => setShowModal(false);
  const handleclose2 = () => setShowMDetailModal(false);
  const [games, setGames] = useState();
  const productItem = JSON.parse(localStorage.getItem('prod_obj'));

  // const history = useHistory();
  const navigate = useNavigate();
  const [startdate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [active, setActive] = useState('true');

  // let [gameSwitch, setgameSwitch] = useState(true);

  // const handleChange = () => {
  //   return setgameSwitch(!gameSwitch);
  // };

  // const {
  //   data: EventData,
  //   loading: EventDataLoading,
  //   error: EventDataError,
  //   refetch,
  // } = useQuery(GET_EVENTS_OF_PRODUCT, {
  //   variables: {
  //     productID: productItem.id,
  //   },
  // });

  const { loading: subscriptionLoader } = useSubscription(
    SUBSCRIPTION_OF_GAMES,
    {
      variables: { productID: productItem.id },
      onComplete: (data) => {
        setGames(data.game);
      },

      onError: () => {
        ErrorNotification('Failed to load subscription data');
      },
    },
  );

  const [CreateEvent, { loading: updateAdminLoading }] = useMutation(
    CREATE_SCHEDULE,
    {
      variables: {
        created_by: localStorage.getItem('adminId'),
        is_active: active === 'true' ? true : false,
        product_id: productItem.id,
        start_at: startdate,
        end_at: endDate,
      },

      onCompleted: () => {
        setShowMDetailModal(false);
        SuccessNotification('Events added successfully');
        // console.log(data)
      },
      onError: () => ErrorNotification('An error occurred when creating event'),
    },
  );

  // const handleRowClick = (rowData) => {};

  const options = {
    filterType: 'checkbox',
    // onRowClick: handleRowClick,
    sortOrder: {
      name: 'event_code',
      direction: 'desc',
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'event_code',
      label: 'Event number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 40 }}>{val}</p>;
        },
      },
    },
    {
      name: 'product',
      label: 'Event day',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 40 }}>{val.schedule_day}</p>
          );
        },
      },
    },
    {
      name: 'schedule_start',
      label: 'Scheduled start date',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return <div>{formatDateFull(val)}</div>;
        },
      },
    },
    {
      name: 'schedule_end',
      label: 'Scheduled end date',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return <div>{formatDateFull(val)}</div>;
        },
      },
    },
    {
      name: 'run_start',
      label: 'Start time',
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'run_end',
      label: 'End time',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'game_of_day',
      label: 'Game of day',
      align: 'right',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val == true ? (
                <div className="px-3 py-1 mt-2 divheight bg-greenLight rounded-12 flex items-center justify-center">
                  <p className="text-green">{'True'}</p>
                </div>
              ) : val === false ? (
                <div className="px-4  mt-2 divheight bg-orangeLight rounded-12 flex items-center justify-center">
                  <p className="text-orange">{'False'}</p>
                </div>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div
      style={{
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        padding: 30,
      }}
    >
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Create New Events for ' + productItem.name}
        body={
          <NewEvent
            setStartDate={setStartDate}
            CreateEvent={CreateEvent}
            startdate={startdate}
            enddate={endDate}
            setActive={setActive}
            active={active}
            setEndDate={setEndDate}
            updateAdminLoading={updateAdminLoading}
            lastItem={games?.slice(-1)[0]}
          />
        }
        handleClose={handleclose2}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          style={{ cursor: 'pointer' }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
          onClick={navigate(-1)}
        >
          {' '}
          <img alt="" src={Go_back} />
        </button>
        <div style={{ marginBottom: 20 }}>
          <button
            className="btn btn-info"
            onClick={() => setShowMDetailModal(true)}
          >
            <BsPlus
              size={20}
              style={{ position: 'relative', top: -1, fontWeight: 900 }}
            />{' '}
            Add new event
          </button>
        </div>
      </div>
      {subscriptionLoader ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={games}
          title={'Event List'}
          columns={columns}
          options={options}
          // onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

export default EventList;

const NewEvent = ({
  // setContent,
  CreateEvent,
  updateAdminLoading,
  setStartDate,
  setEndDate,
  startdate,
  enddate,
  setActive,
  active,
  lastItem,
}) => {
  // console.log(lastItem);
  // const [checked, setChecked] = useState([]);

  // const tableItems = [
  //   {
  //     id: 3,
  //     itemName: "Scheduled date",
  //     inputType: "date",
  //   },
  //   {
  //     id: 4,
  //     itemName: "Start time",
  //     inputType: "time",
  //   },
  //   {
  //     id: 5,
  //     itemName: "End time",
  //     inputType: "time",
  //   },
  //   // {
  // ];

  // const handleCheck = (event) => {
  //   var updatedList = [...checked];
  //   let items = [];

  //   if (event.target.checked) {
  //     tableItems.map((item) => {
  //       if (item.id == event.target.value) {
  //         items.push(item);
  //       }
  //     });

  //     updatedList = [...checked, items];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  // };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p style={{ marginLeft: 10, fontSize: 16 }}>
          New events valid after {lastItem && lastItem.schedule_start}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
          <div style={{ width: '100%' }}>
            <label>
              Start date
              <input
                type="date"
                value={startdate}
                onChange={(evnt) => setStartDate(evnt.target.value)}
                name="schedule_start"
                className="form-control font-loader"
              />{' '}
            </label>
            <p style={{ fontSize: 10, color: '#808080', marginTop: 5 }}>
              Please select date that correspond to day of game
            </p>
          </div>
          <div style={{ width: '100%' }}>
            <label>
              End date
              <input
                type="date"
                value={enddate}
                onChange={(evnt) => setEndDate(evnt.target.value)}
                name="schedule_start"
                className="form-control font-loader"
              />{' '}
            </label>
            <p style={{ fontSize: 10, color: '#808080', marginTop: 5 }}>
              Please select date that correspond to day of game
            </p>
          </div>
        </div>

        <div style={{ width: '50%', padding: 10 }}>
          <label>
            Is game active
            <select
              value={active}
              onChange={(e) => {
                setActive(e.target.value);
              }}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <p style={{ fontSize: 10, color: '#808080', marginTop: 5 }}>
            This indicate whether game should be made active
          </p>
        </div>
      </div>

      <hr />

      <div
        style={{
          display: 'flex',
          float: 'right',
          justifyItems: 'self-end',
          justifyContent: 'space-between',
          marginRight: 15,
        }}
      >
        <p></p>
        <button
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            CreateEvent();
          }}
        >
          <span style={{ marginLeft: 3, paddingTop: 1, marginTop: 10 }}>
            {updateAdminLoading ? 'loading...' : ' Create Event'}
          </span>
        </button>
      </div>
    </div>
  );
};
