import React from 'react';

const TermsAndConditonContent = ({ state, setCheck, closeModal }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">INTRODUCTION</h1>
          <p>
            Please read the following Terms and Conditions carefully as they are
            the legal terms you agree to abide by when you access and open an
            account to play our lottery games. We strongly recommend that you
            print a copy of these Terms and Conditions for your records.
          </p>
          <p>
            These interactive terms and conditions (the &rdquo;Terms and
            Conditions&rdquo;) set out the various rules and procedures that you
            will need to follow to access and use our lottery platforms and to
            open and maintain your Player account. These Terms and Conditions
            also provide information on staking games. The game rules are
            available on our website at www.megafortunelottery.com.
          </p>
          <p>
            Our lottery games can be accessed through our website, App, and USSD
            short code (collectively called Mega Fortune Lottery Platforms).
            This gaming platform is owned and operated by Fortune Synergy.
          </p>
          <p>
            Mega Fortune Lottery is licensed by the National Lottery Authority
            (NLA) of Ghana.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">PAY-OUTS</h1>
          <p>
            All payouts arising from the play of Mega Fortune Lottery games
            shall be done electronically through the Mobile Wallets of Players.
            However, the Players acknowledge that we are at the mercy of other
            Payment Service Providers; in this regard, Mega Fortune Lottery
            shall not be held liable for any delays in the electronic transfer
            of the Payouts.
          </p>
          <p>
            Mega Fortune Lottery reserves the right to carry out additional
            verification procedures for payouts exceeding GHS 5,000 or when
            there is a suspicion of fraud.
          </p>
          <p>
            Mega Fortune Lottery reserves the right to limit or to refuse a
            Player from playing any of its lotto/lottery games.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">FOR REGISTERED AND UNREGISTERED USERS</h1>
          <h2 className="text-semibold">Unregistered Users</h2>
          <p>
            If you simply want to browse around our website, there is no need to
            register with Us. However, by accessing and using our website, you
            agree to read, understand and agree to the Terms and Conditions. If
            you do not agree to accept and abide by these Terms and Conditions,
            you should not use our website and by extension, any of our gaming
            platforms.
          </p>
          <h2 className="text-semibold">Registered Users</h2>
          <p>
            When registering as a Player, you will be asked to confirm if you
            have read and agree to the Terms and Conditions set out applying to
            registered users. If you do not agree to accept and agree to these
            Terms and Conditions, you should not use any of our gaming
            platforms.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">USE OF OUR LOTTERY PLATFORMS</h1>
          <p>
            All Mega Fortune Lottery Platforms are controlled and operated by
            Fortune Synergy. The information contained therein is solely for the
            use of players and prospective players. Please note: For the full
            suite of available MFL Games, please visit
            www.megafortunelottery.com.
          </p>
          <p>
            In accessing our lottery platforms, you do so at your own risk and
            on your initiative and are responsible for compliance with all
            applicable laws. Use of our lottery platforms and/or purchase of any
            games offered unlawfully constitutes a breach of these Terms and
            Conditions and may result in, amongst other penalties, the immediate
            termination of your Account and your right to use our website.
          </p>
          <p>
            All use by you of our lottery platforms is at your own risk. You
            assume complete responsibility for, and for all risk of loss
            resulting from, downloading and/or using of, or referring to, or
            relying on the content of or any other information obtained from
            your use. You agree that MFL will not be liable for damages arising
            out of your use or your inability to use our lottery platforms, and
            you hereby waive all claims with respect thereto, whether based on
            contract, tort, or other grounds.
          </p>
          <p className="text-bold">
            You agree not to use this Website to engage in any unlawful
            activities including without limitation to activities which:
          </p>
          <p>
            (a) infringe the proprietary or intellectual property rights of MFL
            and/or its licensors and/or third parties;
          </p>
          <p>(b) compromise the privacy of others;</p>
          <p>(c) are immoral, illegal, or advocating illegal activity;</p>
          <p>
            (d) destroy the integrity of any data or other computer-based
            information;
          </p>
          <p>(e) violate local, state, or national laws of any country;</p>
          <p>
            (f) reveal trade secrets unless you own them or have the permission
            of the copyright owner;
          </p>
          <p>(g) infringe on any privacy or publicity rights of others;</p>
          <p>(h) impersonate another person.</p>
          <p className="text-bold">You also agree not to:</p>
          <p>
            (i) interfere with or disrupt (or attempt to interfere with or
            disrupt) our lottery platforms or servers or networks connected to
            the same or to disobey any requirements, procedures, policies or
            regulations of networks connected to our lottery platforms;
          </p>
          <p>
            (j) provide any information to MFL that is false or misleading, that
            attempts to hide your identity or that you do not have the right to
            disclose;
          </p>
          <p>
            (k) use our lottery platforms in any manner which could damage,
            disable, overburden or impair the same while interfering with any
            other party&apos;s use and/or enjoyment of our lottery platforms;
          </p>
          <p>
            (l) attempt to gain unauthorized access to any account(s), computer
            systems and networks connected to any MFL lottery platforms, its
            facilities and/or services through hacking, password mining or any
            other means;
          </p>
          <p>
            (m) use our lottery platforms to impersonate any person or entity or
            misrepresent your affiliation with a person or entity.
          </p>
          <p>
            You hereby agree to indemnify and hold harmless, MFL against any
            claims, proceedings and actions taken and all damages (including
            costs) that may be awarded or agreed to be paid to any third party
            in respect of any claim or action arising from your breach of this
            condition or any of the items listed.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">DISCLAIMERS</h1>
          <p>
            Our Lottery Platforms Are Available To All Users “As Is” And, To The
            Greatest Extent Permitted By Applicable Law, All Mfl Gaming
            Platforms Are Made Available Without Any Representations Or
            Warranties Of Any Kind, Either Express Or Implied. Mfl Makes No
            Representations, Warranties Or Undertakings That Our Lottery
            Platforms, Or The Server That Makes It Available, Will Be Free From
            Defects, Including, But Not Limited To Viruses Or Other Harmful
            Elements. Mfl Accepts No Liability For Any Infection By Computer
            Virus, Bug, Tampering, Unauthorised Access, Intervention, Alteration
            Or Use, Fraud, Theft, Technical Failure, Error, Omission,
            Interruption, Deletion, Defect, Delay, Or Any Event Or Occurrence
            Beyond The Control Of Mfl, Which Corrupts Or Affects The
            Administration, Security, Fairness And The Integrity Or Proper
            Conduct Of Any Aspect Of Our Lottery Platforms. Mfl Makes No
            Representations, Warranties Or Undertakings About The Service,
            Facilities And/Or Materials On Our Lottery Platforms (Including
            Without Limitation, Their Accuracy, Completeness Or Their
            Merchantability, Quality Or Fitness For A Particular Purpose).
          </p>
          <p>
            You Agree That The Download Or Upload Of Any Material Through Our
            Lottery Platforms Is Performed At Your Discretion And Risk And That
            You Will Be Solely Responsible For Any Loss Or Damage To Your
            Computer System Or Loss Of Data That May Result In The Download Or
            Upload Of Any Material. All Use By You Of Our Lottery Platforms Is
            At Your Own Risk. You Assume Complete Responsibility For, And For
            All Risk Of Loss Resulting From, Downloading And/Or Using Of,
            Referring To Or Relying On The Facilities Or Materials Provided On
            Our Lottery Platforms. You Agree That Mfl And Providers Of
            Telecommunications And Network Services To Mfl Will Not Be Liable
            For Damages Arising Out Of Your Use Or Your Inability To Use Our
            Lottery Platforms, And You Hereby Waive Any And All Claims With
            Respect Thereto, Whether Based On Contract, Or Other Grounds.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">LIMITATIONS OF LIABILITY</h1>
          <p>
            To the maximum extent permitted by applicable law, neither MFL nor
            any of its officers, directors, employees, agents, affiliates or
            other representatives will be liable for loss or damages arising out
            of or in connection with your use of our lottery platforms
            including, but not limited to, direct, indirect, incidental, special
            or consequential loss or damages, loss of data, loss of income,
            profit or opportunity, loss of or damage to property and claims of
            third parties, even if MFL has been advised of the possibility of
            such loss or damages, or such loss or damages were reasonably
            foreseeable. In no event shall MFL nor any of its officers,
            directors, agents, employees, affiliates, or other representatives
            be liable for any loss or damages whatsoever resulting from the
            statements or conduct of any third party or the interruption,
            suspension, or termination of our lottery platforms, whether such
            interruption, suspension or termination was justified or not,
            negligent or intentional, inadvertent or advertent.
          </p>
          <p>
            Without limiting the foregoing, under no circumstances shall MFL or
            any of its officers, directors, agents, employees, affiliates or
            other representatives be held liable for any delay or failure in
            performance resulting directly or indirectly from acts of nature,
            forces or causes beyond its reasonable control, including, without
            limitation, internet failure, computer equipment failures,
            telecommunication failures, other equipment failures, electrical
            power failures, strikes, riots, interactions, civil disturbances,
            shortages of labour or materials, fires, storms, explosions, force
            majeure, war, governmental actions, orders of domestic or foreign
            courts or tribunals, delays, losses, errors or omissions made by the
            postal or other delivery services or the banking system, or the
            non-performance of a third party.
          </p>
          <p>
            MFL does not exclude liability for fraud, death, or personal injury
            caused by our negligence (or that of our employees or authorized
            representatives). In any event, your sole remedy under these Terms
            and Conditions will be the reimbursement to you of the monies paid
            by you in respect of the relevant game.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">INDEMNITY</h1>
          <p>
            You agree to defend, indemnify and hold MFL (and as applicable, its
            officers, directors, employees, agents, licensors, subsidiaries,
            affiliates and any of our third-party information service providers)
            harmless against any demands, losses, expenses, damages and costs,
            including legal costs, and claims made by or liabilities to any
            third party resulting from any activities conducted under your
            account, your use or misuse of our lottery platforms and, including
            but not limited to posting content/playing games on our lottery
            platforms, infringing any third party&apos;s intellectual property
            or other rights, or otherwise arising out of your breach of these
            Terms and Conditions.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">INTELLECTUAL PROPERTY RIGHTS</h1>
          <p>
            The information, content, graphics, text, sounds, images, buttons,
            trademarks, service marks, trade names and logos (the
            &rdquo;Materials&rdquo;) contained in this Website are protected by
            copyright, trademark, database right, and other intellectual
            property laws, under national laws. You are granted a limited
            license solely for your own personal, non-commercial use to refer
            to, bookmark or point to any page within our lottery platforms, to
            download the Materials contained on our lottery platforms to an
            internet-enabled device, and to print a single hard copy of the
            Materials contained on our lottery platforms for your reference,
            provided however that all copyright, trademark and other proprietary
            notices are left intact. The grant of this limited license is
            conditional on your agreement to, and compliance with the Terms and
            Conditions. Any other use of the Materials from our lottery
            platforms, including any form of copying or reproduction (for any
            purposes other than those noted above) modification, distribution,
            republication, extraction, re-utilization, incorporation or
            integration with other materials or works or re-delivery using
            framing technology, without the prior written permission of MFL, is
            strictly prohibited and violates the proprietary rights of MFL.
          </p>
          <p>
            Other rules may apply to certain software and other items provided
            as part of our lottery platforms. Any special rules will be listed
            as “legal notices” on our website and are incorporated into these
            Terms and Conditions by this reference. All software that is made
            available for downloading from our website (“Software”) is protected
            by copyright and may be protected by other rights. You may only use
            such Software to use our lottery platforms, and may not copy,
            reproduce, distribute, or create derivative works based on the
            Software. Except as expressly permitted by applicable law, you agree
            not to disassemble or otherwise reverse engineer any of the Software
            for any reason or permit any third party to do so. The use of such
            Software may also be governed by the terms in the software license
            agreement or applicable “legal notice” accompanying such Software.
            The downloading and use of such Software is conditional on your
            agreement to be bound by the terms of the relevant software license
            agreement or designated “legal notice”.
          </p>
          <p>
            Hypertext links to this Website are prohibited without the prior
            written consent of MFL. All logos and all other trademarks
            registered by MFL are trademarks of MFL. All other trademarks,
            service marks, trade names and logos contained in this Website
            belong to their respective owners. MFL and/or its licensors (as the
            case may be) retains all right, title, interest, and intellectual
            property rights in and to the Materials and the Software. Nothing in
            these Terms and Conditions shall be construed as conferring by
            implication, estoppel or otherwise, any license or right under any
            copyright, patent, trademark, database right, or other intellectual
            property or proprietary interest of MFL, its affiliates or any third
            party.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">AVAILABILITY</h1>
          <p>
            Although MFL endeavors to ensure our lottery platforms are available
            during gaming hours as determined by MFL from time to time, there
            may be occasions when interruption may occur, e.g., to allow
            maintenance, upgrades, and emergency repairs to take place, or due
            to the failure of telecommunications links and equipment that are
            beyond our control. You agree that MFL shall not be liable to you
            for any loss incurred by you resulting from the modification,
            suspension, or discontinuance of our lottery platforms.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">LINKS TO OTHER WEBSITES</h1>
          <p>
            MFL makes no representations, warranties, or undertakings about any
            content of or information on any other website referred to or
            accessed by a hypertext link through our lottery platforms or from
            which our website is referred to or accessed by a hypertext link
            (&rdquo;third party site&rdquo;). MFL does not endorse or approve
            the content of any third-party site, nor will MFL have any liability
            in connection with any third-party site (including but not limited
            to liability arising out of any allegation that the content of or
            information on any third-party site infringes any law or the rights
            of any person or entity). No judgment or warranty is made concerning
            the accuracy, timeliness, or suitability of the content of any
            third-party site, and any liability that might arise out of, or in
            connection with, your use or reliance on the content of or
            information on, or the performance of, any third-party site is
            excluded. Nor can MFL guarantee that the controller of any
            third-party website will respect your privacy in the same manner as
            MFL.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">AMENDMENT OF TERMS</h1>
          <p>
            MFL reserves the right to change the content, presentation,
            performance, use, and facilities and availability of any part of our
            lottery platforms at its sole discretion and without liability to
            you, including the Terms and Conditions, and you should check these
            Terms and Conditions regularly for any changes. Occasionally MFL
            may:
          </p>
          <p>
            (a) change the technical specifications of our lottery platforms or
            any portion thereof; or
          </p>
          <p>
            (b) suspend our lottery platforms or any portion thereof for
            operational reasons such as repair, maintenance, or improvement of
            the same or any portion thereof or because of an emergency; or
          </p>
          <p>
            (c) change the system resource limits applicable to your use of our
            lottery platforms or any portion thereof.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">WAIVER</h1>
          <p>
            The failure of MFL to exercise or enforce any right or provision of
            the Terms and Conditions shall not constitute a waiver of such right
            or provision. If any provision of the Terms and Conditions is found
            by a court of competent jurisdiction to be invalid, the parties
            nevertheless agree that the court should endeavor to give effect to
            the parties&apos; intentions as reflected in the provision, and the
            other provisions of the Terms remain in full force and effect. The
            Terms and Conditions represent the entire understanding and
            agreement between you and MFL relating to the use of our lottery
            platforms and supersede all prior statements, understandings, or
            agreements whether oral or written.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">DATA PRIVACY STATEMENT</h1>
          <p>
            We respect and protect the privacy of the individuals who access and
            use our lottery platforms. For full details of the type of
            information we collect, how we use it, and under what circumstances,
            refer to our data privacy policy statement on our website.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <h1 className="text-danger">GOVERNING LAW</h1>
          <p>
            The Terms and Conditions shall be governed by and construed by the
            laws of Ghana, and you hereby agree for the benefit of MFL, and
            without prejudice to the right of MFL to take proceedings concerning
            the Terms and Conditions before any other court of competent
            jurisdiction, that the courts of Ghana shall have jurisdiction to
            hear and determine any action or proceedings that may arise out of
            or in connection with the Terms and Conditions, and for such
            purposes, you irrevocably submit to the exclusive jurisdiction of
            such courts.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="d-flex  align-items-end flex-column">
            <div>
              <div className="d-flex align-items-center">
                <input
                  className=""
                  type="checkbox"
                  value={state?.consent_given}
                  onChange={(e) =>
                    setCheck({ ...state, consent_given: e.target.checked })
                  }
                />
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '500',
                  }}
                  className="ml-2"
                >
                  Accept terms and conditions
                </span>
              </div>
            </div>
            {state?.consent_given && (
              <div className="d-flex  align-items-end flex-column">
                <div className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-lg"
                    style={{
                      width: '12rem',
                      fontSize: 16,
                      fontWeight: 'bold',
                      backgroundColor: '#FF7300',
                      color: 'white',
                      borderRadius: 15,
                    }}
                    onClick={closeModal}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditonContent;
