import React from 'react';
import MUIDataTable from 'mui-datatables';
import Spinner from '../../components/Spinner';
import { formatDateFull } from '../../utils';

const ExpandableTable2 = ({ rowData }) => {
  let loading = false;

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    selectableRows: 'none',
    // customToolbarSelect: () => {},
  };

  const columns = [
    {
      name: 'customer',
      label: 'Full name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {val?.full_name}
            </p>
          );
        },
      },
    },
    {
      name: 'staked_by',
      label: 'Staked By',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {val}
            </p>
          );
        },
      },
    },

    {
      name: 'staked_amount',
      label: 'Staked amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {val}
            </p>
          );
        },
      },
    },

    {
      name: 'stake_type',
      label: 'Stake Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {val}
            </p>
          );
        },
      },
    },
    {
      name: 'winning_amount',
      label: 'Winning amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {val?.toString()}
            </p>
          );
        },
      },
    },
    {
      name: 'created_at',
      label: 'Staked at',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'relative',
                left: 5,
                top: 10,
                textTransform: 'capitalize',
              }}
            >
              {formatDateFull(val)}
            </p>
          );
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20, position: 'relative' }}>
      {loading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <MUIDataTable
          data={rowData ? rowData : []}
          title="Stakes"
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
};

export default ExpandableTable2;
