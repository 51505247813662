import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Modal } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../../components/Spinner';
import './index.css';
import addIcon from '../../assets/plus.svg';
import { GET_ALL_DEPARTMENTS, GET_ALL_ADMINS } from '../../Graphql/Queries';
import {
  UPDATE_ADMIN,
  CREATE_ADMIN,
  UPDATE_ADMIN_STATUS,
} from '../../Graphql/Mutations';
import Swal from 'sweetalert2';
import {
  SuccessNotification,
  ErrorNotification,
} from '../../components/Notification';
import bcrypt from 'bcryptjs';

const hashpassword = (password) => {
  var salt = bcrypt.genSaltSync(10);
  var hash = bcrypt.hashSync(password, salt);

  return hash;
};

const columns = [
  {
    name: 'id',
    label: 'Admin Name',
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },
  {
    name: 'name',
    label: 'Admin Name',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'role',
    label: 'Role',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (val) => {
        if (val == null) {
          return 'Staff';
        } else {
          return val;
        }
      },
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'department',
    label: 'Department',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (val) => {
        if (val == null) {
          return 'Staff';
        } else {
          return val.name;
        }
      },
    },
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (val) => {
        return val;
      },
    },
  },
  {
    name: 'is_active',
    label: 'st',
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },

  {
    name: 'department',
    label: 'Department',
    options: {
      filter: true,
      sort: false,
      display: false,
      customBodyRender: (val) => {
        if (val == null) {
          return '07ba9e11-2274-4f74-b8e3-e0802ecd7914';
        } else {
          return val.id;
        }
      },
    },
  },

  {
    name: 'is_active',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (val) => (
        <div>
          {val === true ? (
            <div className="verified" style={{ width: 30 }}>
              <p className="verified-text">Active</p>
            </div>
          ) : (
            <div className="unverified" style={{ width: 30 }}>
              <p className="unverified-text">Inactive</p>
            </div>
          )}
        </div>
      ),
    },
  },
];

const Index = () => {
  const [showModal2, setShowModal2] = useState(false);
  const [show2, setShow2] = useState(false);
  const [action, setAction] = useState(0);
  const [fullname, setfullname] = useState('');
  const [role, setRole] = useState('');
  const [departments, setDepartment] = useState(null);
  const [deptid, setdeptid] = useState(null);
  // const responsive = "simple";
  const [email, setEmail] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [status, setStatus] = useState(true);
  const [admins, setAdmins] = useState(null);
  const [id, setid] = useState(null);
  // const [remarks, setRemarks] = useState("");
  const handleClose2 = () => setShow2(false);
  // const handleShow2 = () => setShow2(true);

  const confirmaction = async () => {
    Swal.fire({
      title: 'Are you sure you want to add a new admin',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,accept',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          CreateAdmin({
            variables: {
              name: fullname,

              email: email,

              phone: phone,

              password: hashpassword('password'),

              department_id: deptid,

              role: role,

              is_active: status,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const [CreateAdmin, { loading: createAdminLoading }] = useMutation(
    CREATE_ADMIN,

    {
      onCompleted: () => {
        setShowModal2(false);
        refetch();
        SuccessNotification('You have successfully created a new admin user');
      },
      onError: (error) => ErrorNotification(error),
    },
  );

  const [UpdateProduct, { loading: updateAdminLoading }] = useMutation(
    UPDATE_ADMIN,
    {
      variables: {
        id: id,

        name: fullname,

        email: email,

        phone: phone,

        department_id: deptid,

        role: role,
      },

      onCompleted: () => {
        setShowModal2(false);
        refetch();
        SuccessNotification('You have successfully updated admin details');
        setAction(0);
      },
      onError: (error) => ErrorNotification(error),
    },
  );

  const [BlockAdmin, { loading: blockAdminLoading }] = useMutation(
    UPDATE_ADMIN_STATUS,
    {
      variables: {
        id: id,
        is_active: status,
      },

      onCompleted: () => {
        setShow2(false);
        setShowModal2(false);
        refetch();
        SuccessNotification('You have successfully deactivatd the account');
      },
      onError: (error) => ErrorNotification(error),
    },
  );

  const callupdateStatus = (status) => {
    try {
      BlockAdmin({
        variables: {
          id: id,
          is_active: status,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const callupdateStatus1 = () => {
    setShow2(true);
    setShowModal2(false);
  };
  const { loading: RequestLoading } = useQuery(GET_ALL_DEPARTMENTS, {
    onCompleted: (data) => {
      setDepartment(data.departments);
    },
    onError: () => {
      ErrorNotification('Query for loading departments failed');
    },
  });

  const { loading: AdminsLoading, refetch } = useQuery(GET_ALL_ADMINS, {
    onCompleted: (data) => {
      setAdmins(data.admin_user);
    },

    onError: () => {
      ErrorNotification('Failed to load all admins');
    },
  });

  const handleRowClick = (c) => {
    setid(c[0]);
    setfullname(c[1]);
    setRole(c[2]);
    setEmail(c[3]);
    setdeptid(c[7]);
    setPhoneNumber(c[5]);
    setStatus(c[6]);
    console.log(c);
    setAction(1);
    setShowModal2(true);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: handleRowClick,

    responsive: 'scrollMaxHeight',

    sortOrder: {
      name: 'Date_Purchased',
      direction: 'desc',
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={true}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Reason for deactivating account</Modal.Title>
          <button
            style={{
              position: 'relative',
              marginTop: 1,
              right: 15,
              width: 10,
              top: -5,
              height: 10,
              borderRadius: '50%',
              backgroundColor: '#DF3F3F33',
            }}
            onClick={() => handleClose2()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span
              style={{
                position: 'absolute',
                marginTop: -11,
                right: 9,
                color: '#DF3F3F',
              }}
              aria-hidden="true"
            >
              &times;
            </span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <form>
            {/* <div class="form-group">
              <textarea
                onChange={(e) => setRemarks(e.target.value)}
                name="Text1"
                cols="40"
                rows="2"
                className="form-control"
              />
            </div> */}

            <button
              onClick={(e) => {
                e.preventDefault();
                callupdateStatus(false);
              }}
              className="btn btn-primary form-control"
            >
              {blockAdminLoading ? <Spinner animation="grow" /> : 'Submit'}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal2}
        onHide={() => setShowModal2(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {action === 0 ? (
              'Add new admin'
            ) : (
              <div style={{ flexDirection: 'column' }}>
                <p>Edit admin details</p>
                <p style={{ fontSize: 12, fontWeight: 600, color: '#0e3e86' }}>
                  Status: {status ? 'active' : 'inactive'}{' '}
                </p>
              </div>
            )}
          </Modal.Title>
          <button
            onClick={() => setShowModal2(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
            position: 'relative',
            top: -30,
          }}
        >
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                {action == 0 ? 'Enter Fullname' : 'Change Admin Name'}
              </label>

              <input
                onChange={(e) => setfullname(e.target.value)}
                type="text"
                className="form-control"
                value={fullname}
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                {action == 0 ? 'Enter Email Address' : 'Change Email Address'}
              </label>

              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                value={email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                {action == 0 ? 'Enter Phone Number' : 'Edit Phone Number'}
              </label>

              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="number"
                className="form-control"
                value={phone}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                {action == 0 ? 'Select admin role' : 'Edit admin role'}
              </label>
              <select
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                defaultValue={role}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="Staff">Staff</option>
                <option value="Business Head">Business Head</option>
                <option value="General Manager">General Manager</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                {action == 0
                  ? 'Select department of admin'
                  : 'Update department of admin'}
              </label>
              <select
                onChange={(e) => {
                  setdeptid(e.target.value);
                }}
                className="form-select"
                defaultValue={deptid}
                aria-label="Default select example"
              >
                {RequestLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    {departments?.map((dept) => (
                      <option key={dept.id} value={dept.id}>
                        {dept.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            {action == 0 ? (
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Select status of admin
                </label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  defaultValue={status}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            ) : null}

            {action == 1 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <button
                  style={{ marginRight: 20 }}
                  onClick={(e) => {
                    e.preventDefault();
                    {
                      status ? callupdateStatus1() : callupdateStatus(true);
                    }
                  }}
                  className="btn btn-danger form-control"
                >
                  {status ? (
                    'Deactivate account'
                  ) : (
                    <>
                      {' '}
                      {blockAdminLoading ? 'Loading....' : 'Activate account '}
                    </>
                  )}
                </button>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    {
                      UpdateProduct();
                    }
                  }}
                  className="btn btn-primary form-control"
                >
                  {updateAdminLoading ? 'Loading...' : 'Update Admin Details'}
                </button>
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  {
                    confirmaction();
                  }
                }}
                className="btn btn-primary form-control"
              >
                {createAdminLoading ? (
                  'Loading...'
                ) : (
                  <>{action === 0 ? 'Add a new admin' : null}</>
                )}
              </button>
            )}
          </form>
        </Modal.Body>
      </Modal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        <button className="btn btn-info" onClick={() => setShowModal2(true)}>
          <img src={addIcon} alt="" style={{ position: 'relative', top: -1 }} />{' '}
          Add Admin
        </button>
      </div>

      {AdminsLoading ? (
        <Spinner />
      ) : (
        <MUIDataTable
          style={{ marginTop: 100 }}
          title={'Admin List'}
          data={admins ?? []}
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
};

export default Index;
