import React, { useState } from 'react';
import { GET_ALL_AGENTS } from '../../Graphql/Queries';
import { Tabs, Tab } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from 'react-icons/bi';

const AgentView = () => {
  // const [agents, setAgents] = useState([]);
  const [pendingAgents, setPendingAgents] = useState([]);
  const [approvedAgentes, setApprovedAgents] = useState([]);
  // const history = useHistory();
  const navigate = useNavigate();

  const {
    // data: agentData,
    loading: agentDataLoading,
  } = useQuery(GET_ALL_AGENTS, {
    onCompleted: (data) => {
      let pending = [];
      let approved = [];

      for (let i = 0; i < data.customer?.length; i++) {
        if (data.customer[i].status !== 'approved') {
          pending.push(data.customer[i]);
        } else {
          approved.push(data.customer[i]);
        }
      }

      setApprovedAgents(approved);
      setPendingAgents(pending);
    },
  });

  // export const dataRefetch = () => {

  // }

  const handleRowClickPending = (dataIndex, rowIndex) => {
    console.log(dataIndex);
   navigate(
      '/admin/agent-details', {
      state: pendingAgents[rowIndex.dataIndex],
    });
  };

  const handleRowClickApproved = (dataIndex, rowIndex) => {
    console.log(dataIndex);
   navigate(
       '/admin/agent-details', {
      state: approvedAgentes[rowIndex.dataIndex],
    });
  };

  const optionsPending = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    selectableRows: false,
    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    onRowClick: handleRowClickPending,
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const optionsApproved = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    selectableRows: false,
    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    onRowClick: handleRowClickApproved,
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const columns = [
    {
      name: 'first_name',
      label: 'First Name',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p
              style={{
                position: 'absolute',
                right: 25,
                backgroundColor: 'red',
              }}
            >
              {val}
            </p>
          );
        },
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: 'email',
      label: 'Email Address',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 25 }}>{val}</p>;
        },
      },
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val === 'null') {
            return 'No phone available';
          } else {
            return val.product.name;
          }
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return <p style={{ position: 'relative', left: 0 }}>{val}</p>;
        },
      },
    },
    // {
    //   name: 'created_at',
    //   label: 'Created at',
    //   option: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (val) => {
    //       console.log("helllo")
    //       return <p style={{ position: "relative", left: 0}}>{"hello"}</p>;

    //     },
    //   }
    // },
    {
      name: 'blocked',
      label: 'Blocked Status',
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val == false) return 'Active';
          else return 'Blocked';
        },
      },
    },
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="pending"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="pending" title="Pending Agents">
          <div
            style={{
              padding: 15,
            }}
          >
            <div
              style={{
                padding: 15,
              }}
            >
              <BiArrowBack
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                 navigate(-1);
                }}
                size={50}
              />
            </div>

            {agentDataLoading ? (
              <CircularProgress
                style={{ position: 'relative', marginTop: '25%', left: '55%' }}
              />
            ) : pendingAgents?.length == 0 ? (
              <div style={{ width: '100vw', textAlign: 'center', margin: 50 }}>
                <h1>There are no pending agents</h1>
              </div>
            ) : (
              <MUIDataTable
                title={'All Pending Agents'}
                data={pendingAgents ? pendingAgents : []}
                columns={columns}
                options={optionsPending}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey="approved" title="Approved Agents">
          <div
            style={{
              padding: 15,
            }}
          >
            <div
              style={{
                padding: 15,
              }}
            >
              <BiArrowBack
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1)
                }}
                size={50}
              />
            </div>

            {agentDataLoading ? (
              <CircularProgress
                style={{ position: 'relative', marginTop: '25%', left: '55%' }}
              />
            ) : (
              <MUIDataTable
                title={'All Approved Agents'}
                data={approvedAgentes ? approvedAgentes : []}
                columns={columns}
                options={optionsApproved}
              />
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AgentView;
