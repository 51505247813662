import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/Spinner';
import './index.css';
import { GET_AGENT_BALANCE_DETAILS } from '../../Graphql/Queries';
import { ErrorNotification } from '../../components/Notification';
// import Swal from "sweetalert2";
import { useSubscription } from '@apollo/client';

import { formatAmount } from '../../utils';

const BalanceDetails = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const [agentdetails, setAgentDetails] = useState([]);

  // const raiseAlert = () => {
  //   Swal.fire({
  //     title: "Are you sure you want to make this payment",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes,accept",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       try {
  //       } catch (error) {
  //         console.log(error);
  //         ErrorNotification("An error occured when creating payment");
  //       }
  //     }
  //   });
  // };

  const { loading: todaywinningLoading } = useSubscription(
    GET_AGENT_BALANCE_DETAILS,
    {
      variables: {
        region_id: localStorage.getItem('selected-region'),
      },

      onComplete: (data) => {
        setAgentDetails(data.customer_account);
      },

      onError: () => {
        ErrorNotification('Failed to load agent balance details');
      },
    },
  );

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    // isRowSelectable: false,
    onRowSelectionChange: (e, i, v) => {
      console.log(v);
      let paymentdata = [];

      v.map((r) => {
        paymentdata.push(agentdetails[r]);
      });

      // console.log(paymentdata);
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },

    {
      name: 'customer',
      label: 'Full name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 10, top: 7 }}>
              {val.full_name}
            </p>
          );
        },
      },
    },
    {
      name: 'customer',
      label: 'Recipient number',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {val.phone_number}
            </p>
          );
        },
      },
    },
    {
      name: 'customer',
      label: 'Town',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>{val.town}</p>
          );
        },
      },
    },
    {
      name: 'balance',
      label: 'Amount to be paid',

      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 30, top: 7 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },

    // {
    //     name: "region_id",
    //     label: "Action",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         customBodyRender: (val) => {
    //             return <button onClick={() => { }} className="btn btn-success" style={{ cursor: "pointer" }}>Make payment</button>
    //         },
    //     },
    // },
  ];

  return (
    <div style={{ padding: 20 }}>
      <button
        onClick={() => navigate(-1)}
        className="btn btn-secondary"
        style={{ cursor: 'pointer', marginTop: 20, marginBottom: 20 }}
      >
        Go back
      </button>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      ></div>

      {todaywinningLoading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <MUIDataTable
            // data={winning_claim}
            title={'PAYOUT DETAILS'}
            columns={columns}
            options={options}
            data={agentdetails}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          float: 'right',
          marginTop: 20,
        }}
      >
        <button
          className="btn btn-success"
          onClick={() => {
            // raiseAlert();
            // setShowMDetailModal(true)
            // setInitiatePayment(true)
          }}
        >
          Make Payment
        </button>
      </div>
    </div>
  );
};

export default BalanceDetails;
