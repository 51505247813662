import React, { useState } from 'react';
import TableRows from './TableRow';
// import WebFont from 'webfontloader'
import { BsPlusCircle } from 'react-icons/bs';
import { SuccessNotification } from '../../../../components/Notification';

const AddProductPropoerty = ({ setContents, populatedData }) => {
  const [rowsData, setRowsData] = useState(populatedData);

  const addTableRows = () => {
    const rowsInput = {
      name: '',
      max: '',
      min: '',
      display_name: '',
      image: '',
      odds: '',
      num_comb: '',
      multiplier: '',
    };
    setRowsData((rowsData) => [...rowsData, rowsInput]);
  };

  const onMakeInput = (index, e) => {
    const rowsInput = [...rowsData];
    let total = parseInt(rowsInput[index]['quantity']) * e.target.value;
    rowsInput[index]['total_value'] = total;
    setRowsData(rowsInput);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const updateState = (id) => {
    const rowsInput = {
      name: '',
      max: null,
      min: null,
      display_name: '',
      image: '',
      odds: '',
      num_comb: '',
      multiplier: '',
    };
    const newState = rowsData.map((obj) => {
      if (obj.id === id) {
        return { ...obj, rowsInput };
      }
      return obj;
    });

    // console.log(newState);

    setRowsData(newState);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  return (
    <div className="container font-loader" style={{ padding: 10 }}>
      <div style={{ paddingBottom: 10, fontWeight: '600', marginLeft: 10 }}>
        Add properties of product
      </div>
      <div className="row ">
        <div className="col-sm-12">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Max</th>
                <th>Min</th>
                <th>Odds</th>
                <th>Number of combinations</th>
                <th>Multiplier</th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                onMakeInput={onMakeInput}
                updateState={updateState}
              />
            </tbody>
          </table>
          <div style={{ marginLeft: 15 }}>
            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                addTableRows();
              }}
            >
              {<BsPlusCircle />}{' '}
              <span style={{ marginLeft: 3, paddingTop: 5 }}>Add new line</span>
            </button>

            <button
              style={{ marginLeft: 10 }}
              className="btn btn-outline-success"
              onClick={(e) => {
                e.preventDefault();
                setContents(rowsData);
                SuccessNotification('Properties saved successfully');
              }}
            >
              <span style={{ marginLeft: 3, paddingTop: 5 }}>Save</span>
            </button>
          </div>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
};
export default AddProductPropoerty;
