import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import MUIDataTable from 'mui-datatables';
import './index.css';
import AdminModal from '../../components/Modal';
import ChequeDataComponent from '../../components/chequeDataComponent';
import { GET_ALL_BANKS } from '../../Graphql/Queries';
import { formatAmount } from '../../utils';
import { ErrorNotification } from '../../components/Notification';
import MySpinner from '../../components/Spinner';

const AgentExpandableTable = ({
  rowData,
  game_id,
  raiseAlert,
  setSelectedWinners,
  setMaxSelectedWinners,
  setInitiatePayment,
  state,
  handleChange,
  setPaymentType,
  paymentType,
  showDetailModal,
  setShowMDetailModal,
  handleclose2,
}) => {
  let loading = false;

  const [open, setOpen] = useState(true);

  const [banks, setBanks] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  useQuery(GET_ALL_BANKS, {
    onCompleted: (data) => {
      setBanks(data.bank);
    },

    onError: () => {
      ErrorNotification('Failed to load banks');
    },
  });

  const options = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    onRowSelectionChange: (e, i, v) => {
      let counter = 0;
      setOpen(!open);

      let rowWinners = [];
      let maxWinners = [];

      // console.log(rowData);

      v.map((r) => {
        if (rowData[r].total_commission_balance > 5000) {
          let c = {
            agent_id: rowData[r].agent.id,
            game_id: game_id,
          };
          setSelectedItem(rowData[r]);

          maxWinners.push(c);
        } else {
          counter++;
          let c = {
            agent_id: rowData[r].agent.id,
            game_id: game_id,
          };

          rowWinners.push(c);
        }
      });

      if (counter > 1) {
        setSelectedWinners(rowWinners);
        setInitiatePayment(true);
      } else {
        setSelectedWinners(rowWinners);
        setInitiatePayment(true);
      }

      if (maxWinners.length > 0) {
        setPaymentType('cheque');
      } else if (rowWinners.length > 0) {
        setPaymentType('momo');
      } else {
        setPaymentType('momo');
      }

      setMaxSelectedWinners(maxWinners);
    },
    customToolbarSelect: () => {},
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'Game Id',
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>{val}</p>
          );
        },
      },
    },
    {
      name: 'agent',
      label: 'Full Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 5, top: 10 }}>
              {val.full_name}
            </p>
          );
        },
      },
    },

    {
      name: 'agent',
      label: 'Phone Number',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 25, top: 10 }}>
              {val.phone_number}
            </p>
          );
        },
      },
    },

    {
      name: 'agent',
      label: 'Momo Network',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          console.log(val);
          return (
            <p style={{ position: 'relative', left: 20, top: 10 }}>
              {val?.customer_account.momo_provider}
            </p>
          );
        },
      },
    },

    {
      name: 'total_stake_amount',
      label: 'Total staked amount(GHS)',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 50 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },

    {
      name: 'total_commission_balance',
      label: 'Commission(GHS)',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return (
            <p style={{ position: 'relative', left: 50 }}>
              {formatAmount(val)}
            </p>
          );
        },
      },
    },

    {
      name: 'commission_status',
      label: 'Payment status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          return (
            <div>
              {val === 'paid' ? (
                <div
                  className="divheight bg-greenLight"
                  style={{ position: 'relative', left: 50 }}
                >
                  <p className="text-green">{val}</p>
                </div>
              ) : (
                <div
                  className="divheight bg-orangeLight"
                  style={{ position: 'relative', left: 50 }}
                >
                  <p
                    style={{
                      fontSize: 10,
                      color: '#FF0000',
                      position: 'relative',
                      top: 2,
                    }}
                  >
                    Pending
                  </p>
                </div>
              )}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div style={{ padding: 20, position: 'relative' }}>
      <AdminModal
        show={showDetailModal}
        close={!showDetailModal}
        title={'Pay with cheque'}
        body={
          <ChequeDataComponent
            banks={banks}
            state={state}
            handleChange={handleChange}
            selectedItem={selectedItem}
            raiseAlert={raiseAlert}
          />
        }
        handleClose={handleclose2}
      />

      {loading ? (
        <div
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Spinner /> */}
          <MySpinner />
        </div>
      ) : (
        <MUIDataTable
          data={rowData ? rowData : []}
          title="Commission Payout"
          columns={columns}
          options={options}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
        <div style={{ position: 'relative', top: 10, marginRight: 20 }}>
          <button
            disabled={open || paymentType === 'cheque'}
            className="btn btn-success"
            onClick={() => raiseAlert()}
          >
            Pay with Momo
          </button>
        </div>

        <div style={{ position: 'relative', top: 10 }}>
          <button
            disabled={paymentType === 'momo'}
            className="btn btn-warning"
            onClick={() => {
              setShowMDetailModal(true);
              setInitiatePayment(true);
            }}
          >
            Pay with Cheque
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentExpandableTable;
